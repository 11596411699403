import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import logoTransp from '../../assets/oroPrestige/logo-trasp.webp';
import videoFile from '../../assets/oroPrestige/Lingotto50gr.mp4'; 
import iconImage from '../../assets/oroPrestige/oroprestige icon.webp';
import SwiperLogos from '../../components/AboutUsLogos';
import ContactForm from '../../components/contactForm/ContactForm';
import GoogleMaps from '../../components/GoogleMapsOP'; 
import { Wrapper } from "@googlemaps/react-wrapper";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import MinimalArrow from '../../components/MinimalArrow';
import family from "../../assets/oroPrestige/silhouette-of-happy-family-who-playing-on-the-beac-2023-11-27-05-37-25-utc.webp";
import regalo from "../../assets/lingotti/0055 - Still life Care is gold-Modifica.webp";
import { ScrollParallax } from 'react-just-parallax';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'


function OroPrestige() {
    const [gioiellerie, setGioiellerie] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const videoRef = useRef(null);
    const googleMaps = 'AIzaSyCZyoDZ5eFh61i2vM3zyDXUI3WVp3J4E9Y'
    var ogImage = 'https://www.careisgold.it/static/media/silhouette-…2023-11-27-05-37-25-utc.fff1423b79bacba527a2.webp';

    

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (videoRef.current) {
                  if (entry.isIntersecting && !document.hidden) {
                    videoRef.current.muted = true;
                    videoRef.current.play().catch((error) => {
                      // Handle the error
                      console.log('Video play failed:', error);
                    });
                  } else {
                    videoRef.current.pause();
                  }
                }
              });
            },
            {
              threshold: 0.5, // Adjust this threshold as needed
            }
          );
      
          if (videoRef.current) {
            observer.observe(videoRef.current);
          }
      
          const handleVisibilityChange = () => {
            if (videoRef.current) {
              if (document.hidden) {
                videoRef.current.pause();
              } else if (videoRef.current.getBoundingClientRect().top < window.innerHeight) {
                videoRef.current.muted = true;
                videoRef.current.play().catch((error) => {
                  // Handle the error
                  console.log('Video play failed:', error);
                });
              }
            }
          };
      
          document.addEventListener('visibilitychange', handleVisibilityChange);
      
          return () => {
            if (videoRef.current) {
              observer.unobserve(videoRef.current);
            }
            document.removeEventListener('visibilitychange', handleVisibilityChange);
          };
        }, []);

    useEffect(() => {
        const handleResize = () => {
          setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const map = useRef(null);
    const contatti = useRef(null);
    const handleClickMap = () => {
        map.current?.scrollIntoView({behavior: 'smooth'});
    };
    const handleClickContatti = () => {
        contatti.current?.scrollIntoView({behavior: 'smooth'});
    };

   


    useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.post(`https://crm.careholding.it/ws/Call/?Cat=Gioiellerie&met=GetListaGioiellerieApertePublic&np=0`);
            setGioiellerie(res.data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchData();
      }, []);

      const [ref, inView] = useInView({
        triggerOnce: true, 
        threshold: 0.2, 
        rootMargin: '500px', 
        passive: true,
      });
    

  return (
    <>
     <Helmet>
        <title>Oro Prestige: Lingotti d'Oro da Investimento nelle Gioiellerie | Careisgold</title>
        <meta name="description" content="Scopri Oro Prestige by Careisgold: il brand dedicato alle gioiellerie. Illumina la tua professione con i lingotti d'oro da investimento. Formazione e servizio chiavi in mano." />
        <meta name="keywords" content="Oro Prestige, lingotti oro, investimento oro, gioiellerie, Careisgold" />
        <meta property="og:title" content="Oro Prestige: Lingotti d'Oro da Investimento nelle Gioiellerie | Careisgold" />
        <meta property="og:description" content="Scopri Oro Prestige by Careisgold: il brand dedicato alle gioiellerie. Illumina la tua professione con i lingotti d'oro da investimento. Formazione e servizio chiavi in mano." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/oro-prestige/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/oro-prestige/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Careisgold SpA",
          "url": "https://www.careisgold.it/oro-prestige/",
          "logo": ${logo},
          "description": "Scopri Oro Prestige by Careisgold: il brand dedicato alle gioiellerie. Illumina la tua professione con i lingotti d'oro da investimento. Formazione e servizio chiavi in mano.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Monte Baldo 14/D",
            "addressLocality": "Villafranca di Verona",
            "addressRegion": "Veneto",
            "postalCode": "37069",
            "addressCountry": "IT"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+39 045 8213155",
            "contactType": "Customer Service"
          },
          "sameAs": [
            "https://www.facebook.com/careisgoldspa",
            "https://www.instagram.com/careisgoldspa",
            "https://www.linkedin.com/company/careisgold-spa"
          ]
        }
      `}
    </script>
      </Helmet>
        <Navbar />
        <MinimalArrow backgroundImage={family} 
            title={`<p class="ml-20">I tuoi lingotti direttamente in gioielleria</p>` }
            Subtitle=''/>
        <section>
            <div className='mx-auto'>
                <div className='h-lvh flex justify-center items-center bg-black z-40'>
                    <div className={`h-screen hidden sm:flex sm:w-2/4 sm:justify-center sm:items-center`}>
                        <img src={logoTransp} alt='logo oro prestige' className='h-2/5 my-auto mx-auto object-cover'/>
                    </div>
                    <div className={`md:h-lvh w-full sm:w-2/4 bg-black flex justify-center items-center p-4`}>
                        <div className='text-center md:text-left w-full sm:max-w-md lg:max-w-lg xl:max-w-xl me-auto'>
                            <p className={`text-3xl md:text-5xl font-semibold uppercase mb-2 text-slate-50 text-center md:text-start`} data-aos="fade-up" data-aos-duration="900">LA TUA RISERVA AUREA</p>
                            <div data-aos="fade-zoom-in" data-aos-duration="1000" className={`text-sm md:text-lg mb-4`}>
                                <p className='font-medium my-9 text-xl text-neutral-300 text-center md:text-start'>Grazie a Oro Prestige by Careisgold, da oggi puoi acquistare lingotti d’oro 24k nella tua gioielleria di fiducia.
                                Chiedi di noi al tuo gioielliere.</p> 
                            </div>
                            <button className={`progress-button-light border-slate-50 text-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}
                                    onClick={handleClickMap}
                            >
                                Trova la gioielleria più vicino a te
                            </button>
                        </div>
                    </div>   
                </div>
            </div>
        </section>

        <section>
            <div className='mx-auto'>
                <div className='flex flex-col sm:flex-row h-lvh justify-center items-center bg-white z-40'>
                    <div className='w-full md:w-3/5 xl:w-2/5 bg-white flex justify-center items-center px-4 pt-10 md:pt-0'>
                        <div className='text-center md:text-left w-full sm:max-w-md lg:max-w-lg xl:max-w-xl me-auto'>
                            <p className='text-3xl md:text-5xl font-semibold uppercase mb-2' data-aos="fade-up" data-aos-duration="900">Oro Prestige by Careisgold</p>
                            <div data-aos="fade-zoom-in" data-aos-duration="1000" className='text-sm md:text-lg mb-4'>
                                <ul className="list-disc text-xl space-y-6 text-black my-10">
                                    <li className="flex items-center mb-3 text-left" data-aos="fade-right" data-aos-duration="1000">
                                        <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" /> 
                                        Il bene rifugio per eccellenza
                                    </li>
                                    <li className="flex items-center mb-3 text-left" data-aos="fade-right" data-aos-duration="1300">
                                        <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" />
                                        Nel tempo mantiene il suo valore ed il suo potere d’acquisto
                                    </li>
                                    <li className="flex items-center mb-3 text-left" data-aos="fade-right" data-aos-duration="1600">
                                        <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" />
                                        Protegge il patrimonio dall’inflazione e dalle crisi di mercato
                                    </li>
                                    <li className="flex items-center mb-3 text-left" data-aos="fade-right" data-aos-duration="1900">
                                        <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" />
                                        È esente IVA
                                    </li>
                                </ul>
                                <button 
                                    className={`border-black text-black progress-button-dark px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}
                                    onClick={handleClickContatti}
                                >
                                    Scopri di più
                                </button>
                            </div>
                        </div>
                    </div>   
                    <div className='w-full md:w-2/5 xl:w-2/5 flex justify-center items-center'>
                      <video className='h-full w-auto' autoPlay loop muted>
                        <source src={videoFile} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className='relative z-20 h-lvh overflow-hidden'>
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                <img src={regalo}  alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" />
              </ScrollParallax>
                <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full flex justify-center items-center">
                    <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                        <div className='lg:w-[50%]'>
                            <h2 className="lg:text-5xl text-4xl text-white font-semibold" data-aos="fade-up" data-aos-duration="1000">OLTRE IL REGALO</h2>
                            <p className="text-xl mt-4 mb-10 text-white" data-aos="fade-up" data-aos-duration="1000">
                                Proteggi il futuro delle persone che ami. <br /> Regalare lingotti d’oro non è solo un gesto d’amore puro, ma anche un investimento nel loro domani.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className='relative z-20 h-lvh overflow-hidden'>
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
              <div id="tolstoy-container" className="bg-black" style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <video
                ref={videoRef}
                width={width < 768 ? '90%' : "70%" }
                height={width < 768 ? '90%' : "70%" }
                muted
                controls
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                >
                <source src="https://videos.gotolstoy.com/public/f6270713-8096-4666-8118-1121350888dd/3281caf9-8ea1-460e-95c6-a9d431ea38ff/3281caf9-8ea1-460e-95c6-a9d431ea38ff.mp4"  type="video/mp4" />
                Your browser does not support the video tag.
                </video>
                </div>
              </ScrollParallax>
            </div>
        </section>
        <div className='text-center mt-10 mb-5'>
            <p className='text-neutral-100 text-xl md:text-3xl font-semibold'>Hanno scritto di noi</p>
        </div>
        <SwiperLogos />
        <div className='h-auto lg:h-lvh flex items-center justify-center md:mt-48 mb-5 md:mb-56' >
            <div className='w-full px-4 lg:px-0' ref={contatti}>
                <div className="flex flex-col lg:flex-row items-start justify-evenly mt-20">
                    <div className='w-full lg:w-3/6'>
                        <ContactForm 
                            LandingName='oro prestige'
                            Name={true}
                            NameClass='text-slate-50'
                            NameText='Nome e Cognome:'
                            NameRequired={true}
                            Tel={true}
                            TelClass='text-slate-50'
                            TelText='Telefono:'
                            TelRequired={true}
                            Email={true}
                            EmailClass='text-slate-50'
                            EmailText='Email:'
                            EmailRequired={true}
                            textBox={true}
                            textBoxClass='text-slate-50'
                            textBoxText='Indirizzo:'
                            TextBoxRequired={false}
                            Dropdown={false}
                            DropdownItem={['Acquisto di un piano rateale', 'Acquisto unico', 'Lavorare con noi']}
                            DropdownClass='text-slate-50'
                            DropdownText='Interessato a:'
                            SendText='Invia'
                            SendClass='progress-button-light border border-slate-50 text-slate-50 w-full md:w-6/12 '
                            Message={true}
                            MessageClass='text-slate-50'
                            MessageText='Messaggio:'
                            MessageTextRequired={false}
                            ConsensoDati={true}
                            ConsensoDatiRequired={true}
                            ConsensoDatiText='consenso dati'
                            ConsensoDatiID={1}
                            ConsensoDatiClass='text-slate-50'
                            successText='Messaggio inviato con successo!'  
                            successLink='/'
                            errorText='Si è verificato un errore, Riprova!' />
                    </div>
                    <div className='w-full lg:w-2/6 py-8 lg:py-16' >
                        <div className='w-full text-start text-slate-50 pb-10'>
                            <p className='text-slate-50 text-4xl font-bold mb-5 uppercase'>Contattaci</p>
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                                <FaMapMarkerAlt className="me-5" />
                                <a className="text-decoration-none text-dark font-medium opacity-75" rel="noreferrer noopener" href="https://goo.gl/maps/y7ADbUdXX1ZZppbK9" target="_blank">
                                <p className="m-0">Via monte baldo, 14/D INT. 3 37069 VILLAFRANCA DI VERONA (VR)</p>
                                </a>
                            </li>
                            <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                                <FaPhone className="me-5" />
                                <a className="text-decoration-none text-dark font-medium opacity-75" href="tel:045 2457421" rel="noreferrer noopener">
                                <p className="m-0">045 2457421</p>
                                </a>
                            </li>
                            <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                                <FaEnvelope className="me-5" />
                                <a className="text-decoration-none text-dark font-medium opacity-75" href="mailto:info@oroprestige.it" rel="noreferrer noopener">
                                <p className="m-0">INFO@OROPRESTIGE.IT</p>
                                </a>
                            </li>
                            </ul>
                            <p className="text-lg font-semibold mt-4">
                            Sede legale:
                            </p>
                            <p className="mt-2 pb-4">
                            Via Monte Baldo 14/D, Villafranca di Verona <br />
                            C.F./P.Iva 04598560235 - REA VR-433912<br />
                            CAP. SOCIALE € 2.000.000,00 i.v.<br />
                            <a className="text-decoration-none text-dark" href="mailto:careisgoldspa@pec.careisgold.it" rel="noreferrer noopener">careisgoldspa@pec.careisgold.it</a><br />
                            Operatore Professionale Oro - Codice n° 5008578
                            </p>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-slate-50'  ref={map}>
                    <div ref={ref}>
                        {inView && (
                            <Wrapper apiKey={googleMaps}>
                                <GoogleMaps gioiellerie={gioiellerie}/>
                            </Wrapper>
                        )}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default OroPrestige