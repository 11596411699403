import React, {useState, useEffect} from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import desktopImage from "../../assets/staff/0061-Careisgold.webp";
import headerImageMobile from "../../assets/staff/0046-Careisgold-Copia.jpg";
import People from '../../components/People';
import MinimalArrow from '../../components/MinimalArrow';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp';


function Staff() {
  const [headerImage, setHeaderImage] = useState(null);
  const [loadingActive, setLoadingActive] = useState(true);


  const handleLinkClick = (active) => {

    const loadingOverlay = document.getElementById('loading-overlay');

    if(active){
      loadingOverlay.classList.add('active');
    }else{
      loadingOverlay.classList.remove('active');
      setLoadingActive(false);
    }

  };

  useEffect(() => {
    if (headerImage === null) {
      handleLinkClick(true);
    } else {
      handleLinkClick(false);
    }

    const handleResize = () => {
      if(window.innerWidth > 800){
        setHeaderImage(desktopImage)
      }else{
        setHeaderImage(headerImageMobile)
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [headerImage]);


  return (
    <>
    <Helmet>
      <title>Team Careisgold: Esperti in Investimenti Oro al Tuo Servizio</title>
      <meta name="description" content="Conosci il team di professionisti Careisgold. Esperti oro fisico da investimento, risparmio e customer care pronti a guidarti nelle tue scelte d'investimento con competenza e dedizione." />
      <meta name="keywords" content="team Careisgold, esperti oro, professionisti risparmio, customer care oro" />
      <meta property="og:title" content="Team Careisgold: Esperti in Investimenti Oro al Tuo Servizio" />
      <meta property="og:description" content="Conosci il team di professionisti Careisgold. Esperti oro fisico da investimento, risparmio e customer care pronti a guidarti nelle tue scelte d'investimento con competenza e dedizione." />
      <meta property="og:image" content={headerImage} /> 
      <meta property="og:url" content="https://www.careisgold.it/staff/" />
      <meta property="og:type" content="page" /> 
      <link rel="canonical" href="https://www.careisgold.it/staff/" />
      <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Team Careisgold: Esperti in Investimenti Oro al Tuo Servizio",
          "url": "https://www.careisgold.it/staff/",
          "description": "Conosci il team di professionisti Careisgold. Esperti oro fisico da investimento, risparmio e customer care pronti a guidarti nelle tue scelte d'investimento con competenza e dedizione.",
          "inLanguage": "it",
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "url": ${headerImage}
          },
          "publisher": {
            "@type": "Organization",
            "name": "Careisgold SpA",
            "logo": {
              "@type": "ImageObject",
              "url": ${logo}
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Via Monte Baldo 14/D",
              "addressLocality": "Villafranca di Verona",
              "addressRegion": "Veneto",
              "postalCode": "37069",
              "addressCountry": "IT"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+39 045 8213155",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/careisgoldspa",
              "https://www.instagram.com/careisgoldspa",
              "https://www.linkedin.com/company/careisgold-spa"
            ]
          }
        }
      `}
    </script>
    </Helmet>
    {!loadingActive &&
      <>
        <Navbar />
        <MinimalArrow backgroundImage={headerImage} title='LE GRANDI IMPRESE NASCONO DAI MIGLIORI TEAM' Subtitle={`<em>“Se ho avuto successo è perché mi sono sempre circondato di persone migliori di me”</em></br><Claudio><div class="text-right mt-2">Claudio Boso, Presidente di Careisgold S.p.A.</div>`}/>
        <People/>
        <Footer/>
      </>
    }
    </>
  )
}

export default Staff