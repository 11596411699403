import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Autoplay } from 'swiper/modules';

function FadeSlider({image1, image2, image3, width}) {
  return (
    <>
        <Swiper
            spaceBetween={30}
            effect={'fade'}
            navigation={false}
            pagination={false}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            modules={[EffectFade, Autoplay]}
            className="mySwiper"
        >
            <SwiperSlide>
            <img src={image1} className={`${width} object-contain`}/>
            </SwiperSlide>
            <SwiperSlide>
            <img src={image2} className={`${width} object-contain`}/>
            </SwiperSlide>
            <SwiperSlide>
            <img src={image3} className={`${width} object-contain`}/>
            </SwiperSlide>
        </Swiper>
    </>
  )
}

export default FadeSlider