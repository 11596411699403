import React, { useRef } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import headerImage from "../../assets/lingotti/0041 - Still life Care is gold-Modifica.webp";
import TimeLine from '../../components/TimeLine';
import { ScrollParallax } from 'react-just-parallax';
import { SlArrowDown } from 'react-icons/sl';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'


function HistoryPage() {

  const nextSectionRef = useRef(null);
  var ogImage = 'https://www.careisgold.it/static/media/0041%20-%20…re%20is%20gold-Modifica.c8c1e3aeda8a66fa5ee6.webp';

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <>
    <Helmet>
        <title>Gruppo Careisgold: Leader Italiano nell'oro fisico da investimento</title>
        <meta name="description" content="Scopri Careisgold, punto di riferimento in Italia per investimenti in oro. Innovazione, affidabilità e una rete di società specializzate al servizio dei nostri clienti." />
        <meta name="keywords" content="Careisgold, investimenti oro Italia, banco metalli, operatore professionale oro, leader mercato oro" />
        <meta property="og:title" content="Gruppo Careisgold: Leader Italiano nell'oro fisico da investimento" />
        <meta property="og:description" content="Scopri Careisgold, punto di riferimento in Italia per investimenti in oro. Innovazione, affidabilità e una rete di società specializzate al servizio dei nostri clienti." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/gruppo/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/gruppo/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Gruppo Careisgold: Leader Italiano nell'oro fisico da investimento",
          "url": "https://www.careisgold.it/gruppo/",
          "description": "Scopri Careisgold, punto di riferimento in Italia per investimenti in oro. Innovazione, affidabilità e una rete di società specializzate al servizio dei nostri clienti.",
          "inLanguage": "it",
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "url": ${headerImage}
          },
          "publisher": {
            "@type": "Organization",
            "name": "Careisgold SpA",
            "logo": {
              "@type": "ImageObject",
              "url": ${logo}
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Via Monte Baldo 14/D",
              "addressLocality": "Villafranca di Verona",
              "addressRegion": "Veneto",
              "postalCode": "37069",
              "addressCountry": "IT"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+39 045 8213155",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/careisgoldspa",
              "https://www.instagram.com/careisgoldspa",
              "https://www.linkedin.com/company/careisgold-spa"
            ]
          }
        }
      `}
    </script>
      </Helmet>
        <Navbar/>
        <div className='relative z-20 h-lvh overflow-hidden'>
            <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
              <img src={headerImage} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" />
            </ScrollParallax>
            <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full flex justify-center items-center">
                <div className="flex justify-end text-start ps-15 mx-10 md:mr-[20%] xl:ml-[50%]">
                  <div className='lg:w-[100%]'>
                    <h2 className="lg:text-5xl text-4xl text-white font-semibold" data-aos="fade-up" data-aos-duration="1000">La storia del Gruppo</h2>
                    <p className="text-xl mt-4 mb-10 text-white " data-aos="fade-up" data-aos-duration="1000">Il nome Care significa letteralmente “cura, protezione, attenzione”: sono questi i capisaldi che, da oltre 15
                      anni, guidano il Gruppo Care Holding e le sue consociate nella realizzazione di progetti su misura per il
                      benessere delle persone e del loro futuro. Leggi la nostra storia, dal 2009 ad oggi.</p>
                    <SlArrowDown 
                      data-aos="fade-up"
                      data-aos-delay="600"
                      data-aos-duration="1000"
                      onClick={scrollToNextSection} 
                      className='arrow cursor-pointer block absolute left-1/2 transform -translate-x-1/2 text-slate-50 text-2xl md:text-5xl' />
                  </div>
                </div>
            </div>
        </div>
        <div ref={nextSectionRef}></div>
        <TimeLine/>
        <Footer/>
    </>
  )
}

export default HistoryPage