import imageOne from "../assets/oroPrestige/gioiellerie/gioielleria-via-della-spiga-milano.webp"
import amantia from '../assets/gioiellerie/amantia.png'
import bascetta from '../assets/gioiellerie/bascetta.png'
import fegadoli1 from '../assets/gioiellerie/fegadoli.png'
import fegadoli2 from '../assets/gioiellerie/fegadoli2.png'

const Data = [
    {
        "idAnagrafica": "70",
        "Indirizzo": "Via G. Garibaldi, 30 32",
        "city": "Adrano",
        "Denominazione": "Gioielleria Bascetta",
        "Lat": 37.6637077331543,
        "Lng": 14.830307960510254,
        "img": bascetta,
        "img1": '',
        "img2": "",
        "tel": "0957692148",
        "mail": "mauriziobascetta@libero.it",
        "desc": " All’interno di questa importante gioielleria, situata nella ridente cittadina di Andrano, in provincia di Lecce, è presente Gaia Bascetta, in qualità di incaricata Careisgold alla promozione di oro puro fisico da investimento."
    },
    {
        "idAnagrafica": "15",
        "Indirizzo": "Via G.B. Fardella 86/88",
        "city": "Trapani",
        "Denominazione": "Amantia",
        "Lat": 38.019096374511719,
        "Lng": 12.522143363952637,
        "img": amantia,
        // "img1": "https://picsum.photos/500/402",
        // "img2": "https://picsum.photos/500/403",
        "tel": "092327004",
        "mail": "info@amantia.it",
        "desc": "La nascita di questa gioielleria inizia nel 1963 e, certamente, da allora il successo della stessa è dipeso dalle scelte lungimiranti della proprietà. Oggi, all’interno di questa importante boutique è possibile trovare Caterina Amantia, incaricata Careisgold abilitata alla promozione di oro puro fisico da investimento."
    },
    {
        "idAnagrafica": "33",
        "Indirizzo": "C.so Vittorio Emanuele, 4",
        "city": "Città di Castello",
        "Denominazione": "Fegadoli Gioielleria 1948",
        "Lat": 43.456939697265625,
        "Lng": 12.240670204162598,
        "img": fegadoli1,
        "img1": fegadoli2,
        // "img2": "https://picsum.photos/500/402",
        "tel": "0758557442",
        "mail": "info@gioielleriafegadoli.it",
        "desc": " La storia di questa importante realtà di Città di Castello affonda le radici nel lontano 1948. Oggi, grazie alla presenza di Marco Fegadoli, incaricato Careisgold alla promozione di oro puro fisico da investimento, sarà possibile per chiunque scegliere di crearsi una riserva aurea."
    }
]

export default Data;
