import React, { useState, useRef, useEffect } from 'react';
import lingotti from '../../assets/lingotti/0081 - Still life Care is gold-Modifica - Copia.webp';
import Navbar from '../../components/Navbar';
import shakinghands from "../../assets/divisionePrivate/shakinghands.mp4";
import { SlArrowDown } from 'react-icons/sl';
import Footer from "../../components/Footer";
import banner from '../../assets/divisionePrivate/banner2.webp';
import IncrementNum from '../../components/IncrementNum';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import ContactForm from '../../components/contactForm/ContactForm';
import SwiperLogos from '../../components/AboutUsLogos';
import MinimalSection from '../../components/MinimalSection';
import oroIcon from '../../assets/oroPrestige/oroprestige icon.webp'
import { ScrollParallax } from 'react-just-parallax';
import logo from '../../assets/oroPrestige/logo-trasp.webp'
import { Helmet } from 'react-helmet';



function DivisionePrivate() {

  const listItemRefs = useRef([]);
  const listItemRefs1 = useRef([]);
  const videoRef = useRef(null);
  
  const [width, setWidth] = useState(window.innerWidth);

  var ogImage = 'https://www.careisgold.it/static/media/banner2.ea30b0dc5b85ef2e7db1.webp';

    
  const privateSection = useRef(null);

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    privateSection.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (videoRef.current) {
              if (entry.isIntersecting && !document.hidden) {
                videoRef.current.muted = true;
                videoRef.current.play().catch((error) => {
                  // Handle the error
                  console.log('Video play failed:', error);
                });
              } else {
                videoRef.current.pause();
              }
            }
          });
        },
        {
          threshold: 0.5, // Adjust this threshold as needed
        }
      );
  
      if (videoRef.current) {
        observer.observe(videoRef.current);
      }
  
      const handleVisibilityChange = () => {
        if (videoRef.current) {
          if (document.hidden) {
            videoRef.current.pause();
          } else if (videoRef.current.getBoundingClientRect().top < window.innerHeight) {
            videoRef.current.muted = true;
            videoRef.current.play().catch((error) => {
              // Handle the error
              console.log('Video play failed:', error);
            });
          }
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
  
      return () => {
        if (videoRef.current) {
          observer.unobserve(videoRef.current);
        }
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);

  
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const contatti = useRef(null);

  const handleClickContatti = () => {
      contatti.current?.scrollIntoView({behavior: 'smooth'});
  };

 

  return (
    <div className="">
      <Helmet>
        <title>Divisione Private Consulenti - Careisgold</title>
        <meta name="description" content="Scopri come la Divisione Private di Careisgold supporta i consulenti finanziari nel valorizzare l'oro fisico come asset strategico. Opportunità di mercato e alta formazione." />
        <meta name="keywords" content="divisione private, consulenti finanziari, oro fisico, investimenti, Careisgold" />
        <meta property="og:title" content="Divisione Private Consulenti - Careisgold" />
        <meta property="og:description" content="Scopri come la Divisione Private di Careisgold supporta i consulenti finanziari nel valorizzare l'oro fisico come asset strategico. Opportunità di mercato e alta formazione." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/divisione-private-consulenti/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/divisione-private-consulenti/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Careisgold SpA",
          "url": "https://www.careisgold.it/divisione-private-consulenti/",
          "logo": ${logo},
          "description": "Scopri come la Divisione Private di Careisgold supporta i consulenti finanziari nel valorizzare l'oro fisico come asset strategico. Opportunità di mercato e alta formazione.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Monte Baldo 14/D",
            "addressLocality": "Villafranca di Verona",
            "addressRegion": "Veneto",
            "postalCode": "37069",
            "addressCountry": "IT"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+39 045 8213155",
            "contactType": "Customer Service"
          },
          "sameAs": [
            "https://www.facebook.com/careisgoldspa",
            "https://www.instagram.com/careisgoldspa",
            "https://www.linkedin.com/company/careisgold-spa"
          ]
        }
      `}
    </script>
      </Helmet>
      <Navbar />
      <div className='relative z-20 h-lvh overflow-hidden bg-black'>
        <video className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} autoPlay muted playsInline loop>
                  <source src={shakinghands} type="video/mp4" />
                  Your browser does not support the video tag.
        </video>
        <div className="absolute top-0 left-0 bg-black bg-opacity-30 md:bg-opacity-20 w-full h-full flex justify-center">
          <div className="flex justify-center items-center text-start ps-15 mx-5 md:mr-[20%] xl:ml-[40%]">
            <div className='lg:w-[100%]'>
              <h2 className="lg:text-5xl text-4xl text-white drop-shadow-2xl font-semibold text-balance" >L'ORO FISICO APRE NUOVE PORTE PER CONSULENTI FINANZIARI</h2>
              <p className="text-xl mt-4 mb-10 text-white drop-shadow-2xl text-balance" >Se sei un consulente finanziario, l’oro rappresenta oggi il tuo più grande alleato per attrarre nuovi clienti e consolidare la tua reputazione
              come punto di riferimento di fiducia.</p>
            </div>
          </div>          
          <SlArrowDown 
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="1000"
              onClick={scrollToNextSection} 
              className='arrow cursor-pointer block absolute transform -translate-x-1/2 text-slate-50 text-3xl md:text-5xl' 
          />
        </div>
      </div>
      <div ref={privateSection}>
      </div>

      <MinimalSection
        orientation='right' 
        background='bg-black' 
        title=""
        titleClass='text-4xl text-white'
        description={`L’Oro da investimento non è un prodotto speculativo;
            è un bene fisico che va concepito come solida base del portafoglio di ogni risparmiatore. L’Oro rappresenta una garanzia, l'asset su cui si fonda la stabilità economica di banche e Stati.`}
        descriptionClass='text-xl text-white'
        buttonText=''
        buttonDark={true}
        href='/investire-in-oro'
        imageUrl={lingotti}
        imgOpacity="opacity-100"
        strength='0.14'
        lerp='0.30'
        buttonClass='hidden'
      />

       {/* CAREISGOLD: PIU VALORE ALLA CONSULENZA */}

      {width < 768 ? 
        <div className={`z-20 overflow-hidden flex justify-center items-center block md:hidden bg-black`}>
          <div className={`flex flex-col`}>
              <div className={``}>
                  <ScrollParallax enableOnTouchDevice={true} lerpEase="0.05" className="absolute inset-0">
                      <img src={banner} className="h-full w-full object-cover pe-1" alt="background" style={{ transform: 'scale(1.4)' }} />
                  </ScrollParallax>
              </div>
              <div className={`z-40 lg:pb-20 sm:pb-56 text-start bg-gradient-to-top py-8 px-5  pb-20`}>
                  <h2 className={`text-3xl lg:text-5xl text-slate-50 font-semibold text-balance`} data-aos="fade-up" data-aos-duration="1000">Careisgold: più valore alla consulenza</h2>
                  <div className={`text-md lg:text-lg mt-4 mb-10 text-slate-50 text-balance`} data-aos="fade-up" data-aos-duration="1000" >
                  <p className='text-left'>Oro fisico l'Asset Strategico per differenziarsi sul Mercato. Il nuovo modo di acquisire clienti.</p>
                  </div>
                  <button 
                      className={`progress-button-light border-slate-50 text-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}
                      onClick={handleClickContatti}
                    >
                      Non perderti i prossimi approfondimenti
                  </button>
              </div>
          </div>
        </div>
       : 
        <div className={`relative z-20 h-lvh overflow-hidden flex justify-center items-center hidden md:block bg-black`}>
          <div className={`w-full h-full flex`}>
              <div className={`relative overflow-hidden flex justify-start items-center opacity-90 xl:w-3/4 w-1/6`}>
                  <ScrollParallax enableOnTouchDevice={true} lerpEase="0.05" className="absolute inset-0">
                      <img src={banner} className="h-full w-full object-cover pe-1" alt="background" style={{ transform: 'scale(1.2)' }} />
                  </ScrollParallax>
              </div>
              <div className={`flex flex-col justify-center items-start text-left z-10 xl:pe-16 xl:ps-0 px-16 xl:translate-x-[5%] xl:w-[25%] 2xl:w-1/4 w-5/6`}>
                  <h2 className={`text-3xl lg:text-5xl text-slate-50 font-semibold text-balance`} data-aos="fade-up" data-aos-duration="1000">Careisgold: più valore alla consulenza</h2>
                  <div className={`text-md lg:text-lg mt-4 mb-10 text-slate-50 text-balance`} data-aos="fade-up" data-aos-duration="1000">
                    <p className='text-left'>Oro fisico l'Asset Strategico per differenziarsi sul Mercato. Il nuovo modo di acquisire clienti.</p>
                  </div>
                  <button 
                    className={`progress-button-light border-slate-50 text-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}
                    onClick={handleClickContatti}
                  >
                    Non perderti i prossimi approfondimenti
                </button>
              </div>
          </div>
        </div>
      } 

        

      {/* CONSULENTE || CLIENTE */}
        
        <div className='relative z-10  pt-10 text-white bg-gradient-custom w-full '>
            <div className='flex justify-around mt-20 flex-col lg:flex-row'>
                <div className='lg:ml-40'>
                    <div className='mb-10 md:mb-0 lg:pl-9 flex justify-center items-center flex-col '>
                    <h2 className='lg:text-left text-center text-3xl mb-14 text-animate-gold  w-full' data-aos="fade-up"><b>PER IL CONSULENTE</b> </h2>
                    </div>
                    <div className='lg:pr-8 flex justify-center'>
                    <ul className='  w-full'>
                        {[
                        "Apre nuove opportunità di mercato",
                        "Rafforza il ruolo del consulente.",
                        "Offre la sicurezza di un prodotto stabile",
                        ].map((text, index) => (
                        <li 
                            key={index} 
                            data-aos="fade-up" 
                            ref={(el) => (listItemRefs.current[index] = el)}
                        >
                            <div className={`flex ${width < 1280 ? 'flex-col items-center' : '' }`}>
                              <span className='mr-4 xl:w-[5%] lg:w-[10%] md:w-[5%] sm:w-[10%] w-[20%] mb-8 ml-2 lg:mt-0 mt-20'>
                                <img src={oroIcon} alt="" />
                              </span>
                              <span className='w-[90%] text-center xl:text-start'>
                                <p className='text-xl'>{text}</p>
                              </span>
                            </div>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
                <div>
                    <div className='mb-10 md:mb-0 lg:pl-9 flex justify-center items-center flex-col '>
                    <h2 className='lg:text-left text-center text-3xl mb-14 text-animate-gold  w-full mt-20 lg:mt-0' data-aos="fade-up"><b>PER IL CLIENTE</b> </h2>
                    </div>
                    <div className='lg:pr-8 flex justify-center'>
                    <ul className='  w-full'>
                        {[
                        "Protegge i risparmi da crisi e volatilità",
                        "Ripara da inflazione e perdita di potere d'acquisto",
                        "Diversifica il paniere d'investimento",
                        ].map((text, index) => (
                        <li 
                            key={index} 
                            data-aos="fade-up" 
                            ref={(el) => (listItemRefs.current[index] = el)}
                        >
                            <div className={`flex ${width < 1280 ? 'flex-col items-center' : '' }`}>
                            <span className='mr-4 xl:w-[5%] lg:w-[10%] md:w-[5%] sm:w-[10%] w-[20%] mb-8 ml-2 lg:mt-0 mt-20'>
                              <img src={oroIcon} alt="" />
                            </span>
                            <span className='w-[90%] text-center xl:text-start'>
                            <p className='text-xl'>{text}</p>
                            </span>
                            </div>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
            </div>

        {/* VIDEO */}
        
            <section>
            <div className='relative z-20 h-lvh overflow-hidden'>
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
              <div id="tolstoy-container" className="bg-black" style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <video
                ref={videoRef}
                width={width < 768 ? '90%' : "70%" }
                height={width < 768 ? '90%' : "70%" }
                muted
                controls
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                >
                <source src="https://videos.gotolstoy.com/public/f45f15ba-3fa4-4d9d-8e91-4e2006e9f3f9/4d393169-3002-4ca0-87d0-d36ac2146290/4d393169-3002-4ca0-87d0-d36ac2146290.mp4"  type="video/mp4" />
                Your browser does not support the video tag.
                </video>
                </div>
              </ScrollParallax>
            </div>
        </section>

        {/* SECOND LIST + INCREMENT NUM */}
        
            <div>
                <h3 className='text-3xl mt-28'><b> Ecco cosa offre il mondo Careisgold ai consulenti finanziari</b></h3>
                <div  className='flex justify-around mt-20 flex-col lg:flex-row'>
                    <div className='lg:pr-8 flex justify-center lg:ml-40'>
                        <ul className=' w-full'>
                            {[
                            "Innovazione Strategica",
                            "Alta Formazione.",
                            "Approccio Moderno all'Acquisizione di Clienti",
                            ].map((text, index) => (
                            <li 
                                key={index} 
                                data-aos="fade-up" 
                                ref={(el) => (listItemRefs1.current[index] = el)}
                            >
                                <div className={`flex ${width < 1280 ? 'flex-col items-center' : '' }`}>
                                <span className='mr-4 xl:w-[5%] lg:w-[10%] md:w-[5%] sm:w-[10%] w-[20%] mb-8 ml-2 lg:mt-0 mt-20'>
                                  <img src={oroIcon} alt="" />
                                </span>
                                <span className='w-[90%] text-center xl:text-start'>
                                <p className='text-xl'>{text}</p>
                                </span>
                                </div>
                            </li>
                            ))}
                        </ul>
                    </div>
                    <div className='lg:pr-8 flex justify-center'>
                        <ul className='  w-full'>
                            {[
                            "Benefici Tangibili",
                            "Vantaggi Fiscali e Amministrativi",
                            "Diversificazione e Ampliamento",
                            ].map((text, index) => (
                            <li 
                                key={index} 
                                data-aos="fade-up" 
                                ref={(el) => (listItemRefs1.current[index] = el)}
                            >
                                <div className={`flex ${width < 1280 ? 'flex-col items-center' : '' }`}>
                                <span className='mr-4 xl:w-[5%] lg:w-[10%] md:w-[5%] sm:w-[10%] w-[20%] mb-8 ml-2 lg:mt-0 mt-20'>
                                  <img src={oroIcon} alt="" />
                                </span>
                                <span className='w-[90%] text-center xl:text-start'>
                                <p className='text-xl'>{text}</p>
                                </span>
                                </div>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="flex justify-center flex-col xl:flex-row py-20">
                  <div className="basis-1/4 mt-10 xl:mt-0">
                    <IncrementNum start={0} end={20300} duration={3} startSign='+&nbsp;' endSign=''/>
                    <p className='mt-4 font-semibold text-lg'>Clienti</p>
                  </div>
                  <div className="basis-1/4 mt-10 xl:mt-0">
                    <IncrementNum start={0} end={14210} duration={3} startSign='+&nbsp;' endSign='&nbsp; Kg'/>
                    <p className='mt-4 font-semibold text-lg'>Kg di Oro ordinati</p>
                  </div>
                  <div className="basis-1/4 mt-10 xl:mt-0">
                    <IncrementNum start={0} end={700} duration={3} startSign='+&nbsp;' endSign=''/>
                    <p className='mt-4 font-semibold text-lg'>Collaboratori</p>
                  </div>
                </div>
            </div>

            <div className='text-center mb-5'>
              <p className='text-neutral-100 text-xl md:text-3xl font-semibold'>Hanno scritto di noi</p>
            </div>
        <SwiperLogos />
        <div className='h-auto lg:h-lvh flex items-center justify-center flex-row bg-gradient-to-top'>
            <div className='w-full px-4 lg:px-0'>
                
            <div className="flex flex-col lg:flex-row items-start justify-evenly mt-40">
                <div className='w-full lg:w-2/6 py-8 lg:py-16'>
                    <div className='w-full text-start text-slate-50 pb-10'>
                        <p className='text-slate-50 text-4xl font-bold mb-5 uppercase'>Contattaci</p>
                        <ul className="list-group list-group-flush">
                        <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                            <FaMapMarkerAlt className="me-5" />
                            <a className="text-decoration-none text-dark font-medium opacity-75" rel="noreferrer noopener" href="https://goo.gl/maps/y7ADbUdXX1ZZppbK9" target="_blank">
                            <p className="m-0">Via monte baldo, 14/D INT. 3 37069 VILLAFRANCA DI VERONA (VR)</p>
                            </a>
                        </li>
                        <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                            <FaPhone className="me-5" />
                            <a className="text-decoration-none text-dark font-medium opacity-75" href="tel:045 2457421" rel="noreferrer noopener">
                            <p className="m-0">045 2457421</p>
                            </a>
                        </li>
                        <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                            <FaEnvelope className="me-5" />
                            <a className="text-decoration-none text-dark font-medium opacity-75" href="mailto:info@oroprestige.it" rel="noreferrer noopener">
                            <p className="m-0">INFO@OROPRESTIGE.IT</p>
                            </a>
                        </li>
                        </ul>
                        <p className="text-lg font-semibold mt-4">
                        Sede legale:
                        </p>
                        <p className="mt-2 pb-4">
                        Via Monte Baldo 14/D, Villafranca di Verona <br />
                        C.F./P.Iva 04598560235 - REA VR-433912<br />
                        CAP. SOCIALE € 2.000.000,00 i.v.<br />
                        <a className="text-decoration-none text-dark" href="mailto:careisgoldspa@pec.careisgold.it" rel="noreferrer noopener">careisgoldspa@pec.careisgold.it</a><br />
                        Operatore Professionale Oro - Codice n° 5008578
                        </p>
                    </div>
                </div>
                <div className='w-full lg:w-3/6'  ref={contatti}>
                    <ContactForm
                    LandingName='divisione private consulenti'
                    Name={true}
                    NameClass='text-slate-50'
                    NameText='Nome e Cognome:'
                    NameRequired={true}
                    Tel={true}
                    TelClass='text-slate-50'
                    TelText='Telefono:'
                    TelRequired={true}
                    Email={true}
                    EmailClass='text-slate-50'
                    EmailText='Email:'
                    EmailRequired={true}
                    Dropdown={false}
                    DropdownItem={['Acquisto di un piano rateale', 'Acquisto unico', 'Lavorare con noi']}
                    DropdownClass='text-slate-50'
                    DropdownText='Interessato a:'
                    SendText='Invia'
                    SendClass='progress-button-light border border-slate-50 text-slate-50 w-full md:w-6/12 '
                    textBox={false}
                    textBoxText='Nome Gioielleria:'
                    textBoxClass='text-slate-50'
                    Message={true}
                    MessageClass='text-slate-50'
                    MessageText='Messaggio:'
                    MessageTextRequired={true}
                    ConsensoDati={true}
                    ConsensoDatiRequired={true}
                    ConsensoDatiText='consenso dati'
                    ConsensoDatiID={1}
                    ConsensoDatiClass='text-slate-50'
                    successText='Messaggio inviato con successo!'  
                    successLink='/contatti'
                    errorText='Si è verificato un errore, Riprova!'
                    />
                </div>
            </div>
          </div>
        </div>

        </div>
      <Footer/>
    </div>
  )
}

export default DivisionePrivate