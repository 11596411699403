import axios from "axios";
import { useEffect, useState } from "react";
// Import all logos
import logo1 from '../assets/oroPrestige/loghi-rassegna-stampa/1.webp';
import logo2 from '../assets/oroPrestige/loghi-rassegna-stampa/2.webp';
import logo3 from '../assets/oroPrestige/loghi-rassegna-stampa/3.webp';
import logo4 from '../assets/oroPrestige/loghi-rassegna-stampa/4.webp';
import logo5 from '../assets/oroPrestige/loghi-rassegna-stampa/5.webp';
import logo6 from '../assets/oroPrestige/loghi-rassegna-stampa/6.webp';
import logo7 from '../assets/oroPrestige/loghi-rassegna-stampa/7.webp';
import logo8 from '../assets/oroPrestige/loghi-rassegna-stampa/8.webp';

const AboutUsLogos = () => {
    const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8];
    const doubleLogos = [...logos, ...logos]; // Double the array for a seamless loop

    return (
        <article className="wrapper">
            <div className="marquee">
                <div className="marquee__group">
                    {doubleLogos.map((logo, index) => (
                        <img key={index} src={logo} alt={`Logo ${index % logos.length + 1}`} className="marquee__item" />
                    ))}
                </div>
            </div>
        </article>
    );
};

export default AboutUsLogos;
