import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const FixingContext = createContext();

export const useFixing = () => {
    return useContext(FixingContext);
};

export const FixingProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [showFixing, setShowFixing] = useState(false);
    const [prezzo, setPrezzo] = useState("");
    const [variazione, setVariazione] = useState("");
    const [bullBear, setBullBear] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (variazione !== "") {
            const number = parseFloat(variazione);
            if (!isNaN(number)) {
                setBullBear(number >= 0);
            } else {
                console.log("The input is not a valid number.");
            }
        }
    }, [variazione]);
    


    const isSmallDevice = window.innerWidth < 768;


    useEffect(() => {
        axios.post("https://newgest.careholding.it/principale.asmx/getprezzoORO", {}, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => {
            const [a, b] = response.data.d.split('#'); 
            setPrezzo(a);
            setVariazione(b);
            setShowFixing(true);
        })
        .catch(error => {
            console.error("An error occurred:", error);
            setError("Failed to fetch data. Please try again later.");
        })
        .finally(() => {
            setLoading(false);
        });

        if(isSmallDevice) {
            setTimeout(() => {
                setIsVisible(false)
            }, 10000)
        }  
    }, []);

    const handleFixingShow = () => {
        setIsVisible(!isVisible);

    };

    const value = {
        isVisible,
        setIsVisible,
        showFixing,
        prezzo,
        variazione,
        bullBear,
        loading,
        error,
        handleFixingShow
    };

    return (
        <FixingContext.Provider value={value}>
            {children}
        </FixingContext.Provider>
    );
};
