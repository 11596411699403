import { useState } from 'react';
import AndreaC from '../assets/staff/andrea-cerulli.webp';
import GaiaD from '../assets/staff/gaia-donaduzzi.webp';
import RominaS from '../assets/staff/romina-sinigagllia.webp';
import MilenaB from '../assets/staff/milena-bovolenta.webp';
import MonicaM from '../assets/staff/monica-mantovani.webp';
import GiorgiaT from '../assets/staff/giorgia-tinelli.webp';
import BarbaraL from '../assets/staff/barbara-lorenzoni.webp';
import MarcelaS from '../assets/staff/marcela-salazar.webp';
import PaolaP from '../assets/staff/paola-pasqualin.webp';
import SaraB from '../assets/staff/sara-begalli.webp';
import CamillaS from '../assets/staff/camilla-salandini.webp';
import JodyF from '../assets/staff/Fustini2_staff.jpg';
import MicheleM from '../assets/staff/michele-malagnini.webp';
import TommasoP from '../assets/staff/tommaso-poli.webp';
import AlessioC from '../assets/staff/alessio-cutrona.webp';
import VirajP from '../assets/staff/viraj-perera.webp';
import BeatriceM from '../assets/staff/beatrice-murari.webp';
import User from '../assets/staff/userimg.webp';
import AndreaGuerra from '../assets/staff/andrea-guerra.webp';
import BeatriceRuzzenente from '../assets/staff/beatrice-ruzzenente.webp';
import NataliaM from '../assets/staff/natalia-matei.webp';
import StefaniaF from '../assets/staff/stefania-farris.webp';
import GermanoP from '../assets/staff/germano-pomari.webp';
import IradaP from  '../assets/staff/irada-pallanca.webp';
import CristianT from  '../assets/staff/Trusca1_staff.jpg';
import Zeggiotti from  '../assets/staff/Zegggiotti1_staff.jpg';
import Silvestri from  '../assets/staff/Silvestri2_staff.jpg';
import Roberta from  '../assets/staff/Roberta.webp';
import Arianna from  '../assets/staff/Arianna.webp';
import NewImage from '../assets/mfxeded/giphy (2).gif';

const people = [
    {
        name: 'Andrea Cerulli',
        role: 'Chief Financial Officer',
        imageUrl:AndreaC,
    },
    {
        name: 'Stefania Farris',
        role: 'Accounting Manager',
        imageUrl:StefaniaF
    },
    {
        name: 'Gaia Donaduzzi',
        role: 'Accounting',
        imageUrl:GaiaD
    },
    {
        name: 'Natalia Matei',
        role: 'Accounting',
        imageUrl:NataliaM
    },
    {
        name: 'Germano Pomari',
        role: 'Customer Care Manager',
        imageUrl:GermanoP
    },
    {
        name: 'Romina Sinigaglia',
        role: 'Customer Care',
        imageUrl:RominaS
    },
    {
        name: 'Beatrice Murari',
        role: 'Customer Care',
        imageUrl: BeatriceM
    },
    {
        name: 'Milena Bovolenta',
        role: 'AML and Production Manager',
        imageUrl:MilenaB
    },
    {
        name: 'Monica Mantovani',
        role: 'Anti-Money Laundering',
        imageUrl:MonicaM
    },
    {
        name: 'Giorgia Tinelli',
        role: 'Anti-Money Laundering',
        imageUrl:GiorgiaT
    },
    {
        name: 'Barbara Lorenzoni',
        role: 'Events Manager',
        imageUrl:BarbaraL
    },
    {
        name: 'Marcela Salazar',
        role: 'Licensing Office Manager',
        imageUrl:MarcelaS
    },
    {
        name: 'Paola Pasqualin',
        role: 'Legal Counsel',
        imageUrl:PaolaP
    },
    {
        name: 'Irada Pallanca',
        role: 'Digital Communication Manager & Digital Strategist',
        imageUrl:IradaP
    },
    {
        name: 'Sara Begalli',
        role: 'Social Media Specialist',
        imageUrl:SaraB
    },
    
    {
        name: 'Camilla Salandini',
        role: 'Junior Marketing Specialist',
        imageUrl:CamillaS
    },
    {
        name: 'Andrea Guerra',
        role: 'Marketing & Promotion Manager',
        imageUrl:AndreaGuerra
    },
    {
        name: 'Beatrice Ruzzenente',
        role: 'Graphic Designer',
        imageUrl:BeatriceRuzzenente
    },
    {
        name: 'Jody Fustini',
        role: 'IT Manager',
        imageUrl:JodyF
    },
    {
        name: 'Michele Malagnini',
        role: 'DPO & Network',
        imageUrl:MicheleM
    },
    {
        name: 'Cristiano Silvestri',
        role: 'DB Developer',
        imageUrl:Silvestri
    },
    {
        name: 'Tommaso Poli',
        role: 'Software Engineer',
        imageUrl:TommasoP
    },
    {
        name: 'Alessio Cutrona',
        role: 'App Developer',
        imageUrl:AlessioC
    },
    {
        name: 'Viraj Perera',
        role: 'Web Developer',
        imageUrl:VirajP
    },
    {
        name: 'Mihai Cristian Trusca',
        role: 'Web Developer',
        imageUrl:CristianT
    },
    {
        name: 'Alessandro Zeggiotti',
        role: 'Backend Developer',
        imageUrl:Zeggiotti
    },
    {
        name: 'Arianna Masotto',
        role: 'Office Hospitality',
        imageUrl:Arianna
    },
    {
        name: 'Roberta Valerio',
        role: 'Office Hospitality Assistant',
        imageUrl:Roberta
    },
  ]
  
  export default function Backoffice() {
    const [rotation, setRotation] = useState(false);
    const [imageIndex, setImageIndex] = useState(false);

    let i = 1; 

    const handleClick = (name) => {
        if (name === 'Andrea Guerra') {
            if (i < 7) {
                i++;
                setImageIndex(false);
                return;
            }
            if (i === 7) { 
                setRotation(true); 
                setTimeout(() => {
                    setImageIndex(true);
                },1500)
                setTimeout(() => {
                    setRotation(false); 
                },2000)
                i = 1; 
                return;
            }
        }
    };
    

    return (
        <>
            <ul
                role="list"
                className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
            >
                {people.map((person) => (
                    <li key={person.name} 
                    data-aos="fade-in" data-aos-duration="1500"
                    >
                        <img
                            className={`mx-auto h-24 w-24 rounded-full ${person.name === 'Andrea Guerra' && rotation && 'rotate'}`}
                            src={person.name === 'Andrea Guerra' && imageIndex ? NewImage : person.imageUrl}
                            alt=""
                            onClick={() => handleClick(person.name)}
                        />                        
                        <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-neutral-200">{person.name}</h3>
                        <p className="text-sm leading-6 text-neutral-400">{person.role}</p>
                    </li>
                ))}
            </ul>
        </>
    )
  }
  