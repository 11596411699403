import React, { useRef, useState, useEffect } from 'react';
import FullMinimalSection from '../../components/FullMinimalSection';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/collaboraConNoi/pexels-padrinan-3785931.webp";
import big1 from '../../assets/collaboraConNoi/Prime_2.webp';
import big3 from '../../assets/collaboraConNoi/pexels-pavel-danilyuk-5520291.png';
import big4 from '../../assets/collaboraConNoi/Corporate1.webp';
import big2 from '../../assets/collaboraConNoi/5 (1).webp';
import BigImage from '../../components/BigImage';
import Footer from '../../components/Footer';
import MinimalArrow from '../../components/MinimalArrow';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'




function CollaboraConNoi() {

  const nextSectionRef = useRef(null);

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const [width, setWidth] = useState(window.innerWidth);

  var ogImage = 'https://www.careisgold.it/static/media/pexels-padrinan-3785931.ebf1695091712b124a0e.webp';
  

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>Carriere e Opportunità | Lavora con Careisgold SpA</title>
        <meta name="description" content="Unisciti al team Careisgold. Scopri le opportunità di lavoro e collaborazione nel settore degli investimenti in oro. Cresci con noi!" />
        <meta name="keywords" content="lavoro Careisgold, carriere oro, opportunità lavoro, collaborazioni" />
        <meta property="og:title" content="Carriere e Opportunità | Lavora con Careisgold SpA" />
        <meta property="og:description" content="Unisciti al team Careisgold. Scopri le opportunità di lavoro e collaborazione nel settore degli investimenti in oro. Cresci con noi!" />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/collabora-con-noi/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/collabora-con-noi/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Careisgold SpA",
          "url": "https://www.careisgold.it/collabora-con-noi/",
          "logo": ${logo},
          "description": "Unisciti al team Careisgold. Scopri le opportunità di lavoro e collaborazione nel settore degli investimenti in oro. Cresci con noi!",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Monte Baldo 14/D",
            "addressLocality": "Villafranca di Verona",
            "addressRegion": "Veneto",
            "postalCode": "37069",
            "addressCountry": "IT"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+39 045 8213155",
            "contactType": "Customer Service"
          },
          "sameAs": [
            "https://www.facebook.com/careisgoldspa",
            "https://www.instagram.com/careisgoldspa",
            "https://www.linkedin.com/company/careisgold-spa"
          ]
        }
      `}
    </script>
      </Helmet>
      <Navbar />
      <MinimalArrow backgroundImage={headerImage} title='La nostra unione è la nostra forza' Subtitle="Careisgold opera per offrire a tutti la possibilità di crearsi la propria Riserva Aurea, avvalendosi di collaboratori preparati, professionisti qualificati e partner di assoluta affidabilità."/>

      <FullMinimalSection 
        headerImage={big2}
        logoTransp=''
        title='Un approccio diversificato per ogni cliente'
        description={`L'azienda vanta un Back Office altamente preparato e capace di rispondere alle diverse esigenze dei clienti. Garantiamo una distribuzione capillare su tutto il territorio nazionale attraverso collaboratori
          preparati e professionisti qualificati. Nello specifico, l'azienda è articolata in tre  divisioni.`}
        href='/servizi'
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText=''
        buttonClass='hidden'
        buttonClass2='hidden'
        darkButton={true}
      />
      <BigImage
        grid='flex'
        background={big1}
        text='text-slate-50'
        buttonClass1='w-full'
        buttonClass2='hidden'
        href='https://4changeyourbusiness.it/'
        href2='/'
        firstButtonText='Scopri di piu'
        secondButtonText='il nostro team'      
        title='Divisione Prime'
        description={`<p class=''>La Divisione Prime organizza percorsi formativi ad hoc per permettere a tutti, anche senza esperienza nel settore,
           di mettersi in gioco e diventare professionisti nel mondo dell'Oro da investimento.</p>`}
        image=''
        opacity='opacity-100 left-0 top-[-40vh] h-[100vh] w-full md:w-[100vh]'
        bgOpacity='opacity-90 xl:w-3/4 w-1/6'
        translate='xl:translate-x-[5%] xl:w-2/5 2xl:w-1/4 w-5/6'
        orientation='right'
        backgroundColor='bg-black'
        buttonText='Scopri 4 change'
        darkButton={true}
        buttonClass='w-full'
        customClass='py-8 px-5 mb-20 pb-20'
      />
      <BigImage
        grid='flex'
        background={big3}
        text='md:text-black text-white'
        buttonClass1='w-full'
        buttonClass2='mt-5 w-full'
        href='https://gioiellerie.careisgold.it/oro-prestige-lingotti-in-gioielleria'
        href2='/divisione-private-consulenti'
        firstButtonText='Oro prestige'
        secondButtonText='divisione private'
        title='Divisione Private'
        description={`<p class=''>La Divisione Private si occupa di sostenere e supportare i professionisti del settore investimenti e gli
          operatori del settore gioiellerie, per operare nel mondo dell’Oro Puro Fisico.</p>`}
        image=''
        opacity='opacity-100 left-0 top-[-40vh] h-[100vh] w-full md:w-[100vh]'
        bgOpacity='opacity-90 xl:w-3/4 w-1/6'
        translate='xl:translate-x-[5%] xl:w-2/5 2xl:w-1/4 w-5/6'
        orientation='left'
        secondButton={true}
        backgroundColor='bg-neutral-50'
        darkButton={width < 768 & true}
        buttonClass='w-full'
        customClass='py-8 px-5 lg:mb-20'
      />
      <BigImage
        grid='flex'
        background={big4}
        text='text-slate-50'
        buttonClass1='hidden'
        buttonClass2='hidden'
        href='/'
        href2='/'
        firstButtonText='Storia del gruppo'
        secondButtonText='il nostro team'        
        title='Divisione Corporate'
        description={`<p class=''>La Divisione CORPORATE di Careisgold è impegnata nella facilitazione ed assistenza di prestigiose
          Organizzazioni finanziarie e assicurative (Banche, Assicurazioni, Società di Intermediazione Mobiliare,
          Società di Gestione Risparmio) a cui fornire la soluzione Chiavi in mano, il know-how necessario e specifico
          per rendere immediatamente operative e redditizie le proprie strutture commerciali.</p>`}
        image=''
        opacity='opacity-100 left-0 top-[-40vh] h-[100vh] w-full md:w-[100vh]'
        bgOpacity='opacity-90 xl:w-3/4 w-1/6'
        translate='xl:translate-x-[5%] xl:w-2/5 2xl:w-1/4 w-5/6'
        orientation='right'
        backgroundColor='bg-black'
        buttonText='Scopri Oro Prestige'
        darkButton={true}
        buttonClass='w-full'
        customClass='py-8 px-5 mb-20'
      />
      <Footer/>
    </div>
  )
}

export default CollaboraConNoi