import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/lingotti/header-servizi-page.webp";
import { ScrollParallax } from 'react-just-parallax';
import { SlArrowDown } from 'react-icons/sl';
import lingottoKG from "../../assets/lingotti/0028-Still-life-Care-is-gold-Modifica-Copia.webp";
import oroIcon from '../../assets/oroPrestige/oroprestige icon.webp'
import ImageStickyContent from '../../components/ImageStickyContent';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'



const useDebounce = (func, delay) => {
  const debounceTimeout = useRef(null);

  const debouncedFunction = (...args) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      func(...args);
    }, delay);
  };

  return debouncedFunction;
};




function Servizi2() {
  const [width, setWidth] = useState(window.innerWidth);
 
  const [section, setSection] = useState('');
  const sectionRef = useRef(section);

  const nextSectionRef = useRef(null);
  var ogImage = 'https://www.careisgold.it/static/media/header-servizi-page.9f45255e1ead5c57acab.webp';

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const debouncedResize = useDebounce(() => {
    setWidth(window.innerWidth);
  }, 150);


  useEffect(() => {
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, [debouncedResize]);

  useEffect(() => {
    sectionRef.current = section;
  }, [section]);

  const content = <>
    <Section id="section1" title="Offerta personalizzata e flessibile" text="Le nostre soluzioni sono pensate su misura, per rispondere ad ogni esigenza. In più, con noi, il cliente ha la possibilità di personalizzare durata, periodicità e cadenza dei versamenti." />
    <Section id="section2" title="Oro puro certificato" text="L’oro che trattiamo ha una purezza di 999,9/1000, ovvero “a 24 carati”. Si tratta del massimo grado di purezza disponibile sul mercato, a garanzia di un prodotto della più alta qualità e valore." />
    <Section id="section3" title="“Good delivery”" text="Rappresenta la certificazione che determina uno standard al fine di aumentare la trasparenza e la liquidabilità dell’Oro Puro Fisico in tutto il mondo. Noi di Careisgold trattiamo solo lingotti provenienti da raffinerie inserite nella Good Delivery List, e in Italia, solo tre società l&#39;hanno ottenuta." />
    <Section id="section4" title="Oro nominale" text="L’Oro Puro Fisico è di esclusiva proprietà del cliente. Ogni lingotto è identificato all&#39;interno del caveau mediante un codice seriale e un codice sigillo, abbinati unicamente all’acquirente. È tutto riportato anche nel proprio portale riservato." />
    <Section id="section5" title="Sicurezza" text="L’Oro Puro Fisico di proprietà del cliente può essere custodito nei caveaux di massima sicurezza del più grande Gruppo Italiano di Custodia e Trasporto Valori. L’oro depositato in caveaux sarà assicurato al 100%. In alternativa, può essere inviato verso qualsiasi destinazione d’Italia, in totale discrezione." />
    <Section id="section6" title="Trasparenza e certificazioni" text="Careisgold SpA è iscritta nel registro della Banca d’Italia come Operatore Professionale in Oro e come Banco Metalli, attività commerciale che ha ottenuto una specifica autorizzazione da parte di Bankitalia per la compravendita all&#39;ingrosso di Oro e metalli preziosi. L’azienda, inoltre, vanta un capitale sociale interamente versato di € 2.000.000,00." />
    <Section id="section7" title="Possibilità di rivendere l’oro puro fisico acquistato" text="Careisgold SpA, in quanto Banco Metalli, può riacquistare in ogni momento l’Oro Puro in deposito: in breve tempo, il cliente riceverà il controvalore in Euro." />
    <Section id="section8" title="Filiera etica e percorso tracciato" text="Proponiamo esclusivamente “Oro Puro Etico”, ossia Oro estratto con tecniche non invasive, senza l’utilizzo di agenti chimici inquinanti, nel massimo rispetto dell’ambiente e delle normative sulla sicurezza sul lavoro." />
  </>


  return (
    <div className="relative">
      <Helmet>
        <title>Perché Scegliere Careisgold - Acquista in Sicurezza e Qualità</title>
        <meta name="description" content="Scopri i motivi per cui scegliere Careisgold per i tuoi investimenti in oro. Offriamo prodotti certificati, consulenza esperta e soluzioni personalizzate per proteggere il tuo patrimonio." />
        <meta name="keywords" content="scegliere Careisgold, investimenti più sicuri, qualità, oro, consulenza" />
        <meta property="og:title" content="Perché Scegliere Careisgold - Acquista in Sicurezza e Qualità" />
        <meta property="og:description" content="Scopri i motivi per cui scegliere Careisgold per i tuoi investimenti in oro. Offriamo prodotti certificati, consulenza esperta e soluzioni personalizzate per proteggere il tuo patrimonio." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/perche-scegliere-careisgold/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/perche-scegliere-careisgold/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Perché Scegliere Careisgold - Acquista in Sicurezza e Qualità",
              "url": "https://www.careisgold.it/perche-scegliere-careisgold/",
              "description": "Scopri i motivi per cui scegliere Careisgold per i tuoi investimenti in oro. Offriamo prodotti certificati, consulenza esperta e soluzioni personalizzate per proteggere il tuo patrimonio.",
              "inLanguage": "it",
              "primaryImageOfPage": {
                "@type": "ImageObject",
                "url": ${headerImage}
              },
              "publisher": {
                "@type": "Organization",
                "name": "Careisgold SpA",
                "logo": {
                  "@type": "ImageObject",
                  "url": ${logo}
                },
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Via Monte Baldo 14/D",
                  "addressLocality": "Villafranca di Verona",
                  "addressRegion": "Veneto",
                  "postalCode": "37069",
                  "addressCountry": "IT"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+39 045 8213155",
                  "contactType": "Customer Service"
                },
                "sameAs": [
                  "https://www.facebook.com/careisgoldspa",
                  "https://www.instagram.com/careisgoldspa",
                  "https://www.linkedin.com/company/careisgold-spa"
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <Navbar />
      <div className='relative z-20 h-lvh overflow-hidden'>
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                <img src={headerImage} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" />
              </ScrollParallax>
          <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full flex justify-center items-center">
              <div className="flex justify-end text-start ps-15 mx-10 md:mr-[20%] xl:ml-[50%]">
                <div className='lg:w-[100%]'>
                  <h2 className="lg:text-5xl text-4xl text-white font-semibold" data-aos="fade-up" data-aos-duration="1000">Parole chiave: accessibilità ed esperienza</h2>
                  <p className="text-xl mt-4 mb-10 text-white " data-aos="fade-up" data-aos-duration="1000">Attraverso piani flessibili e personalizzati, chiunque può diventare proprietario del bene
                    rifugio per eccellenza. Inoltre, la pluriennale esperienza nel settore e il nostro know – how ci
                    contraddistinguono da sempre, permettendoci di offrire ai nostri clienti servizi unici nel loro genere.</p>
                  <SlArrowDown
                    data-aos="fade-up"
                    data-aos-delay="600"
                    data-aos-duration="1000"
                    onClick={scrollToNextSection} 
                    className='arrow cursor-pointer block absolute left-1/2 transform -translate-x-1/2 text-slate-50 text-2xl md:text-5xl' />
                </div>
              </div>
          </div>
      </div>
      <div ref={nextSectionRef}>
      </div>

      <ImageStickyContent src={lingottoKG} content={content} />
      <Footer />

    </div>
  );
}

const Section = ({ id, title, text }) => (
  <>
    <div id={id} className={`flex lg:flex-row lg:items-start lg:items-start flex-col items-center px-5 pb-20 ${id === 'section1' ? 'lg:mt-0 mt-80' : ''}`}>
      <div className='mr-4 w-[25%] lg:w-[3%] flex-shrink-0 ' data-aos="fade-in" data-aos-duration="3000">
        <img src={oroIcon} alt="" />
      </div>
      <div className="w-full lg:w-2/3 text-left">
        <h2 className="text-center lg:text-left text-3xl lg:text-4xl text-animate-gold text-slate-50 font-semibold cursor-default" data-aos="fade-in" data-aos-duration="3000">
          {title}
        </h2>
        <div className="text-center lg:text-left text-md lg:text-lg mt-4 mb-10 text-slate-50" data-aos="fade-up" data-aos-duration="3000">
          {text}
        </div>
      </div>
  </div>
</>
);

export default Servizi2;
