import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from "../../components/Footer";
import { ScrollParallax } from 'react-just-parallax';
import safeBlack from '../../assets/lingotti/areaClienti.webp';
import DefaultButton from '../../components/DefaultButton';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'


function AreaRiservata() {

    const [activeButton, setActiveButton] = useState(null);
    var ogImage = 'https://www.careisgold.it/static/media/areaClienti.ab5102c676cf7d5e51e6.webp';


    const handleButtonClick = (button) => {
      setActiveButton(button);
      setTimeout(() => {
        alert('vola' + button)
        setActiveButton(null)}
        , 500); // Adjust the timeout as needed
    };

  return (
    <div className="">
      <Helmet>
        <title>Area Riservata Clienti e Collaboratori | Careisgold</title>
        <meta name="description" content="Accedi all'area riservata Careisgold per clienti e collaboratori. Gestisci il tuo portafoglio, visualizza transazioni e accedi a servizi esclusivi." />
        <meta name="keywords" content="area riservata, clienti Careisgold, collaboratori Careisgold, login, portafoglio oro" />
        <meta property="og:title" content="Area Riservata Clienti e Collaboratori | Careisgold" />
        <meta property="og:description" content="Accedi all'area riservata Careisgold per clienti e collaboratori. Gestisci il tuo portafoglio, visualizza transazioni e accedi a servizi esclusivi." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/area-clienti-collaboratori/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/area-clienti-collaboratori/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Area Riservata Clienti e Collaboratori | Careisgold",
              "url": "https://www.careisgold.it/area-clienti-collaboratori/",
              "description": "Accedi all'area riservata Careisgold per clienti e collaboratori. Gestisci il tuo portafoglio, visualizza transazioni e accedi a servizi esclusivi.",
              "inLanguage": "it",
              "primaryImageOfPage": {
                "@type": "ImageObject",
                "url": ${safeBlack}
              },
              "publisher": {
                "@type": "Organization",
                "name": "Careisgold SpA",
                "logo": {
                  "@type": "ImageObject",
                  "url": ${logo}
                },
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Via Monte Baldo 14/D",
                  "addressLocality": "Villafranca di Verona",
                  "addressRegion": "Veneto",
                  "postalCode": "37069",
                  "addressCountry": "IT"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+39 045 8213155",
                  "contactType": "Customer Service"
                },
                "sameAs": [
                  "https://www.facebook.com/careisgoldspa",
                  "https://www.instagram.com/careisgoldspa",
                  "https://www.linkedin.com/company/careisgold-spa"
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <Navbar />
      <div className='hidden lg:block'>
        <div className="flex flex-col md:flex-row md:h-screen">
          <div className="md:w-1/2">
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                  <img src={safeBlack} alt='header image' className="top-0 right-0 w-1/2 h-full object-contain" />
              </ScrollParallax>
          </div>
          <div className="flex flex-col justify-center items-center md:w-2/3 p-4 space-y-5 h-screen">
              <p className="text-start w-full md:w-1/2 uppercase text-slate-50 text-2xl font-bold">LOGIN</p>
              <p className="text-start w-full md:w-1/2 pb-8 text-slate-50 text-xl">Se sei un cliente, clicca qui per accedere alla tua area riservata.</p>
              <DefaultButton text='Accedi come cliente' link='https://areaclienti.careisgold.it/dark/admin/login.html' dark={true} buttonClass='' />
              <DefaultButton text='Accedi come collaboratore' link='https://crm.careholding.it/login.aspx' dark={true} buttonClass='' />
          </div>
        </div>
      </div>
          {/* mobile */}
          <div className={`z-20 overflow-hidden flex justify-center items-center block lg:hidden`}>
            <div className={`flex flex-col`}>
                <div className={``}>
                    <ScrollParallax enableOnTouchDevice={true} lerpEase="0.05" className="absolute inset-0">
                        <img src={safeBlack} className="h-full w-full object-cover pe-1" alt="background" style={{ transform: 'scale(1.2)' }} />
                    </ScrollParallax>
                </div>
                <div className={`z-20 pb-20 text-start bg-gradient-to-top mx-5 space-y-3`}>
                  <p className="text-start w-full md:w-1/2 uppercase text-slate-50 text-2xl font-bold">LOGIN</p>
                  <p className="text-start w-full md:w-1/2 pb-8 text-slate-50 text-xl">Se sei un cliente, clicca qui per accedere alla tua area riservata.</p>
                  <DefaultButton text='Accedi come cliente' link='https://areaclienti.careisgold.it/dark/admin/login.html' dark={true} buttonClass='' />
                  <DefaultButton text='Accedi come collaboratore' link='https://crm.careholding.it/login.aspx' dark={true} buttonClass='' />
                </div>
            </div>
        </div>

      <Footer/>
    </div>
  );
}

export default AreaRiservata;
