import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../components/Navbar';
import oroIcon from '../../assets/oroPrestige/oroprestige icon.webp'
import headerImage from "../../assets/lingotti/0030 - Still life Care is gold-Modifica.webp";
import cassettoOro from "../../assets/lingotti/0026-Still-life-Care-is-gold-Modifica.webp";
import Footer from '../../components/Footer';
import MinimalArrow from '../../components/MinimalArrow';
import BackgroundImageFade from '../../components/BackgroundImageFade';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'


export default function ScopriProdotti() {

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const par = [
    {
      caratteristica: 'Accessibilità:',
      testo: 'l’Oro da investimento è alla portata di tutti, al costo di una colazione al giorno. Non è necessario disporre di grandi capitali per cominciare a crearsi la propria riserva aurea.'
    },
    {
      caratteristica: 'Flessibilità:',
      testo: 'il piano PAR è progettato per essere completamente personalizzabile sulla base delle richieste specifiche di ogni cliente. È infatti possibile modificare l’importo mensile e la durata del piano in qualsiasi momento. Inoltre, i versamenti possono essere sospesi e riattivati in modo facile e comodo.'
    },
    {
      caratteristica: 'Prezzo medio di mercato:',
      testo: 'Careisgold monitora e studia l’andamento del prezzo dell’Oro sul mercato in modo da offrire ai suoi clienti le migliori condizioni di acquisto.'
    },
    {
      caratteristica: 'Purezza e qualità:',
      testo: 'I lingotti offerti nel piano PAR hanno una purezza certificata di 999,9‰, garantendo il massimo standard di qualità sul mercato.'
    },
    {
      caratteristica: 'Pezzatura:',
      testo: 'È possibile scegliere lingotti di diverso peso: 20g, 50g e 100g, a seconda delle proprie preferenze.'
    },
    {
      caratteristica: 'Sicurezza:',
      testo: 'L’Oro ottenuto attraverso il piano PAR è di proprietà esclusiva del cliente. Careisgold offre, inoltre, soluzioni di custodia dell’Oro sicure e assicurate al 100%.'
    },
  ]

  const aurum = [
    {
      caratteristica: 'Miglior prezzo:',
      testo: 'Careisgold conviene! Monitoriamo costantemente l’andamento del prezzo dell’Oro per garantirti sempre il miglior prezzo di mercato.'
    },
    {
      caratteristica: 'Velocità:',
      testo: 'l’Oro da investimento è subito disponibile. Con un singolo versamento il cliente può raggiungere immediatamente la quantità d’Oro desiderata.'
    },
    {
      caratteristica: 'Economicità:',
      testo: 'la quotazione dell’Oro al momento dell’acquisto, riflette i valori ufficiali del London Bullion Market Association, l’organizzazione internazionale che rappresenta e gestisce il mercato dell’Oro a livello mondiale.'
    },
    {
      caratteristica: 'Purezza e qualità:',
      testo: ' I lingotti offerti nel piano Aurum hanno una purezza certificata di 999,9‰, garantendo il massimo standard di qualità sul mercato.'
    },
    {
      caratteristica: 'Sicurezza:',
      testo: 'L’oro ottenuto attraverso il piano Aurum è di proprietà esclusiva del cliente. Careisgold offre, inoltre, soluzioni di custodia dell’Oro sicure e assicurate al 100%.'
    }
  ]

  const aurumExtra = [
    {
      caratteristica: 'Soluzione Omnicomprensiva:',
      testo: 'il piano Aurum Extra permette di crearsi la propria riserva aurea in modo immediato, ma anche aprendo un piano di acquisto a lungo termine per ottenere periodicamente ulteriori quantità d’Oro.'
    },
    {
      caratteristica: 'Flessibilità:',
      testo: 'il piano Aurum Extra è stato ideato per offrire al cliente il maggior numero di opzioni possibili per acquisire i propri lingotti sulla base delle proprie necessità.'
    },
    {
      caratteristica: 'Prezzo medio di mercato:',
      testo: 'Careisgold monitora e studia l’andamento del prezzo dell’Oro sul mercato in modo da ottenere sempre le condizioni migliori di acquisto per il cliente.'
    },
    {
      caratteristica: 'Purezza e qualità:',
      testo: ' I lingotti offerti nel piano PAR hanno una purezza certificata di 999,9‰, garantendo il massimo standard di qualità sul mercato.'
    },
    {
      caratteristica: 'Pezzatura:',
      testo: 'per i piani di acquisto ricorrente, è possibile scegliere lingotti di diverso peso: 20g, 50g e 100g, a seconda delle proprie preferenze.'
    },
    {
      caratteristica: 'Sicurezza:',
      testo: 'L’oro ottenuto è di proprietà esclusiva del cliente. Careisgold offre, inoltre, soluzioni di custodia dell’Oro sicure e assicurate al 100%.'
    },
  ]


  const content = <>
  <div className='relative z-10 p-10  text-white bg-gradient-custom'>
    <div className={`flex flex-col md:flex-row justify-evenly ${width < 750 ? ' ' :  'section-separator'}`}>
      <div className='md:w-[35%] mb-10 md:mb-0 lg:pl-9'>
        <h2 className='text-left text-3xl mb-9 text-animate-gold'><b>PAR:</b> </h2>
        <p className='text-left  text-xl'>
          Il PAR (Piano di Acquisto Ricorrente) è la soluzione più comoda e flessibile per accedere all’Oro da
          investimento in modo cadenzato, con una prospettiva a lungo termine. Questa formula permette di accumulare Oro puro fisico in modo graduale,
          adattando le caratteristiche del piano alle proprie necessità.
        </p>
      </div>
      <div className='md:w-[35%] pr-8'>
        <p className='uppercase mb-12'>Caratteristiche principali</p>
        <ul className=''>
          {par.map((vantaggio, index) => (
            <li  key={index} className='mt-7' data-aos="fade-up" data-aos-duration="1000">
            <div className='flex'>
              <span className='mr-4 w-[4%]'>
                <img src={oroIcon} alt="" />
              </span>
              <span className='w-[90%]'>
                <p className='border-bottom-gold' ><b>{vantaggio.caratteristica}</b> {vantaggio.testo}
                </p>
              </span>
            </div>
          </li>
          ))}
        </ul>
      </div>
    </div>
    <div className={`flex flex-col md:flex-row justify-evenly mt-32 ${width < 750 ? ' ' :  'section-separator'}`}>
      <div className='md:w-[35%] mb-10 md:mb-0 lg:pl-9'>
        <h2 className='text-left text-3xl mb-9 text-animate-gold'><b>Aurum:</b> </h2>
        <p className='text-left  text-xl'>
          Il piano Aurum è dedicato a chi ha già a disposizione un capitale e desidera metterlo al riparo dalla
          perdita di potere d’acquisto e dai pericoli dei mercati. Questa soluzione permette di accedere all’Oro Puro
          Fisico in modo immediato e conveniente, al fine di raggiungere immediatamente la quantità desiderata, al miglior prezzo del mercato.
        </p>
      </div>
      <div className='md:w-[35%] pr-8'>
        <p className='uppercase mb-12'>Caratteristiche principali</p>
        <ul className=''>
        {aurum.map((vantaggio, index) => (
            <li  key={index} className='mt-7' data-aos="fade-up" data-aos-duration="1000">
            <div className='flex'>
              <span className='mr-4 w-[4%]'>
                <img src={oroIcon} alt="" />
              </span>
              <span className='w-[90%]'>
                <p className='border-bottom-gold' ><b>{vantaggio.caratteristica}</b> {vantaggio.testo}
                </p>
              </span>
            </div>
          </li>
          ))}
        </ul>
      </div>
    </div>
    <div className='flex flex-col md:flex-row justify-evenly mt-32'>
      <div className='md:w-[35%] mb-10 md:mb-0 lg:pl-9' >
        <h2 className='text-left text-3xl mb-9 text-animate-gold'><b>Aurum Extra:</b> </h2>
        <p className='text-left  text-xl'>
          Aurum Extra rappresenta la soluzione più completa e versatile per chi vuole convertire
          immediatamente una parte dei propri risparmi in Oro Puro Fisico e al contempo aprire un piano di acquisto
          ricorrente a lungo termine.
        </p>
      </div>
      <div className='md:w-[35%] pr-8'>
        <p className='uppercase mb-12'>Caratteristiche principali</p>
        <ul className=''>
        {aurumExtra.map((vantaggio, index) => (
            <li  key={index} className='mt-7' data-aos="fade-up" data-aos-duration="1000">
            <div className='flex'>
              <span className='mr-4 w-[4%]'>
                <img src={oroIcon} alt="" />
              </span>
              <span className='w-[90%]'>
                <p className='border-bottom-gold' ><b>{vantaggio.caratteristica}</b> {vantaggio.testo}
                </p>
              </span>
            </div>
          </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
  </>

    // I nostri prodotti permettono a chiunque di potersi creare
    // la propria riserva aurea attraverso diverse soluzioni, garantendo flessibilità nel budget e nella durata del
    // piano.

  return (
    <>
    <Helmet>
        <title>Lingotti Oro Certificati | Careisgold</title>
        <meta name="description" content="Esplora la gamma di prodotti oro certificati Careisgold. Lingotti oro da investimento di alta qualità per ogni tipo di investitore." />
        <meta name="keywords" content="prodotti oro, oro certificato, lingotti, Careisgold" />
        <meta property="og:title" content="Lingotti Oro Certificati | Careisgold" />
        <meta property="og:description" content="Esplora la gamma di prodotti oro certificati Careisgold. Lingotti oro da investimento di alta qualità per ogni tipo di investitore." />
        <meta property="og:image" content={headerImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/prodotti/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/prodotti/" />
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Lingotti Oro Certificati | Careisgold",
            "url": "https://www.careisgold.it/prodotti/",
            "description": "Scopri i prodotti innovativi e i servizi personalizzati di Careisgold per investire in oro in modo professionale.",
            "inLanguage": "it",
            "primaryImageOfPage": {
              "@type": "ImageObject",
              "url": ${headerImage}
            },
            "publisher": {
              "@type": "Organization",
              "name": "Careisgold SpA",
              "logo": {
                "@type": "ImageObject",
                "url": "${logo}"
              },
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Via Monte Baldo 14/D",
                "addressLocality": "Villafranca di Verona",
                "addressRegion": "Veneto",
                "postalCode": "37069",
                "addressCountry": "IT"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+39 045 8213155",
                "contactType": "Customer Service"
              },
              "sameAs": [
                "https://www.facebook.com/careisgoldspa",
                "https://www.instagram.com/careisgoldspa",
                "https://www.linkedin.com/company/careisgold-spa"
              ]
            }
          }
        `}
      </script>
      </Helmet>
      <Navbar/>
      <div>
          <MinimalArrow backgroundImage={headerImage} title='' Subtitle={`<p class='md:text-start text-center text-4xl font-semibold'>Careisgold è leader in Italia nel settore dell’Oro da investimento e offre soluzioni sicure e perfettamente
                adattabili alle esigenze specifiche di ogni cliente.</p>`}/>
        <div className='relative bg-black'>
          <BackgroundImageFade src={cassettoOro} content={content} />
        </div>
      </div>
      <Footer/>
    </>
  )
}