import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest } from 'react-icons/fa'; // Import social media icons
import logo from '../assets/logo/logo-400.webp';
import FullMinimalSection from './FullMinimalSection';
import image2 from "../assets/lingotti/0015-Still-life-Care-is-gold-HDR-Modifica.webp";
import FixingGold from './FixingGold';


function Footer({banner}) {
  const lineRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the element is in viewport
    };
  
    const lineElement = lineRef.current; // Store the current element reference
    const elements = document.querySelectorAll('.animation-footer-text');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add animation class when element is in viewport
          lineElement.classList.add('animated-line');
          elements.forEach(element => {
            element.classList.remove('opacity-0');
            element.classList.add('opacity-1');

          });

        } else {
          // Remove animation class when element is out of viewport
          lineElement.classList.remove('animated-line');
          elements.forEach(element => {
            element.classList.remove('opacity-1');
            element.classList.add('opacity-0');
          });
        }
      });
    }, options);
  
    // Start observing the line element
    observer.observe(lineElement);
  
    // Clean up observer when component unmounts
    return () => {
      // Check if the lineElement is still valid before unobserving
      if (lineElement) {
        observer.unobserve(lineElement);
      }
    };
  }, []);



  return (
    <>
      <FixingGold/>
      {banner && 
        <FullMinimalSection 
          headerImage={image2}
          logoTransp=''
          title="Non esitare! Chiedi e ti risponderemo."
          description={`Un italiano su cinque ha già scelto di entrare nel mondo dell'Oro da investimento. Compila il form in 10 secondi e ottieni subito una consulenza gratuita dai nostri esperti.`}
          href='/contatti'
          logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
          buttonText='Contattaci'
          darkButton={true}
          buttonClass='w-full lg:w-1/2'
          buttonClass2='hidden'
        />
      }
      <footer className="relative lg:w-full bg-black text-white lg:py-20 py-10 px-5">
          <img src={logo} alt="Company Logo" className="lg:absolute me-auto mb-10 lg:w-1/6 sm:w-1/3 w-1/2" />
        <div className="w-full flex justify-start lg:justify-center items-center z-20">
          <div className={`text-start lg:w-1/2`}>
              <p className={`lg:text-2xl text-lg font-semibold uppercase mb-2`}>Careisgold SpA</p>
              <p className={`text-sm lg:text-lg`}>Via Monte Baldo 14/D, Villafranca di Verona</p>
              <p className={`text-sm lg:text-lg`}>C.F./P.Iva 04598560235 - REA VR-433912</p>
              <p className={`text-sm lg:text-lg`}>CAP. 37069 SOCIALE € 2.000.000,00 i.v.</p>
              <p className={`text-sm lg:text-lg`}>careisgoldspa@pec.careisgold.it</p>
              <p className={`text-sm lg:text-lg mb-10`}>Operatore Professionale Oro - Codice n° 5008578</p>

              <hr ref={lineRef} className="border-b border-gray-300 my-4" />

              <div className="flex flex-wrap justify-start lg:space-x-8">
                <Link to='/azienda/' className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 0.5s ease-in"}}>Azienda</Link>
                <Link to='/contatti/' className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 1s ease-in"}}>Contatti</Link>
                <a href="https://www.facebook.com/careisgoldspa/" target="_blank" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 1.5s ease-in"}}><FaFacebook /></a>
                <a href="https://www.instagram.com/careisgoldspa/?igshid=15rizhw3869f2" target="_blank" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 2s ease-in"}}><FaInstagram /></a>
                <a href="https://www.linkedin.com/company/careisgold-spa" target="_blank" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 2.5s ease-in"}}><FaLinkedin /></a>
                <a href="https://www.pinterest.com/Careisgold/?actingBusinessId=1007469516550293342" target="_blank" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 2.5s ease-in"}}><FaPinterest /></a>
                <a href="https://www.iubenda.com/privacy-policy/95086304" target="_blank" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 2.5s ease-in"}}>Privacy Policy</a>
                <a href='https://www.iubenda.com/privacy-policy/95086304/cookie-policy' target="_blank" className={`text-sm me-3 mt-3 lg:m-0 hover:text-gray-400 opacity-0 animation-footer-text`} style={{"transition":"opacity 3.5s ease-in"}}>Terms of Service</a>
              </div>                
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
