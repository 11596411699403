import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import MinimalArrow from '../../components/MinimalArrow';
import headerImage from "../../assets/lingotti/0039-Still-life-Care-is-gold-Modifica.webp";
import { Helmet } from 'react-helmet';

function Recensioni() {
  // const [reviews, setReviews] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);

  const reviews = [
    {
      name: "Mario Rossi",
      date: "10 Novembre 2024",
      rating: 5,
      comment: "Servizio eccellente! Altamente raccomandato per chi cerca un servizio di qualità nel settore oro."
    },
    {
      name: "Giulia Bianchi",
      date: "8 Novembre 2024",
      rating: 4,
      comment: "Molto soddisfatta del servizio e dell'affidabilità di Careisgold. Consigliato a chi cerca serietà e competenza."
    },
    {
        name: "Giulia rossi",
        date: "7 Novembre 2024",
        rating: 0,
        comment: "Pessimi."
      },
      {
        name: "Mario Rossi",
        date: "10 Novembre 2024",
        rating: 5,
        comment: "Servizio eccellente! Altamente raccomandato per chi cerca un servizio di qualità nel settore oro."
      },
      {
        name: "Giulia Bianchi",
        date: "8 Novembre 2024",
        rating: 4,
        comment: "Molto soddisfatta del servizio e dell'affidabilità di Careisgold. Consigliato a chi cerca serietà e competenza."
      },
      {
          name: "Giulia rossi",
          date: "7 Novembre 2024",
          rating: 0,
          comment: "Pessimi."
        },
  ];


  // useEffect(() => {
  //   axios.get('https://crm.careholding.it/ws/Call/?Cat=Clienti&met=GetRecensioniPubbliche&pars=&np=0') 
  //     .then((response) => {
  //       // setReviews(response.data); 
  //       // setLoading(false);
  //       console.log(response.data);
        
  //     })
  //     .catch((err) => {
  //       setError('Failed to load reviews');
  //       setLoading(false);
  //     });
  // }, []);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>{error}</div>;

  return (
    <div className='bg-white'>
      <Helmet>
        <title>Recensioni Clienti su Careisgold: Opinioni e Feedback nel Settore Oro</title>
        <meta name="description" content="Leggi le recensioni dei clienti Careisgold, leader nel mercato oro. Opinioni reali, feedback e testimonianze per valutare la qualità del servizio." />
        <meta name="keywords" content="recensioni Careisgold, opinioni mercato oro, testimonianze clienti, feedback servizio oro, Careisgold qualità" />
        <meta property="og:title" content="Recensioni Clienti su Careisgold: Opinioni e Feedback nel Settore Oro" />
        <meta property="og:description" content="Scopri cosa dicono i clienti su Careisgold: feedback, testimonianze e opinioni su servizi nel mercato dell'oro." />
        <meta property="og:image" content={`https://www.careisgold.it${headerImage}`} />
        <meta property="og:url" content="https://www.careisgold.it/recensioni/" />
        <meta property="og:type" content="page" />
      </Helmet>
      <Navbar />
      <MinimalArrow backgroundImage={headerImage} title="Recensioni e Opinioni su Careisgold" Subtitle="Leggi le testimonianze dei clienti che hanno scelto Careisgold per investire in oro fisico e ricevere servizi di qualità."/>
      
      <div>
        <section className="container mx-auto flex items-start gap-12 py-8">
            <aside className="lg:w-1/3 sticky top-28 h-[calc(100vh-7rem)] overflow-y-auto mt-20">
            <div className="bg-gray-50 p-8 rounded-lg text-center mb-8 shadow-md border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Grado di Soddisfazione dei Clienti</h3>
              <div className="text-2xl font-bold text-yellow-500">4.6 / 5</div>
              <p className="text-gray-500 text-sm mb-6">3020 recensioni verificate</p>
              {[4, 4, 3, 2, 1, 0].map((stars) => (
                <div key={stars} className="flex items-center justify-between text-sm mb-2">
                  <span className="text-gray-600">{stars} stelle</span>
                  <div className="w-full bg-gray-200 rounded-full h-2 mx-3">
                    <div className="bg-yellow-400 h-2 rounded-full" style={{ width: `${stars * 20}%` }}></div>
                  </div>
                  <span className="text-gray-600">{stars * 10}%</span>
                </div>
              ))}
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
              <label htmlFor="sort_by" className="block text-gray-600 mb-2">Ordina le recensioni:</label>
              <select
                id="sort_by"
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-yellow-400 focus:border-yellow-400"
              >
                <option value="recent">Recensioni più recenti</option>
                <option value="best">Recensioni migliori</option>
              </select>
            </div>
            </aside>

            <div className="lg:w-2/3 flex flex-col space-y-8 overflow-y-auto mt-10">
            <h2 className="text-2xl font-medium mb-6 text-gray-800">Cosa dicono i nostri clienti su Careisgold</h2>
            <p className="text-gray-600 mb-10 leading-relaxed">
              Da oltre 70 anni, Careisgold si impegna a offrire trasparenza e affidabilità nel settore dell’oro.
              Ecco le opinioni e le recensioni reali dei nostri clienti soddisfatti.
            </p>

            {reviews.map((review, index) => (
              <div key={index} className="bg-white p-6 shadow-md rounded-lg border border-gray-200">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-semibold text-gray-800">{review.name}</h3>
                  <span className="text-gray-500 text-sm">{review.date}</span>
                </div>
                <div className="flex items-center mb-3">
                  {[...Array(review.rating)].map((_, i) => (
                    <span key={i} className="text-yellow-400 text-lg mr-1">★</span>
                  ))}
                  {[...Array(5 - review.rating)].map((_, i) => (
                    <span key={i} className="text-gray-300 text-lg mr-1">★</span>
                  ))}
                  <span className="ml-3 text-gray-500 text-xs">Acquisto verificato</span>
                </div>
                <p className="text-gray-700">{review.comment}</p>
              </div>
            ))}
            </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Recensioni;
