import React, { useEffect, useRef, useState } from 'react';
import markerIcon from '../assets/oroPrestige/marker30.svg';
import userIcon from '../assets/oroPrestige/user1.webp';
import sedeIcon from '../assets/oroPrestige/careisgold1.webp';
import myPosition from '../assets/oroPrestige/my-position.webp';

const MapOP = ({gioiellerie}) => {
  const infoWindowRef = useRef(null);
  const userLocationMarkerRef = useRef(null);
  const customMarkerRef = useRef(null);

  const generateSmartLink = (latitude, longitude) => {
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      return `maps://maps.apple.com/?daddr=${latitude},${longitude}&dirflg=d`;
    }

    if (isAndroid) {
      return `https://maps.google.com/maps?daddr=${latitude},${longitude}&dirflg=d`;
    }

    // Fallback link for other platforms or when the mobile app is not available
    return `https://maps.google.com/maps?daddr=${latitude},${longitude}`;
  };

  useEffect(() => {
    const map = new window.google.maps.Map(document.getElementById('map'), {
      mapId: '26a4c07079ff402c',
      center: { lat: 41.769596, lng: 12.341707 },
      zoom: 5.2,
      clickableIcons: true,
    });

    let markerIndex = 0;
    const markers = [];

    const dropMarker = () => {
      if (markerIndex < gioiellerie.length) {

        const item = gioiellerie[markerIndex];

        if(item.idAnagrafica  === '1'){
          item.Lat = null;
          item.Lng = null;
        }
        const marker = new window.google.maps.Marker({
          position: { lat: item.Lat, lng: item.Lng },
          map: map,
          icon: markerIcon,
          title: item.Denominazione,
          animation: window.google.maps.Animation.DROP,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <a class="text-decoration-none" target="_blank"  href="${generateSmartLink(
                item.Lat,
                item.Lng
              )}">
              <p class="font-bold m-0 text-center text-black">${item.Denominazione}</p>
              <p class="m-0 text-black text-center">${item.Indirizzo}</p>
              <p class="m-0 text-link font-bold text-center">ottieni indicazioni</p>
            </a>
          `,
        });

        marker.addListener('click', () => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }
          infoWindow.open(map, marker);
          infoWindowRef.current = infoWindow;
        });

        markers.push(marker);

        markerIndex++;
        setTimeout(dropMarker, 50); // Delay between markers in milliseconds (e.g., 1000 = 1 second)
      }
    };

    dropMarker();

        // Add current location button
        const currentLocationBtn = document.createElement('button');
        currentLocationBtn.innerHTML = `<img src='${myPosition}' alt="logo azienda" class="bg-contain w-11 me-2 border p-2 mt-4 rounded bg-neutral-100" />`;
        currentLocationBtn.classList.add('current-location-btn');
    
        const centerMapToCurrentLocation = () => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const { latitude, longitude } = position.coords;
                  map.setCenter({ lat: latitude, lng: longitude });
                  map.setZoom(12);

          
                  // Create user location marker
                  if (userLocationMarkerRef.current) {
                    userLocationMarkerRef.current.setPosition({ lat: latitude, lng: longitude });
                  } else {
                    const userLocationMarker = new window.google.maps.Marker({
                      position: { lat: latitude, lng: longitude },
                      map: map,
                      title: 'La mia posizione',
                      animation: window.google.maps.Animation.DROP,
                      icon: userIcon,
                    });
                    userLocationMarkerRef.current = userLocationMarker;
                  }
                },
                (error) => {
                  console.error('Error getting current location:', error);
                }
              );
            } else {
              console.error('Geolocation is not supported by this browser.');
            }
          };
        currentLocationBtn.addEventListener('click', centerMapToCurrentLocation);
        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(currentLocationBtn);
      
          const showCustomMarker = () => {
            if (customMarkerRef.current) {
              customMarkerRef.current.setMap(null); // Remove the existing marker from the map
              map.setCenter({ lat: 45.393285, lng: 10.900937 });
              map.setZoom(12);
            }
      
            const customMarker = new window.google.maps.Marker({
              position: { lat: 45.393285, lng: 10.900937 }, // Replace with the desired position for the custom marker
              map: map,
              title: 'Careisgold S.p.A',
              animation: window.google.maps.Animation.DROP,
              icon: sedeIcon, // Set the custom icon for the custom marker
              }); 

              const infoWindowSede = new window.google.maps.InfoWindow({
                content: 
                `
                <p class="font-bold mx-0 mb-2 text-center text-black">Careisgold S.p.A</p>
                <a class="text-decoration-none font-semibold" target="_blank" href="https://www.careisgold.it/"><p class="mt-0 mb-2 text-center">visita il sito</p></a>
                <a class="text-decoration-none font-semibold" target="_blank" href="https://goo.gl/maps/Vd15wfzxnCfXp58j7">ottieni indicazioni</a>
              `
              , 
              });
              customMarker.addListener('click', () => {
                if (infoWindowRef.current) {
                    infoWindowRef.current.close(); // Close the currently open info window
                  }
                  infoWindowSede.open(map, customMarker); // Show the info window when marker is clicked
                  infoWindowRef.current = infoWindowSede; // Update the infoWindowRef
                });
      
            customMarkerRef.current = customMarker;
          };

          const showCustomMarkerButton = document.createElement('button');
          showCustomMarkerButton.innerHTML = `<img src='${sedeIcon}' alt="logo azienda" class="bg-contain w-11 me-2 border p-2 rounded bg-neutral-100" />`;
          showCustomMarkerButton.classList.add('sede-btn');
          showCustomMarkerButton.addEventListener('click', showCustomMarker);
          map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(showCustomMarkerButton);    

    // return () => {
    //   // Clean up markers and close the info window on unmount
    //   markers.forEach((marker) => marker.setMap(null));
    //   if (infoWindowRef.current) {
    //     infoWindowRef.current.close();
    //   }
    // };
  }, []);

  return <div id="map" className="rounded" style={{"height":"475px"}}></div>;
};

export default MapOP;
