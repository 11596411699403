export default function BackgroundImageFade({src, content, ptClass='pt-44'}) {
    return<>
<div className='relative bg-black '>
          <div 
            className="fixed top-0 left-0 w-full h-full bg-cover bg-center"
            style={{ backgroundImage: `url(${src})` }}>
            <div className="absolute top-0 left-0 w-full h-full"></div>
          </div>
         <div className={`relative z-10 ${ptClass} text-white bg-gradient-custom`}>            
            {content}
          </div>
        </div>
    </>
}