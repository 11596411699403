import React from 'react';
import { ScrollParallax } from 'react-just-parallax';
import { Link } from 'react-router-dom';
import DefaultButton from './DefaultButton';

function FullMinimalSection({headerImage, logoTransp, title, description, href, logoPosition, buttonText, darkButton, download, buttonClass, downloadApp = false, androidIcon, hrefAndroid, appleIcon, hrefApple, secondButton, secondButtonText, href2, buttonClass2}) {

  const createMarkup = (htmlContent) => {
    return {__html: htmlContent};
  };

  return (
    <>
      <div className='relative z-20 h-lvh overflow-hidden'>
          <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
              <img src={headerImage} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} />
          </ScrollParallax>
          <div className="absolute top-0 left-0 bg-black bg-opacity-50 w-full h-full flex justify-center items-center">
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.08">
                  <img  src={logoTransp}      
                        data-aos="fade-in" data-aos-duration="3000"
                        className={`absolute ${logoPosition} object-contain`}
                        alt='logo careisgold'
                     />
              </ScrollParallax>
              {/* Text container centered and slightly moved to the right */}
              <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                <div className='lg:w-[50%]'>
                  <h2 className="lg:text-5xl text-4xl text-white font-semibold drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000" >{title}</h2>
                  <p className="text-xl mt-4 mb-10 text-white drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">{description}</p>

                 {download && buttonText ?  
                    <a href={href} download>
                      <button className={`${darkButton ? 'progress-button-light border-slate-50 text-slate-50' : 'border-black text-black progress-button-dark'} px-8 py-2 uppercase border text-center transition duration-300 ease-in-out  ${buttonClass !== '' ? buttonClass : 'w-full lg:w-1/2'}`}>
                        {buttonText}
                      </button>
                    </a>
                    :
                    <DefaultButton text={buttonText} link={href} dark={darkButton} buttonClass={buttonClass} />
                  }
                    {secondButton && <DefaultButton text={secondButtonText || 'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}


                  {downloadApp && 
                  <div className='flex absolute'>
                    <a href={hrefAndroid} target="_blank" className='lg:w-1/5 w-[40%]'>
                      <img src={androidIcon}  alt="Android Icon"></img>
                    </a>
                    <a href={hrefApple} target="_blank" className='lg:w-1/5 w-[40%] ml-10'>
                      <img src={appleIcon}  alt="Apple Icon"></img>
                    </a>
                  </div>
                  }
                </div>
              </div>
          </div>
      </div>
    </>
  );
}

export default FullMinimalSection;
