import BMAntonioM from '../assets/staff/Divisioneprivate/Antonio Mattiocco.jpg';
import BMPCarloM from '../assets/staff/Divisioneprivate/Carlo Melotti.png';
import BMLuigiP from '../assets/staff/Divisioneprivate/Luigi Premarini.jpg';
import BMMatteoM from '../assets/staff/Divisioneprivate/Matteo Maurizio.jpg';
import BMRobertoB from '../assets/staff/Divisioneprivate/Roberto Boschi.JPG';


const people = [
    {
        name: 'Carlo Melotti',
        role: 'Responsabile Divisione Private',
        imageUrl:BMPCarloM
    },
    {
        name: 'Luigi Premarini',
        role: 'Divisione Private',
        imageUrl:BMLuigiP
    },
    {
      name: 'Antonio Mattiocco',
      role: 'Divisione Private',
      imageUrl: BMAntonioM
    },
    {
        name: "Matteo Maurizio",
        role: 'Divisione Private',
        imageUrl:BMMatteoM
    },
    {
        name: 'Roberto Boschi',
        role: 'Responsabile Oro Prestige',
        imageUrl:BMRobertoB
    },
]

export default function DivisionePrivateStaff() {
    return (
        <>
            <ul
                role="list"
                className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
            >
                {people.map((person) => (
                <li key={person.name} 
                data-aos="fade-in" data-aos-duration="1500"
                >
                    <img className="mx-auto h-24 w-24 rounded-full bw-image" src={person.imageUrl} alt="" />
                    <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-neutral-200">{person.name}</h3>
                    <p className="text-sm leading-6 text-neutral-400">{person.role}</p>
                </li>
                ))}
            </ul>
        </>
    )
  }