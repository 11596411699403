import React from 'react';
import { ScrollParallax } from 'react-just-parallax';
import { Link } from 'react-router-dom';
import DefaultButton from './DefaultButton';

function BigImage({orientation, background, text, buttonClass1, buttonClass2, href2, href, firstButtonText, secondButton, secondButtonText, title, description, image, backgroundColor, bgOpacity, translate, grid, darkButton, buttonText, customClass}) {

    const createMarkup = (htmlContent) => {
        return {__html: htmlContent};
    };

    return (
        <>
        <div className={`z-20 overflow-hidden flex justify-center items-center block md:hidden ${backgroundColor}`}>
            <div className={`flex flex-col`}>
                <div className={``}>
                    <ScrollParallax enableOnTouchDevice={true} lerpEase="0.05" className="absolute inset-0">
                        <img src={background} className="h-full w-full object-cover pe-1" alt="background" style={{ transform: 'scale(1.4)' }} />
                    </ScrollParallax>
                </div>
                <div className={`z-40 lg:pb-20 sm:pb-56 text-start bg-gradient-to-top ${customClass}`}>
                    <h2 className={`text-3xl lg:text-5xl ${text} font-semibold text-balance`} data-aos="fade-up" data-aos-duration="1000">{title}</h2>
                    <div className={`text-md lg:text-lg mt-4 mb-10 ${text} text-balance`} data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(description)}></div>
                    <DefaultButton text={firstButtonText || 'Scopri di più'} link={href} dark={darkButton} buttonClass={buttonClass1} />
                    {secondButton && <DefaultButton text={secondButtonText || 'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}
                </div>
            </div>
        </div>
          {orientation === 'left' ? (
            <>
            <div className={`relative z-20 h-lvh overflow-hidden flex justify-center items-center hidden md:block ${backgroundColor}`}>
                <div className={`w-full h-full ${grid}`}>
                    <div className={`relative overflow-hidden flex justify-start items-center ${bgOpacity}`}>
                        <ScrollParallax enableOnTouchDevice={true} lerpEase="0.05" className="absolute inset-0">
                            <img src={background} className="h-full w-full object-cover pe-1" alt="background" style={{ transform: 'scale(1.2)' }} />
                        </ScrollParallax>
                    </div>
                    <div className={`flex flex-col justify-center items-start text-left z-10 xl:pe-16 xl:ps-0 px-16 ${translate}`}>
                        <h2 className={`text-3xl lg:text-5xl ${text} font-semibold text-balance`} data-aos="fade-up" data-aos-duration="1000">{title}</h2>
                        <div className={`text-md lg:text-lg mt-4 mb-10 ${text} text-balance`} data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(description)}></div>
                        <DefaultButton text={firstButtonText || 'Scopri di più'} link={href} dark={darkButton} buttonClass={buttonClass1} />
                        {secondButton && <DefaultButton text={secondButtonText || 'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}
                    </div>
                </div>
            </div>
            </>
          ):(
            <>
            <div className={`relative z-20 h-lvh overflow-hidden flex justify-center items-center hidden md:block ${backgroundColor}`}>
                <div className={`w-full h-full ${grid}`}>
                    <div className={`flex flex-col justify-center items-start text-left z-10 xl:pe-16 xl:ps-0 px-16 pb-30 ${translate}`}>
                        <h2 className={`text-3xl lg:text-5xl ${text} font-semibold`} data-aos="fade-up" data-aos-duration="1000">{title}</h2>
                        <div className={`text-md lg:text-lg mt-4 mb-10 ${text}`} data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(description)}></div>
                        <DefaultButton text={firstButtonText || 'Scopri di più'} link={href} dark={darkButton} buttonClass={buttonClass1} />
                        {secondButton && <DefaultButton text={secondButtonText || 'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}
                    </div>
                    <div className={`relative overflow-hidden flex justify-start items-center ${bgOpacity}`}>
                        <ScrollParallax enableOnTouchDevice={true} lerpEase="0.05" className="absolute inset-0">
                            <img src={background} className="h-full w-full object-cover pe-1" alt="background" style={{ transform: 'scale(1.2)' }}/>
                        </ScrollParallax>
                    </div>
                </div>
            </div>
            </>
          )}
        </>
    );
}

export default BigImage;
