import { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import 'react-vertical-timeline-component/style.min.css';
import { styles } from "../styles";
import { experiences } from "../constants";
import { SectionWrapper } from "../hoc";
import { textVariant } from "../utils/motion";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';


const ExperienceCard = ({ experience }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  
  const toggleModal = () => {
    setOpen(true);
  }

  return (
    <VerticalTimelineElement
      contentStyle={{ background: '#e6dedd', color: '#000'}}
      contentArrowStyle={{ borderRight: '7px solid #f8fafc'}}
      iconStyle={{background: '#f8fafc'}}
      icon={
        <div className="flex justify-center items-center w-full h-full">
          <img 
            src={experience.icon}
            alt={experience.company_name}
            className="w-[100%] h-[100%] object-cover rounded-full"
          />
        </div>
      }
    >
      <div className="text-start">
        <span className="text-black text-md font-bold m-0">{experience.date}</span>
        <p className="text-black text-md">{experience.desc} . . .</p>
      </div>
      <button onClick={toggleModal} className="mt-4 font-semibold text-gold">Scopri di più</button>
      <Transition.Root show={open} as={Fragment}>
  <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 glassModal bg-opacity-75 transition-opacity"></div>
    </Transition.Child>

    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden bg-neutral-100 text-left shadow-xl transition-all sm:w-full sm:max-w-5xl p-6 md:p-12 flex flex-col sm:flex-row">
              <div className="sm:w-1/2">
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="mySwiper w-full h-full"
                >
                  <SwiperSlide>          
                    <img 
                      src={experience.img1}
                      alt={experience.company_name}
                      className="w-full h-full object-contain"
                    />
                  </SwiperSlide>
                  <SwiperSlide>          
                    <img
                      src={experience.img2}
                      alt={experience.company_name}
                      className="w-full h-full object-contain"
                    />
                  </SwiperSlide>
                  <SwiperSlide>          
                    <img 
                      src={experience.img3}
                      alt={experience.company_name}
                      className="w-full h-full object-contain"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="mt-auto sm:w-1/2 sm:pl-6 text-left">
                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                  {experience.title}
                </Dialog.Title>
                <div className="hidden md:block mt-2">
                  <p className="text-sm text-gray-500">
                    {experience.text}
                  </p>
                </div>
                <div className="block md:hidden mt-2">
                  <p className="text-sm text-gray-500">
                    {experience.desc}
                  </p>
                </div>
                <div className="mt-2">
                  <p className="text-md font-semibold text-neutral-800">
                    <em>"{experience.cit}"</em>
                  </p>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
    </VerticalTimelineElement>
  )
}

const Experience = () => {
  return (
    <>
      <motion.div
        variants={textVariant()}
        className="custom-margin-top"
      >
        <p className={styles.sectionSubText}></p>
        {/* <h2 className='text-neutral-50 text-3xl font-semibold'>Nel corso degli anni, il Gruppo si è proiettato verso la vendita di beni rifugio proprio attraverso Careisgold,
        società per azioni che vanta un capitale sociale interamente versato di € 2.000.000,00 e Operatore
        Professionale in Oro e Banco Metalli.</h2> */}
      </motion.div>
      <div className="mt-20 flex flex-col">
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard key={index} experience={experience} />
          ))}
        </VerticalTimeline>
      </div>
    </>
  )
}

export default SectionWrapper(Experience, "work");
