import React, { useRef, useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import ContactForm from '../../components/contactForm/ContactForm';
import GoogleMaps from '../../components/GoogleMaps'; 
import { Wrapper } from "@googlemaps/react-wrapper";
import headerImage from "../../assets/lingotti/0017 - Still life Care is gold-Modifica.webp";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import MinimalArrow from '../../components/MinimalArrow';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'




function Contatti() {
  const [showMap, setShowMap] = useState(false)

  const googleMaps = 'AIzaSyCZyoDZ5eFh61i2vM3zyDXUI3WVp3J4E9Y'

  var ogImage = 'https://www.careisgold.it/static/media/0017%20-%20…re%20is%20gold-Modifica.58621a994aaa6176c39c.webp';

  useEffect(() => {
    if(window.location.hostname !== 'localhost'){
      setShowMap(true);
    }
  },[]);


  return (
    <div className="bg-black">
      <Helmet>
        <title>Contatta Careisgold | Assistenza e Informazioni</title>
        <meta name="description" content="Hai domande su investimenti in oro? Contatta Careisgold per assistenza professionale e informazioni su prodotti e servizi. Siamo qui per te." />
        <meta name="keywords" content="contatti Careisgold, assistenza oro, informazioni investimenti, supporto clienti" />
        <meta property="og:title" content="Contatta Careisgold | Assistenza e Informazioni" />
        <meta property="og:description" content="Hai domande su investimenti in oro? Contatta Careisgold per assistenza professionale e informazioni su prodotti e servizi. Siamo qui per te." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/contatti/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/contatti/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Careisgold SpA",
          "url": "https://www.careisgold.it/contatti/",
          "logo": ${logo},
          "description": "Hai domande su investimenti in oro? Contatta Careisgold per assistenza professionale e informazioni su prodotti e servizi. Siamo qui per te.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Monte Baldo 14/D",
            "addressLocality": "Villafranca di Verona",
            "addressRegion": "Veneto",
            "postalCode": "37069",
            "addressCountry": "IT"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+39 045 8213155",
            "contactType": "Customer Service"
          },
          "sameAs": [
            "https://www.facebook.com/careisgoldspa",
            "https://www.instagram.com/careisgoldspa",
            "https://www.linkedin.com/company/careisgold-spa"
          ]
        }
      `}
    </script>
      </Helmet>
      <Navbar />
      <MinimalArrow backgroundImage={headerImage} title="Scopri un mondo di opportunità: contattaci subito" Subtitle=""/>
      <div className='flex items-center justify-center pt-20 '>
        <div className='w-full px-4 lg:px-0'>
          <div className="flex flex-col lg:flex-row items-start justify-evenly">
          <div className='w-full lg:w-3/6'>
                {/* Content on the left */}
                <ContactForm 
                  LandingName='sito careisgold.it'
                  Name={true}
                  NameClass='text-slate-50'
                  NameText='Nome e Cognome:'
                  NameRequired={true}
                  Tel={true}
                  TelClass='text-slate-50'
                  TelText='Telefono:'
                  TelRequired={true}
                  Email={true}
                  EmailClass='text-slate-50'
                  EmailText='Email:'
                  EmailRequired={true}
                  Dropdown={true}
                  DropdownRequired={true}
                  DropdownItem={['Acquisto di un piano rateale', 'Acquisto unico', 'Lavorare con noi']}
                  DropdownClass='text-slate-50'
                  DropdownText='Interessato a:'
                  SendText='Invia'
                  SendClass='progress-button-light border border-slate-50 text-slate-50 w-full md:w-6/12 '
                  Message={true}
                  MessageClass='text-slate-50'
                  MessageText='Messaggio:'
                  MessageTextRequired={false}
                  ConsensoDati={true}
                  ConsensoDatiRequired={true}
                  ConsensoDatiText='consenso dati'
                  ConsensoDatiID={1}
                  ConsensoDatiClass='text-slate-50'
                  Newsletter={false}
                  NewsletterRequired={false}
                  NewsletterText='consenso dati newsletter'
                  NewsletterID={21}
                  NewsLetterClass='text-slate-50'
                  successText='Messaggio inviato con successo!'  
                  successLink='/'
                  errorText='Si è verificato un errore, Riprova!'
                />
              </div>
              <div className='w-full lg:w-2/6 py-8 lg:py-16'>
                  <div className='w-full bg-slate-50'>
                    {showMap &&
                      <Wrapper apiKey={googleMaps}>
                        <GoogleMaps />
                      </Wrapper>
                    }
                  </div>
                  <div className='w-full text-start text-slate-50 py-10'>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                        <FaMapMarkerAlt className="me-5" />
                        <a className="text-decoration-none text-dark font-medium opacity-75" rel="noreferrer noopener" href="https://goo.gl/maps/y7ADbUdXX1ZZppbK9" target="_blank">
                          <p className="m-0">Via Monte Baldo, 14/D 37069 Villafranca di Verona (VR)</p>
                        </a>
                      </li>
                      <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                        <FaPhone className="me-5" />
                        <a className="text-decoration-none text-dark font-medium opacity-75" href="tel:045 2457421" rel="noreferrer noopener">
                          <p className="m-0">045 2457421</p>
                        </a>
                      </li>
                      <li className="list-group-item bg-light ms-0 ps-0 flex items-center mb-3">
                        <FaEnvelope className="me-5" />
                        <a className="text-decoration-none text-dark font-medium opacity-75" href="mailto:info@careisgold.it" rel="noreferrer noopener">
                          <p className="m-0 lowercase">INFO@CAREISGOLD.IT</p>
                        </a>
                      </li>
                    </ul>
                    <p className="text-lg font-semibold mt-4">
                      Sede legale:
                    </p>
                    <p className="mt-2 pb-4">
                      Via Monte Baldo 14/D, Villafranca di Verona <br />
                      C.F./P.Iva 04598560235 - REA VR-433912<br />
                      CAP. 37069 SOCIALE € 2.000.000,00 i.v.<br />
                      <a className="text-decoration-none text-dark" href="mailto:careisgoldspa@pec.careisgold.it" rel="noreferrer noopener">careisgoldspa@pec.careisgold.it</a><br />
                      Operatore Professionale Oro - Codice n° 5008578
                    </p>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contatti;
