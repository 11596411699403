import React, { useRef, useEffect, useState } from 'react';
import MinimalSection from '../../components/MinimalSection';
import lingottiOro from '../../assets/lingotti/0082 - Still life Care is gold-Modifica.webp';
import lingottoKilo from '../../assets/lingotti/0020-Still-life-Care-is-gold-Modifica-2-Modifica-2.webp';
import Navbar from '../../components/Navbar';
import mountainVideo from "../../assets/mouseFollow/Hero_5.mp4";
import logoTransp from '../../assets/mouseFollow/logobianco.webp';
import logoFV from '../../assets/fondazioneVeronesi/FondazioneVeronesi.webp';
import logoFV1 from '../../assets/fondazioneVeronesi/Risorsa 1.png';
import logoFV12 from '../../assets/fondazioneVeronesi/Risorsa 2.png';
import logoFV2 from '../../assets/fondazioneVeronesi/logo1.png';

import { SlArrowDown } from 'react-icons/sl';
import Footer from "../../components/Footer";
import image1 from "../../assets/staticSection/family4.webp";
import image2 from "../../assets/staticSection/prodotto5.webp";
import FullMinimalSection from '../../components/FullMinimalSection';
import DefaultButton from '../../components/DefaultButton';
import fondazioneVeronesi from '../../assets/fondazioneVeronesi/Bimba-con-fiocco2.webp'
import { Helmet } from 'react-helmet';
import headerImage from "../../assets/staticSection/azienda_sede.webp";
import contattoUmano from '../../assets/consulenzaSupporto/contattoUmano.webp';
import contattoUmano2 from '../../assets/consulenzaSupporto/1crop.png';



function HomePage() {
  const [width, setWidth] = useState(window.innerWidth);
  var ogImage = 'https://www.careisgold.it/static/media/azienda_sede.ef12f4d81d9d70e63532.webp';

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSectionRef = useRef(null);

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="">
      <Helmet>
        <title>Lingotti oro da investimento: prezzo e piani con Careisgold</title>
        <meta name="description" content="Scopri i vantaggi dell'investimento in Oro con Careisgold. Lingotti con sistema anticontraffazione. Soluzioni per la tutela del patrimonio alla portata di tutti." />
        <meta property="og:title" content="Careisgold SpA" />
        <meta property="og:description" content="Scopri i vantaggi dell'investimento in Oro con Careisgold. Lingotti con sistema anticontraffazione. Soluzioni per la tutela del patrimonio alla portata di tutti." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/" />
        <meta property="og:type" content="page" /> 
        <meta name="keywords" content="investimento in oro, come investire in oro fisico, servizi di investimento in oro Careisgold, testimonianze reali investimento oro, benefici dell'oro come bene rifugio" />
        <meta name="author" content="Careisgold S.p.A" />
        <link rel="canonical" href="https://www.careisgold.it/" />
      </Helmet>
      <Navbar />
        <div className='relative z-20 h-lvh overflow-hidden bg-black'>
              <video className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} autoPlay muted playsInline loop>
                  <source src={mountainVideo} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
          <div className="absolute top-0 left-0 bg-black bg-opacity-0 md:bg-opacity-0 w-full h-full flex justify-center">
                  {/* Adjust the image to be more to the right and cover 100vh without stretching */}
                  <img src={logoTransp} className='opacity-20 w-2/3 hidden lg:block' style={{ position: 'absolute', right: '-30vh', height: '150vh', objectFit: 'contain' }} alt='logo careisgold' />
              <div className="absolute bottom-0 left-0 text-left pb-56 px-8 md:p-32"
                  data-aos="fade-up"
                  data-aos-duration="3000"
              >
                  <h1 className="text-4xl md:text-7xl text-white font-semibold drop-shadow-2xl">L’oro accessibile a tutti,<br/>prima di tutti</h1>
                  <p className="text-md md:text-xl mt-4 text-white drop-shadow-2xl">Careisgold: la prima azienda a rendere l’Oro da investimento<br/>accessibile a ogni famiglia.</p>
              </div>              
              <SlArrowDown 
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  onClick={scrollToNextSection} 
                  className='arrow cursor-pointer block absolute transform-translate-x-1/2 text-slate-50 text-3xl md:text-5xl' />
          </div>
      </div>
      <div ref={nextSectionRef}>
      </div>
      <MinimalSection
        orientation='right' 
        background='bg-black' 
        title="La scelta responsabile per il tuo futuro"
        titleClass='text-4xl text-white'
        description={`
        Lingotti d'oro puro 24k certificati, muniti di sofisticato sistema anticontraffazione. Soluzioni personalizzate e servizi per la tutela del patrimonio a partire dal costo di una colazione al giorno.
        `}
        descriptionClass='font-medium py-9  text-xl text-neutral-300'
        buttonText='Perché l’Oro?'
        href='/investire-in-oro'
        imageUrl={lingottiOro}
        imgOpacity="opacity-100"
        strength='0.14'
        lerp='0.30'
        buttonClass1='w-full lg:w-1/2'
        buttonClass2='hidden'
        darkButton={true}
        firstButtonText='Perché l’Oro?'
      />
      <FullMinimalSection 
        headerImage={width > 640 ? contattoUmano : contattoUmano2}
        logoTransp=''
        title='Per noi il contatto umano è fondamentale'
        description={`Non vendiamo online perché ci piace seguirti ancora come una volta. Con Careisgold avrai a disposizione un incaricato sempre
            pronto ad aiutarti in ogni fase del nostro percorso insieme. Inoltre, il nostro servizio clienti è sempre pronto ad offrirti supporto e assistenza.`}
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Scopri di più'
        secondButton={false}
        secondButtonText=''
        href='/consulenza-supporto'
        darkButton={true}
        buttonClass='w-full lg:w-1/2'
        buttonClass2='hidden'
        href2=''
      />
      <MinimalSection 
        orientation='left' 
        background='bg-neutral-50' 
        title='Banco Metalli certificato da Banca d’Italia' 
        titleClass='text-4xl text-black' 
        description={`Careisgold è la società per azioni specializzata nella vendita di beni rifugio. Iscritta nel registro della Banca
        d’Italia come Operatore Professionale in Oro e Banco Metalli con il codice n°5008578, Careisgold dispone
        della Licenza di Pubblica Sicurezza rilasciata dalla Questura per la distribuzione di Oro Puro Fisico da
        investimento.`}
        descriptionClass='text-bg-black font-normal tracking-wide text-pretty text-black text-xl pb-16'
        href='/azienda'
        imageUrl={lingottoKilo}
        imgOpacity="opacity-100"
        strength='0.14'
        lerp='0.30'
        buttonClass1='w-full lg:w-1/2'
        buttonClass2='hidden'
        darkButton={false}
        firstButtonText='Scopri cosa ci contraddistingue'
      />
      <FullMinimalSection 
        headerImage={image1}
        logoTransp=''
        title='Ora puoi realizzare ciò che prima era impossibile'
        description={`Da sempre, l’Oro è stato un bene riservato ai ceti sociali più abbienti. Oggi, grazie agli innovativi prodotti creati da Careisgold, ogni italiano può acquistare lingotti d’oro puro con soluzioni studiate per ogni
        esigenza.`}
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Scopri di più'
        secondButtonText=''
        secondButton={false}
        href='/prodotti'
        href2=''
        darkButton={true}
        buttonClass='w-full lg:w-1/2'
        buttonClass2='hidden'
      />
      <FullMinimalSection 
        headerImage={image2}
        logoTransp=''
        title='Novità e approfondimenti'
        description={`Per non perdere nessun aggiornamento sul mondo Careisgold e scoprire tante curiosità sul metallo
        prezioso per eccellenza, consulta la nostra rassegna stampa e tutti i nostri approfondimenti.`}
        href='/notizie'
        href2=''
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Esplora'
        secondButtonText=''
        secondButton={false}
        darkButton={true}
        buttonClass='w-full lg:w-1/2'
        buttonClass2='hidden'
      />

      <section>
      <div className='relative mx-auto hidden lg:block'>
        <div className='h-lvh flex justify-center items-end sm:items-center relative'>
          {/* Left Content Section */}
          <div className={`absolute top-0 left-0 h-full w-full 2xl:w-3/5 bg-transparent flex justify-start 2xl:justify-center items-center p-4 z-0`}>
            <div className='text-left max-w-xl lg:max-w-xl xl:max-w-xl'>
              <div className='flex xl:flex-row flex-col items-start xl:items-start xl:justify-around'>
                <img src={logoFV2} alt='logo careisgold sostiene fondazione veronesi' className='object-contain w-1/2 px-4 -mt-5' />
                <img src={logoFV1} alt='logo careisgold sostiene fondazione veronesi' className='object-contain w-1/2 px-4 h-[7rem]' />
              </div>
              <h1 className={`font-semibold text-2xl text-neutral-50 text-balance my-10`} data-aos="fade-up" data-aos-duration="900">
                Careisgold sostiene Fondazione Veronesi 
                <span className='ms-2'>nella lotta contro i tumori infantili</span>
              </h1>
              <div data-aos="fade-zoom-in" data-aos-duration="1000" className={`text-xl mb-10 text-neutral-300 text-balance`}>
                <b>Prendersi cura</b> delle persone è il fulcro dell'azienda, il nostro impegno ci ha portato a realizzare un nuovo progetto, che vuole mettere al centro i bambini malati di tumore.
              </div>
              <DefaultButton text='Scopri di più' link='https://beneficenza.careisgold.it/careisgold-fondazione-veronesi' dark={true} buttonClass='w-full' />
            </div>
          </div>  

          {/* Right Background Section */}
          <div className={`absolute top-0 right-0 h-full w-full lg:w-1/4 bg-white`}>
          </div>  

          {/* Image Section */}
          <div className='absolute sm:h-full right-0 w-2/5 overflow-hidden'>
            <img 
              src={fondazioneVeronesi} 
              alt='fondazione veronesi' 
              className='h-full w-[100%] ms-auto object-left-top object-cover z-20' 
            />
          </div>
        </div>
      </div>
        <div className={`top-0 right-0 h-full w-full lg:w-1/4 bg-white lg:hidden block md:px-[10rem] md:pt-10 px-5 `}>
              <div className='z-40 relative top-0 px-5 flex md:flex-row flex-col md:pb-5'>
                <img src={logoFV2} alt='logo careisgold sostiene fondazione veronesi' className='object-contain w-1/2  mx-auto md:px-2 md:-mt-[2rem] pt-5' />
                <img src={logoFV12} alt='logo careisgold sostiene fondazione veronesi' className='object-contain w-1/2  mx-auto md:px-5  py-5' />
              </div>
              <DefaultButton text='Scopri di più' link='https://beneficenza.careisgold.it/careisgold-fondazione-veronesi' dark={false} buttonClass='w-full' />
            <img src={fondazioneVeronesi} alt='fondazione veronesi' className='h-full left-0 object-contain z-20' />
        </div>  
      </section>
      <Footer banner={true}/>
    </div>
  )
}

export default HomePage