import sede from "../assets/company/Sede.webp";
import logoHolding from '../assets/company/Logo Holding.webp';
import mamma from '../assets/company/Foto_mamma_Boso_1.webp';
import img20091 from '../assets/company/2009/2009_2.1.webp';
import img20092 from '../assets/company/2009/2009_2.webp';
import img20093 from '../assets/company/2009/2009_1.webp';
import img20121 from '../assets/company/2012/Sede.webp';
import img20122 from '../assets/company/2012/2013_4.webp';
import img20123 from '../assets/company/2012/2013_4 (2).webp';
import img20161 from '../assets/company/2016/2016_3.webp';
import icon2016 from '../assets/company/2016/2016.webp';
import img20162 from '../assets/company/2016/In GabbiaNo.webp';
import img20163 from '../assets/company/2016/2016_1.webp';
import img20171 from '../assets/company/2017/Foto_mamma_Boso_1.webp';
import img20172 from '../assets/company/2017/Ricerca1.webp';
import img20173 from '../assets/company/2017/Ricerca2.webp';
import img20181 from '../assets/company/2018/Italpreziosi.webp';
import logo2018 from '../assets/company/2018/2018.webp';
import img20182 from '../assets/company/2018/2018_1.webp';
import img20183 from '../assets/company/2018/Sole24Ore.webp';
import img20201 from '../assets/company/2020/_C9A9336.webp';
import logo2020 from '../assets/company/2020/2020.webp';
import img20202 from '../assets/company/2020/The Golden Era.webp';
import img20203 from '../assets/company/2020/WhatsApp Image 2024-04-30 at 14.28.21.webp';
import img20231 from '../assets/company/2023/1Q8A9553.webp';
import logo2023 from '../assets/company/2023/2023.webp';
import img20232 from '../assets/company/2023/1Q8A9789.webp';
import img20233 from '../assets/company/2023/1J1A0945.webp';
import img20243 from '../assets/company/2024/TGF.webp';
import img20244 from '../assets/company/2024/D1B03659.webp';
import img20245 from '../assets/company/2024/0162 - Inaugurazione Care is Gold.webp';
import logo2024 from '../assets/company/2024/2024.webp';




  const experiences = [
    {
      title: "PER SAPERE DOVE ANDARE, RICORDATI LE TUE ORIGINI, NON IMPORTA QUANTO UMILI SIANO.",
      desc:'Dopo diversi sforzi e sacrifici, Claudio Boso diventa Amministratore della SpA italiana di un’importante azienda svizzera, per la quale raggiunge straordinari successi. Nel 2005',
      text:'Dopo diversi sforzi e sacrifici, Claudio Boso diventa Amministratore della SpA italiana di un’importante azienda svizzera, per la quale raggiunge straordinari successi. Nel 2005, improvvisamente, le cose cambiano. Disillusioni e promesse non mantenute costringono Boso a dover prendere una decisione: vivere di ciò che si era faticosamente guadagnato, oppure… rimettersi in gioco e restituire tutto ciò che aveva appreso in quegli anni. Così, sfrutta la sua pluriennale esperienza in ambito finanziario per fondare la nuova realtà: Care Holding SpA. Perché “Care” Holding? Perché “care” letteralmente significa “cura, protezione, attenzione” e la missione del Gruppo vuole essere proprio questa: prendersi “cura” delle persone, siano esse collaboratori o clienti, tutelando il loro benessere e il loro patrimonio, per un futuro più sereno.',
      cit:'Esistono tre fasi nella vita di ognuno: la prima in cui studi, impari ed esplori, la seconda in cui raccogli ciò che hai seminato e lo metti in pratica, e la terza… è quella in cui ridai a qualcuno tutto ciò che hai ricevuto',
      icon: logoHolding,
      img1:img20091,
      img2:img20092,
      img3:img20093,
      date: "2009",
    },
    {
      title: "CRESCERE SIGNIFICA ABBRACCIARE IL CAMBIAMENTO.",
      desc:'Operando principalmente in ambito finanziario e assicurativo, il Gruppo Care Holding cresce in breve tempo e arriva a contare oltre venti mila clienti.',
      text:'Operando principalmente in ambito finanziario e assicurativo, il Gruppo Care Holding cresce in breve tempo e arriva a contare oltre venti mila clienti. Infatti - grazie alle sue diverse consociate – l’azienda sviluppa una rete di consulenti presente in modo capillare su tutto il territorio nazionale. Inoltre, viene istituita la Care Master University, con percorsi di formazione ad hoc, e aumentano le opportunità di brand awareness grazie a riviste ed eventi. Per gestire la sempre maggiore operatività, c’è bisogno di uno spazio più ampio: così, nel 2012, il Gruppo si trasferisce presso la nuova sede di Villafranca di Verona.',
      cit:'Dico sempre: vuoi realizzare qualcosa di davvero grande? Vuoi essere uno di quelli che lascia un segno? Allora procedi passo dopo passo, dal piccolo… al grande',
      icon: sede,
      img1:img20121,
      img2:img20122,
      img3:img20123,
      date: "2012",
    },
    {
      title: "GUARDARE OLTRE SE STESSI PER COMPRENDERE IL MONDO CHE CI CIRCONDA.",
      desc:'Dopo anni di successi, migliaia di clienti soddisfatti e collaboratori realizzati, il Gruppo affronta un periodo di stasi: vendere prodotti finanziari diventa molto più regolamentato e, in poco tempo, il mercato si trasforma in un vero e proprio Oceano Rosso',
      text:'Dopo anni di successi, migliaia di clienti soddisfatti e collaboratori realizzati, il Gruppo affronta un periodo di stasi: vendere prodotti finanziari diventa molto più regolamentato e, in poco tempo, il mercato si trasforma in un vero e proprio Oceano Rosso, dove la concorrenza si fa più spietata e gli operatori lottano tra loro come veri e propri squali, pronti a prevaricarsi gli uni con gli altri. Di fronte a tale situazione, Boso sente la necessità di un rinnovamento: inizia così un periodo di profonda ricerca e studio di nuovi prodotti e soluzioni innovative, con l’obiettivo di trovare… un Oceano Blu, senza inquilini aggressivi, dove si può operare con tranquillità e profitto.',
      cit:'Quando attraversiamo un periodo di grandi sfide, dobbiamo ricordarci che le avversità hanno un rovescio della medaglia preziosissimo: sono straordinarie opportunità',
      icon: icon2016,
      img1:img20161,
      img2:img20162,
      img3:img20163,
      date: "2016",
    },
    {
      title: "NEL MOMENTO PIÙ BUIO BISOGNA SAPER SCORGERE I SEGNALI CHE EMANA L’UNIVERSO.",
      desc:'Un momento delicato quanto importante: proprio dopo la scomparsa della madre, il Presidente scopre che la stessa aveva accantonato per lui dei lingotti d’oro puro fisico.',
      text:'Un momento delicato quanto importante: proprio dopo la scomparsa della madre, il Presidente scopre che la stessa aveva accantonato per lui dei lingotti d’oro puro fisico. Così, Boso decidere di approfondire il mondo del prezioso metallo giallo, scoprendone tutti gli aspetti e realizzando tutte le sue infinite potenzialità. È la svolta che stava aspettando, e che cambierà le sorti del Gruppo Care Holding.',
      cit:'…Mi chiesi: come posso continuare a garantire alle persone sicurezza e stabilità, stando al passo con i tempi? E la risposta arrivò in uno dei momenti più difficili della mia vita… la perdita di mia madre',
      icon: mamma,
      img1:img20171,
      img2:img20172,
      img3:img20173,
      date: "2017",
    },
    {
      title: "IL SENSO DELLA VITA È AVERE OBIETTIVI DI VALORE.",
      desc:'Seguendo l’esempio di Henry Ford, Boso vuole trovare un modo per rendere l’oro puro fisico, prima appannaggio di un certo ceto sociale, accessibile a tutti.',
      text:'Seguendo l’esempio di Henry Ford, Boso vuole trovare un modo per rendere l’oro puro fisico, prima appannaggio di un certo ceto sociale, accessibile a tutti. Così, proprio grazie alla sua esperienza e alle competenze maturate in ambito finanziario e assicurativo, studia nuovi, rivoluzionari prodotti e servizi, creando un nuovo business: Careisgold SpA. Con Careisgold, investire nel bene rifugio per eccellenza non è più un lusso per pochi, ma diventa un sogno realizzabile da chiunque. Questa è l’idea vincente e Boso, con orgoglio e soddisfazione, afferma: “Non abbiamo inventato nulla, ma abbiamo inventato tutto!”',
      cit:'Henry Ford disse: ‘Un giorno ogni famiglia americana avrà una delle mie automobili’. Voglio far mio il suo sogno: un giorno, ogni famiglia italiana avrà la propria riserva aurea Careisgold',
      icon: logo2018,
      img1:img20181,
      img2:img20182,
      img3:img20183,
      date: "2018",
    },
    {
      title: "LA NOSTRA UNIONE È LA NOSTRA FORZA.",
      desc:'Il 2020 vede l’arrivo in azienda del primo figlio di Boso, Samuel, seguito dal fratello Mattia nel 2021: un grande orgoglio per il Presidente!',
      text:'Il 2020 vede l’arrivo in azienda del primo figlio di Boso, Samuel, seguito dal fratello Mattia nel 2021: un grande orgoglio per il Presidente! Inoltre, se per molte imprese vi è il forte problema del “ricambio generazionale”, avere Samuel e Mattia in Careisgold garantisce continuità per l’azienda. E questo, per Boso, è un elemento indispensabile per consolidare la vision di Careisgold: garantire stabilità e sicurezza per tutte le generazioni future, clienti e collaboratori, attraverso l’oro da investimento.',
      cit:'La cosa bella della famiglia è avere sempre qualcuno dalla tua parte',
      icon: logo2020,
      img1:img20201,
      img2:img20202,
      img3:img20203,
      date: "2020",
    },
    {
      title: "AGISCI COME SE QUEL CHE FAI FACESSE LA DIFFERENZA. LA FA!",
      desc:'Careisgold, forte del DNA finanziario e “di vendita” della Holding, diventa presto il benchmark nel mercato, moltiplicando il fatturato di anno in anno e conquistando tutti, clienti e collaboratori. In altre parole, Careisgold crea un vero e proprio Oceano Oro, in cui naviga a vele spiegate, inarrestabile.  ',
      text:'Careisgold, forte del DNA finanziario e “di vendita” della Holding, diventa presto il benchmark nel mercato, moltiplicando il fatturato di anno in anno e conquistando tutti, clienti e collaboratori. In altre parole, Careisgold crea un vero e proprio Oceano Oro, in cui naviga a vele spiegate, inarrestabile. Dopo gli straordinari traguardi raggiunti nel 2022, definito da Boso “l’anno della conferma”, il Presidente decide di iniziare il 2023 con un evento unico, per mostrare a tutto il mercato questa importante novità: “The Golden Era” è l’evento dell’anno.',
      cit:'Non devi avere paura di condividere il tuo sapere. Dividerlo con gli altri non toglierà nulla a te! Diffondere conoscenza ne genera altra, che tornerà indietro anche a te, aumentando maggiormente la tua',
      icon: logo2023,
      img1:img20231,
      img2:img20232,
      img3:img20233,
      date: "2023 ",
    },
    {
      title: "SE OGNI GIORNO TI DEDICHI AL TUO OBIETTIVO CON IMPEGNO, I RISULTATI ARRIVERANNO!",
      desc:'Careisgold continua a crescere, attraversando un significativo processo di trasformazione ed evolvendo da azienda di tipo familiare ad una realtà più strutturata e organizzata.',
      text:'Careisgold continua a crescere, attraversando un significativo processo di trasformazione ed evolvendo da azienda di tipo familiare ad una realtà più strutturata e organizzata. Per poter gestire al meglio tutti i processi e fornire un supporto più efficace alle varie divisioni aziendali e quindi a collaboratori e clienti, si rende necessario un ampliamento degli uffici.',
      cit:'Le aziende crescono se le persone crescono',
      icon: logo2024,
      img1:img20243,
      img2:img20244,
      img3:img20245,
      date: "2024",
    },
  ];
  

  export { experiences };