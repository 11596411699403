import React, { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const GoldPrice = () => {
  const [goldData, setGoldData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = '';
        let bodyData = {};
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        switch (selectedFilter) {
          case 'All':
            url = 'https://crm.careholding.it/ws/Call/?Cat=Oro&met=GetPrezziOroMediClientePublic&np=1';
            bodyData = { 'Piano': '' };
            break;
          case 'LastYear':
            url = 'https://crm.careholding.it/ws/Call/?Cat=Oro&met=GetQuotazioniLastYearOroPublic&np=1';
            bodyData = { 'StartDate': formattedDate };
            break;
          case 'LastMonth':
            url = 'https://crm.careholding.it/ws/Call/?Cat=Oro&met=GetQuotazioniMonthOroPublic&np=2';
            bodyData = { 'Anno': year, 'Mese': month };
            break;
          default:
            break;
        }

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        });

        let modifiedData = await response.json();
        modifiedData = modifiedData.reverse();

        switch (selectedFilter) {
          case 'All':
            modifiedData = modifiedData.map(item => {
              const year = Math.floor(item.DataRiferimento / 100);
              const month = String(item.DataRiferimento % 100).padStart(2, '0');
              const formattedDate = `${month}-${year}`;
              return {
                date: formattedDate,
                price: item.PrezzoMedioFixing
              };
            });
            break;
          case 'LastYear':
            modifiedData = modifiedData.map(item => {
              const dateObj = new Date(item.DataRiferimento);
              const day = String(dateObj.getDate()).padStart(2, '0');
              const month = String(dateObj.getMonth() + 1).padStart(2, '0');
              const year = dateObj.getFullYear();
              return {
                date: `${day}-${month}-${year}`,
                price: item.PrezzoEuro
              };
            });
            break;
          case 'LastMonth':
            modifiedData = modifiedData.map(item => {
              const dateObj = new Date(item.DataRiferimento);
              const day = String(dateObj.getDate()).padStart(2, '0');
              const month = String(dateObj.getMonth() + 1).padStart(2, '0');
              const year = dateObj.getFullYear();
              return {
                date: `${day}-${month}-${year}`,
                price: item.EurPm
              };
            });
            break;
          default:
            break;
        }

        modifiedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        setGoldData(modifiedData);
      } catch (error) {
        console.error('Error fetching gold price data:', error);
      }
    };

    fetchData();
  }, [selectedFilter]);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='w-full xl:w-2/3'>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart 
          data={goldData}
          margin={{
            right: 50,
            left: 0,
          }}
            >
            <CartesianGrid stroke="#555555" strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              formatter={(value) => [`€${value} / g`, 'Prezzo']}
              labelFormatter={(label) => `Data: ${label}`}
              itemStyle={{ color: '#000' }} // Custom color for the value
              labelStyle={{ color: '#000' }} // Custom color for the label
            />
            <Area type="monotone" dataKey="price" stroke="#b7955a" fill="#b7955a" />
          </AreaChart>
        </ResponsiveContainer>
        <div className='flex flex-col md:flex-row md:space-x-5 mt-10 mx-10'>
          <button
            type="button"
            onClick={() => handleFilterChange('All')}
            className="progress-button-light border-slate-50 text-slate-50 px-20 py-2 uppercase border text-center transition duration-300 ease-in-out w-full mb-4"
          >
            Tutto
          </button>
          <button
            type="button"
            onClick={() => handleFilterChange('LastYear')}
            className="progress-button-light border-slate-50 text-slate-50 px-20 py-2 uppercase border text-center transition duration-300 ease-in-out w-full mb-4"
          >
            Ultimo anno
          </button>
          <button
            type="button"
            onClick={() => handleFilterChange('LastMonth')}
            className="progress-button-light border-slate-50 text-slate-50 px-20 py-2 uppercase border text-center transition duration-300 ease-in-out w-full mb-4"
          >
            Ultimo mese
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoldPrice;
