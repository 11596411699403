import React, { useEffect, useRef, useState } from 'react';
import MouseFollow from '../../../components/MouseFollow';
import FadeSlider from '../../../components/FadeSlider';
import videoFile from '../../../assets/oroPrestige/Lingotto50gr.mp4'; 
import iconImage from '../../../assets/oroPrestige/oroprestige icon.webp';
import DefaultButton from '../../../components/DefaultButton';
import { ScrollParallax } from 'react-just-parallax';
import family2 from "../../../assets/oroPrestige/beach-sunset-and-silhouette-of-family-on-vacation-2023-11-27-04-58-21-utc.webp";
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from '../../../assets/oroPrestige/marker30.svg';
import L from 'leaflet';
import { useNavigate, useParams } from 'react-router-dom';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { GiPositionMarker } from "react-icons/gi";
import DataGioiellerie from "../../../components/Gioiellerie";
import { Helmet } from 'react-helmet';
import logo from '../../../assets/oroPrestige/logo-trasp.webp'

function SingleGioielleria() {
    const { name, city } = useParams();
    const [gioielleria, setGioielleria] = useState({});
    const [position, setPosition] = useState([]);
    const mapsUrl = gioielleria.Indirizzo ? `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(gioielleria.Indirizzo)}` : '';
    const navigate = useNavigate();

    const customIcon = L.icon({
        iconUrl: markerIcon,
        iconSize: [45, 45],
        iconAnchor: [16, 32],
        popupAnchor: [5, -32]
    });

   
    const setData = (data) => {
        const removeAccents = (str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        };
        
        const formattedName = name.replace(/-/g, ' ');
        const gioielleria = data.find(
            item => item.Denominazione.toLowerCase() === formattedName.toLowerCase()
        );
        const formattedCityName = removeAccents(city.replace(/-/g, ' ').toLowerCase());
        const provenienzaGioielleria = removeAccents(gioielleria.city.toLowerCase());
      

        if(formattedCityName !== provenienzaGioielleria) {
            navigate('/not-found')
            return;
        }

        if (gioielleria) {
            setGioielleria(gioielleria);
            setPosition([gioielleria.Lat, gioielleria.Lng]);
        } else {
            console.log('No matching gioielleria found');
        }


    }

    useEffect(() => {
        setData(DataGioiellerie);
        // const fetchData = async () => {
        //     try {
        //         const res = await axios.post(`https://crm.careholding.it/ws/Call/?Cat=Gioiellerie&met=GetListaGioiellerieApertePublic&np=0`);
        //         setData(res.data);
        //     } catch (err) {
        //         console.log(err);
        //     }
        // };
        // fetchData();
    }, []);

    const nextSectionRef = useRef(null);

    // Function to scroll to the next section
    const scrollToNextSection = () => {
      nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <>
        <Helmet>
            <title>{`Careisgold SpA: ${gioielleria.Denominazione}`}</title>
            <meta name="description" content={gioielleria.desc} />
            <meta name="keywords" content={`careisgold, gioielleria, gioielli in oro, collezione esclusiva, qualità, gioiellerie ${gioielleria.city}`} />
            <meta property="og:title" content={`Careisgold SpA: ${gioielleria.Denominazione}`} />
            <meta property="og:description" content={gioielleria.desc} />
            <meta property="og:image" content={`https://www.careisgold.it${gioielleria.img}/`} />
            <meta property="og:url" content={`https://www.careisgold.it/oro-prestige/${gioielleria.city}/${name}/`} />
            <meta property="og:type" content="page" />
            <link rel="canonical" href={`https://www.careisgold.it/oro-prestige/${gioielleria.city}/${name}/`} />
            <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Careisgold SpA: Gioielleria",
          "url": "https://www.careisgold.it/oro-prestige/${gioielleria.city}/${name}/",
          "description": ${gioielleria.desc},
          "inLanguage": "it",
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "url": ${gioielleria.img}
          },
          "publisher": {
            "@type": "Organization",
            "name": "Careisgold SpA",
            "logo": {
              "@type": ImageObject,
              "url": ${logo}
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Via Monte Baldo 14/D",
              "addressLocality": "Villafranca di Verona",
              "addressRegion": "Veneto",
              "postalCode": "37069",
              "addressCountry": "IT"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+39 045 8213155",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/careisgoldspa",
              "https://www.instagram.com/careisgoldspa",
              "https://www.linkedin.com/company/careisgold-spa"
            ]
          }
        }
      `}
    </script>

  </Helmet>
            <MouseFollow />
            <section className='mx-auto bg-black h-lvh flex flex-col md:flex-row justify-top md:justify-center items-top px-5 z-40'>
                    <div className={`sm:flex w-full md:w-2/4 justify-center items-center`}>
                        <FadeSlider width='w-[50vh] h-[50vh] mx-auto' image1={gioielleria.img} image2={gioielleria.img1} image3={gioielleria.img2}/>
                    </div>
                    <div className={`w-full md:w-2/4  flex justify-center items-center p-4`}>
                        <div className='text-center md:text-left w-full md:max-w-md lg:max-w-lg xl:max-w-xl me-auto'>
                            <h1 className={`text-3xl md:text-5xl font-semibold text-animate-gold uppercase mb-2 text-center md:text-start`} data-aos="fade-up" data-aos-duration="900">{gioielleria.Denominazione}</h1>
                            <div data-aos="fade-zoom-in" data-aos-duration="1000" className={`text-sm md:text-lg mb-4`}>
                                <p className='font-medium my-9 text-xl text-neutral-300 text-center md:text-start'>{gioielleria.desc}</p> 
                            </div>
                            <button onClick={scrollToNextSection} className={` 'progress-button-light border-slate-50 text-slate-50 px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full`}>
                                Contattaci
                            </button>
                        </div>
                    </div>   
            </section>
            <section className='flex flex-col sm:flex-row h-lvh justify-center items-center bg-lingotto-3d-op z-40'>
                <div className='w-full md:w-3/5 xl:w-2/5 bg-lingotto-3d-op flex justify-center items-center px-4 pt-10 md:pt-0'>
                    <div className='text-left w-full sm:max-w-md lg:max-w-lg xl:max-w-xl me-auto'>
                        <p className='text-3xl md:text-5xl font-semibold uppercase mb-2 text-center md:text-start' data-aos="fade-up" data-aos-duration="900">Oro Prestige by Careisgold</p>
                        <div data-aos="fade-zoom-in" data-aos-duration="1000" className='text-sm md:text-lg mb-4'>
                            <ul className="list-disc text-xl space-y-6 text-black my-10">
                                <li className="flex items-center mb-3" data-aos="fade-right" data-aos-duration="1000">
                                    <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" /> 
                                    Il bene rifugio per eccellenza
                                </li>
                                <li className="flex items-center mb-3" data-aos="fade-right" data-aos-duration="1300">
                                    <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" />
                                    Nel tempo mantiene il suo valore ed il suo potere d’acquisto
                                </li>
                                <li className="flex items-center mb-3" data-aos="fade-right" data-aos-duration="1600">
                                    <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" />
                                    Protegge il patrimonio dall’inflazione e dalle crisi di mercato
                                </li>
                                <li className="flex items-center mb-3" data-aos="fade-right" data-aos-duration="1900">
                                    <img src={iconImage} alt="Icon" className="mr-2 w-8 h-8" />
                                    È esente IVA
                                </li>
                            </ul>
                            <DefaultButton text='Scopri di più' link='/oro-prestige' dark={false} buttonClass='w-full' />
                        </div>
                    </div>
                </div>   
                <div className='w-full md:w-2/5 xl:w-2/5 flex justify-center items-center'>
                      <video className='h-full w-auto' autoPlay loop muted>
                        <source src={videoFile} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                </div>
            </section>
            <section>
                <div className='relative z-20 h-lvh overflow-hidden'>
                    <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                        <img src={family2}  alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" />
                    </ScrollParallax>
                    <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full flex justify-center items-center">
                        <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                            <div className='lg:w-[50%]'>
                                <h2 className="lg:text-5xl text-4xl text-white font-semibold" data-aos="fade-up" data-aos-duration="1000">OLTRE IL REGALO</h2>
                                <p className="text-xl mt-4 mb-10 text-white" data-aos="fade-up" data-aos-duration="1000">
                                    Proteggi il futuro delle persone che ami. <br /> Regalare lingotti d’oro non è solo un gesto d’amore puro, ma anche un investimento nel loro domani.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='mx-auto'>
                    <div className='h-lvh flex flex-col md:flex-row justify-center items-center bg-black z-40'>
                        <div className={`md:hidden block w-3/5 sm:w-2/5 bg-black flex justify-center items-center px-5 py-20`}>
                            <div className='text-center md:text-left w-full sm:max-w-md lg:max-w-lg xl:max-w-xl me-auto'>
                                <h1 className={`text-3xl md:text-5xl font-semibold text-animate-gold uppercase mb-2 text-center md:text-start`} data-aos="fade-up" data-aos-duration="900">CONTATTI</h1>
                                <div data-aos="fade-zoom-in" data-aos-duration="1000" className={`text-sm md:text-lg mb-4`}>
                                    <ul className='text-neutral-50'>
                                        <li className='mt-4'>
                                            <a href={mapsUrl} target='_blank' rel='noopener noreferrer' className='flex items-center'><GiPositionMarker className='text-gold text-3xl me-5'/>{gioielleria.Indirizzo}</a>
                                        </li>
                                        <li className='mt-4'>
                                            <a className='flex items-center' href={`tel:${gioielleria.tel}`}><FaPhoneAlt className='text-gold text-3xl me-5 px-1'/>{gioielleria.tel}</a>
                                        </li>                                    
                                        <li className='mt-4'>
                                            <a className='flex items-center' href={`mailto:${gioielleria.mail}`}><IoMdMail className='text-gold text-3xl me-5 px-1'/>{gioielleria.mail}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>  
                        <div className={`h-screen flex w-full md:w-2/4 xl:w-2/5 justify-center items-top md:items-center`}>
                            {position.length > 0 && (
                                <MapContainer center={position} zoom={15} className='md:h-50 md:w-50 w-[90%] h-[70%]'>
                                    <TileLayer
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={position} icon={customIcon}>
                                        <Popup>
                                            <div className='text-center'>
                                                <span className='font-semibold text-lg uppercase'>{gioielleria.Denominazione}</span><br/>
                                                <a href={mapsUrl} target='_blank' rel='noopener noreferrer'>{gioielleria.Indirizzo}</a>
                                            </div>
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                            )}
                        </div>
                        <div ref={nextSectionRef}>
                        </div>
                        <div className={`md:block hidden w-3/5 sm:w-2/5 bg-black flex justify-center items-center p-5`}>
                            <div className='text-center md:text-left w-full sm:max-w-md lg:max-w-lg xl:max-w-xl me-auto'>
                                <h1 className={`text-3xl md:text-5xl font-semibold text-animate-gold uppercase mb-2 text-center md:text-start`} data-aos="fade-up" data-aos-duration="900">CONTATTI</h1>
                                <div data-aos="fade-zoom-in" data-aos-duration="1000" className={`text-sm md:text-lg mb-4`}>
                                    <ul className='text-neutral-50'>
                                        <li className='mt-4'>
                                            <a href={mapsUrl} target='_blank' rel='noopener noreferrer' className='flex items-center'><GiPositionMarker className='text-gold text-3xl me-5'/>{gioielleria.Indirizzo}</a>
                                        </li>
                                        <li className='mt-4'>
                                            <a className='flex items-center' href={`tel:${gioielleria.tel}`}><FaPhoneAlt className='text-gold text-3xl me-5 px-1'/>{gioielleria.tel}</a>
                                        </li>                                    
                                        <li className='mt-4'>
                                            <a className='flex items-center' href={`mailto:${gioielleria.mail}`}><IoMdMail className='text-gold text-3xl me-5 px-1'/>{gioielleria.mail}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
        </>
    );
}

export default SingleGioielleria;
