import React, { useEffect, useRef, useState } from 'react';
import goldbar from '../../assets/lingotti/100g - Still life Careisgold-Modifica.webp';
import Navbar from '../../components/Navbar';
import kinegram from "../../assets/lingotti/Retro_kinegram.webp";
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'


const ImageFollow = () => {
  const containerRef = useRef(null);
  const [imageLeft, setImageLeft] = useState('10%');
  const transitionDuration = '1s';
  const [section, setSection] = useState('');
  const sectionRef = useRef(section);
  const [width, setWidth] = useState(window.innerWidth);
  const [rotateImage, setRotateImage] = useState(false);
  const [imageChange, setImageChange] = useState(false);

  useEffect(() => {
    sectionRef.current = section;
  }, [section]);

  useEffect(() => {
    const handleScrollStop = () => {
      setTimeout(() => {
          const sectionElement = document.querySelector(sectionRef.current); // Use the ref here
        if (sectionElement && width > 1024) {
          sectionElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

      }, 100);
    };

    let scrollTimeout = null;

    const onScroll = () => {
      if (scrollTimeout !== null) {
        clearTimeout(scrollTimeout);
      }
        scrollTimeout = setTimeout(handleScrollStop, 150);
      
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      if (scrollTimeout !== null) {
        clearTimeout(scrollTimeout);
      }
    };
  }, []); 

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
      const sections = containerRef.current.querySelectorAll('div[id^="section"]');
      const image = containerRef.current.querySelector('img');
      image.style.transition = transitionDuration; 

      const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const newPosition = entry.target.id === 'section1' || entry.target.id === 'section3' || entry.target.id === 'section5' || entry.target.id === 'section7' ? '20%' : '80%';
                setSection('#'+ entry.target.id);
                setImageLeft(newPosition); 
                if (entry.target.id === 'section5') {
                  setRotateImage(true);
                  setTimeout(() => {
                    setImageChange(true);
                  }, 250);
                }else{
                  setRotateImage(false);
                  setTimeout(() => {
                    setImageChange(false);
                  }, 250);
                }
      
            }
        });
    };
    
    

      const observerOptions = {
          root: null,
          threshold: 0.5,
          rootMargin: "0px"
      };
      const observer = new IntersectionObserver(observerCallback, observerOptions);

      sections.forEach(section => {
          observer.observe(section);
      });

      return () => {
          sections.forEach(section => {
              observer.unobserve(section);
          });
      };
  }, []);

    return (
      <>
      <Helmet>
        <title>Lingotti Oro Kinegram: Massima Sicurezza | Careisgold</title>
        <meta name="description" content="Scopri i lingotti d'oro Kinegram proposti da Careisgold. Tecnologia avanzata per la massima sicurezza e autenticità del tuo investimento in oro" />
        <meta name="keywords" content="lingotti oro, Kinegram, sicurezza oro, autenticità oro, Careisgold" />
        <meta property="og:title" content="Lingotti Oro Kinegram: Massima Sicurezza | Careisgold" />
        <meta property="og:description" content="Scopri i lingotti d'oro Kinegram proposti da Careisgold. Tecnologia avanzata per la massima sicurezza e autenticità del tuo investimento in oro." />
        <meta property="og:image" content={goldbar} /> 
        <meta property="og:url" content="https://www.careisgold.it/kinegram/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/kinegram/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Lingotti Oro Kinegram: Massima Sicurezza | Careisgold",
          "url": "https://www.careisgold.it/kinegram/",
          "description": "Scopri i lingotti d'oro Kinegram proposti da Careisgold. Tecnologia avanzata per la massima sicurezza e autenticità del tuo investimento in oro.",
          "inLanguage": "it",
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "url": ${goldbar}
          },
          "publisher": {
            "@type": "Organization",
            "name": "Careisgold SpA",
            "logo": {
              "@type": "ImageObject",
              "url": ${logo}
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Via Monte Baldo 14/D",
              "addressLocality": "Villafranca di Verona",
              "addressRegion": "Veneto",
              "postalCode": "37069",
              "addressCountry": "IT"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+39 045 8213155",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/careisgoldspa",
              "https://www.instagram.com/careisgoldspa",
              "https://www.linkedin.com/company/careisgold-spa"
            ]
          }
        }
      `}
    </script>
      </Helmet>
      <Navbar />

        <div ref={containerRef}  className='z-40'                       
        >
            <img
              src={imageChange ? kinegram : goldbar}
              style={{
                width: '20%',
                position: 'fixed',
                top: '50%',
                left: imageLeft,
                transform: `translateY(-50%) translateX(-50%) ${rotateImage ? 'rotateY(180deg)' : ''}`,
                transition: `left ${transitionDuration} ease, transform 1s`,
              }}
              className='hidden md:block'
              alt='lingotto argor herause'
            />
            {width < 700 && 
            <div className='fade-lingotto'>
              <img
                  src={goldbar}
                  style={{
                      width: '50%'
                  }}
                  alt='lingotto argor herause'
              />
              </div>}
            <div id="section1" className='bg-black md:h-dvh text-slate-50 flex items-center justify-end'>
              <div className={`w-full lg:w-2/3 text-left lg:pe-56 lg:pl-0 p-4 mt-[25rem] lg:mt-0`}>
                <h4 className={`lg:text-6xl text-slate-50 font-semibold `} data-aos={width > 800 ? "fade-up" : "fade-in"} data-aos-duration="3000">Ogni lingotto è un mattone che costruisce il tuo futuro</h4>
                <div className={`text-md lg:text-lg mt-4 mb-10 text-slate-50`} data-aos="fade-in" data-aos-duration="3000">
                    <p className=' pr-4'>
                      Careisgold offre solo lingotti che rispecchiano la massima qualità disponibile sul mercato. Ogni prodotto
                      segue rigorosi standard di qualità e sicurezza, con lingotti di purezza 999,9‰, provenienti da raffinerie
                      inserite nella Good Delivery List e massimi sistemi anti-contraffazione.
                    </p>
                </div>
              </div>
            </div>
            <div id="section2" className='bg-black md:h-dvh text-slate-50 flex items-center justify-start'>
              <div className="w-full lg:w-2/3 text-left lg:pe-56 lg:pl-28 p-4 lg:mt-0">
              <h4 className={` lg:text-6xl text-slate-50 font-semibold`} data-aos={width > 800 ? "fade-up" : "fade-in"} data-aos-duration="3000">Careisgold è impegnata nel fornire solo il meglio ai propri clienti</h4>
              <div className={`text-md lg:text-lg mt-4 mb-10 text-slate-50`} data-aos="fade-in" data-aos-duration="3000">
                    <p className=''>
                      Per questo, collaboriamo con partner d’eccellenza per la produzione dei lingotti d’oro e per i servizi
                      destinai ai nostri clienti. Fiducia e sicurezza sono i capisaldi che guidano l’azienda nel proporre soluzioni
                      che soddisfino i più alti standard di qualità.
                    </p>
                </div>
              </div>
            </div>
            <div id="section3" className='bg-black md:h-dvh text-slate-50 flex items-center justify-end'>
              <div className="w-full lg:w-2/3 text-left lg:pe-56 p-4 lg:mt-0">
                <h4 className={`lg:text-6xl text-slate-50 font-semibold`} data-aos={width > 800 ? "fade-up" : "fade-in"} data-aos-duration="3000">I migliori lingotti d’oro puro certificati</h4>
                <div className={`text-md lg:text-lg mt-4 mb-10 text-slate-50`} data-aos="fade-in" data-aos-duration="3000">
                      <p className=''>
                        Careisgold ha scelto <b> Argor-Heraeus Italia SpA </b> come partner per offrire ai propri clienti i migliori lingotti
                        d’oro puro certificati. Rinomata a livello globale per la sua eccellenza nel settore della raffinazione,
                        Argor-Heraeus rappresenta l’élite nella produzione di lingotti d’oro puro fisico ed è garanzia di
                        tracciabilità e liquidabilità.
                      </p>
                  </div>
              </div>
            </div>
            <div id="section4" className='bg-black md:h-dvh text-slate-50 flex items-center justify-start'>
              <div className="w-full lg:w-2/3 text-left lg:pe-56 lg:pl-28 p-4 lg:mt-0">
              <h4 className={` lg:text-6xl text-slate-50 font-semibold`} data-aos={width > 800 ? "fade-up" : "fade-in"} data-aos-duration="3000">Filiera etica</h4>
                <div className={`text-md lg:text-lg mt-4 mb-10 text-slate-50`} data-aos="fade-in" data-aos-duration="3000">
                      <p className=''>
                        L’Oro di Careisgold viene estratto con tecniche non invasive, senza l’utilizzo di agenti chimici inquinanti
                        o esplosivi e con il massimo riguardo per l’ambiente. Vengono rispettate tutte le normative relative alla
                        sicurezza e al lavoro, per tutelare coloro che operano in condizioni rischiose ed evitare il fenomeno
                        dello sfruttamento minorile.
                      </p>
                  </div>
              </div>
            </div>
            <div id="section5" className='bg-black md:h-dvh text-slate-50 flex items-center justify-end'>
              <div className="w-full lg:w-2/3 text-left lg:pe-56 p-4 lg:mt-0">
              <h4 className={`lg:text-6xl text-slate-50 font-semibold`} data-aos={width > 800 ? "fade-up" : "fade-in"} data-aos-duration="3000">La sicurezza è tuo diritto e una nostra responsabilità</h4>
                <div className={`text-md lg:text-lg mt-4 mb-10 text-slate-50`} data-aos="fade-in" data-aos-duration="3000">
                      <p className=''>
                        I lingotti fino al peso di 100gr sono dotati di tecnologia <b>Kinegram®.</b> Tale sistema anti-contraffazione,
                        utilizzata anche per banconote e passaporti, garantisce la massima sicurezza e trasparenza contro frodi
                        e falsificazioni.
                      </p>
                  </div>
              </div>
            </div>
        </div>
        </>

    );
}

export default ImageFollow;
