import React, { useState, useEffect } from 'react';
import FullBWSection from '../../components/FullBWsection';
import logoNero from '../../assets/staticSection/1.webp';
import family from '../../assets/staticSection/family12.webp';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/lingotti/0082 - Still life Care is gold-Modifica.webp";
import sarto from "../../assets/sarto/sarto1_crop.webp"
import FullMinimalSection from '../../components/FullMinimalSection';
import Footer from '../../components/Footer';
import MinimalArrow from '../../components/MinimalArrow';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'
import { ScrollParallax } from 'react-just-parallax';



function Prodotti() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className="">
      <Helmet>
        <title>Prodotti e Servizi Oro | Careisgold SpA</title>
        <meta name="description" content="Esplora la gamma completa di prodotti e servizi oro di Careisgold. Lingotti certificati e soluzioni personalizzate per ogni esigenza. Lingotti accessibili a tutti, prima di tutti." />
        <meta name="keywords" content="prodotti, servizi, oro, lingotti oro, investimenti, oro fisico da investimento, Careisgold" />
        <meta property="og:title" content="Prodotti e Servizi Oro | Careisgold SpA" />
        <meta property="og:description" content="Esplora la gamma completa di prodotti e servizi oro di Careisgold. Lingotti certificati e soluzioni personalizzate per ogni esigenza. Lingotti accessibili a tutti, prima di tutti." />
        <meta property="og:image" content={headerImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/prodotti-e-servizi/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/prodotti-e-servizi/" />
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Prodotti e Servizi Oro | Careisgold SpA",
            "url": "https://www.careisgold.it/prodotti-e-servizi/",
            "description": "Esplora la gamma completa di prodotti e servizi oro di Careisgold. Lingotti certificati e soluzioni personalizzate per ogni esigenza. Lingotti accessibili a tutti, prima di tutti.",
            "inLanguage": "it",
            "primaryImageOfPage": {
              "@type": "ImageObject",
              "url": ${headerImage}
            },
            "publisher": {
              "@type": "Organization",
              "name": "Careisgold SpA",
              "logo": {
                "@type": "ImageObject",
                "url": ${logo}
              },
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Via Monte Baldo 14/D",
                "addressLocality": "Villafranca di Verona",
                "addressRegion": "Veneto",
                "postalCode": "37069",
                "addressCountry": "IT"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+39 045 8213155",
                "contactType": "Customer Service"
              },
              "sameAs": [
                "https://www.facebook.com/careisgoldspa",
                "https://www.instagram.com/careisgoldspa",
                "https://www.linkedin.com/company/careisgold-spa"
              ]
            }
          }
        `}
    </script>
      </Helmet>
      <Navbar />
      <MinimalArrow backgroundImage={headerImage} title='Prodotti e servizi <br /> per ogni esigenza' Subtitle='Careisgold offre un&#39;ampia gamma di prodotti adattabili ad ogni necessità e servizi a 360 gradi per una gestione ottimale del proprio piano in oro fisico da investimento.'/>

      {/* PRIMO BLOCCO - PRODOTTI E LINGOTTI */}

      {width > 768 ?  <FullBWSection 
        background='bg-black'
        text='text-neutral-50'
        buttonClass1='w-full xl:w-2/3'
        buttonClass2='mt-5 w-full xl:w-2/3'
        darkButton={true}
        href2='/lingotti'
        href='/prodotti'
        title='Creiamo soluzioni su misura per te'
        description={`<p class=''>Come un sarto crea abiti su misura, anche noi adattiamo i nostri prodotti alle esigenze e ai
            desideri di ogni singolo cliente. Grazie alla sua esperienza pluriennale, Careisgold è in grado
            di offrire soluzioni affidabili, flessibili e innovative per la creazione della tua riserva aurea
            personale.</p>`}
        image={sarto}
        secondButton={true}
        opacity='opacity-50'
        translate='lg:translate-x-[20%]'
        firstButtonText='Prodotti'
        secondButtonText='Lingotti'
      /> :
      <FullMinimalSection 
        headerImage={sarto}
        logoTransp=''
        title='Creiamo soluzioni su misura per te'
        description={`Come un sarto crea abiti su misura, anche noi adattiamo i nostri prodotti alle esigenze e ai
            desideri di ogni singolo cliente. Grazie alla sua esperienza pluriennale, Careisgold è in grado
            di offrire soluzioni affidabili, flessibili e innovative per la creazione della tua riserva aurea
            personale.`}
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Prodotti'
        secondButtonText='Lingotti'
        secondButton={true}
        darkButton={true}
        download={false}
        buttonClass='w-full xl:w-2/3'
        buttonClass2='mt-5 w-full xl:w-2/3'
        href='/prodotti'
        href2='/lingotti'
      />
      }


      {/* SECCONDO BLOCCO - SERVIZI */}

         <div className='relative z-20 h-lvh overflow-hidden'>
          <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
              <img src={logoNero} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" style={{ transform: 'scale(1.2)' }} />
          </ScrollParallax>
          <div className="absolute top-0 left-0 lg:bg-black lg:bg-opacity-50 bg-white w-full h-full flex justify-center items-center">
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.08">
                  <img  src=''      
                        data-aos="fade-in" data-aos-duration="3000"
                        className={`absolute left-[-40vh] top-[-30vh] w-[110vh]object-contain`}
                        alt='logo careisgold'
                     />
              </ScrollParallax>
              {/* Text container centered and slightly moved to the right */}
              <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                <div className='lg:w-[50%]'>
                  <h2 className="lg:text-5xl text-4xl lg:text-white font-semibold drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                    Servizi esclusivi sempre a tua disposizione
                  </h2>
                  <p className="text-xl mt-4 mb-10 lg:text-white drop-shadow-lg text-balance" data-aos="fade-up" data-aos-duration="1000">
                    Tutte le soluzioni Careisgold sono affiancate a servizi di primo livello per garantire un supporto completo e
                    personalizzato ad ogni cliente e soddisfare ogni tipo di esigenza legata al tuo piano in Oro.
                  </p>
                  <a href='/servizi' >
                    <button className={`${width > 768 ? 'progress-button-light border-slate-50 text-slate-50' : 'border-black text-black progress-button-dark'}  px-8 py-2 uppercase border text-center transition duration-300 ease-in-out w-full lg:w-1/2`}>
                      SCOPRI TUTTI I SERVIZI CAREISGOLD
                    </button>
                  </a>
                </div>
              </div>
          </div>
      </div>

      {/* TERZO BLOCCO - CONSULENZA E SUPPORTO */}
      
      {width > 768 ? <FullBWSection 
        background='bg-black'
        text='text-slate-50'
        buttonClass1='w-full xl:w-2/3'
        buttonClass2='hidden'
        darkButton={true}
        href2=''
        href='/consulenza-supporto'
        title='Pensiamo a tutto noi'
        description={`<p class=''>Il tuo Oro è prezioso, ma non pesante. Careisgold solleva il cliente da ogni
          impellenza burocratica, occupandosi di ogni dettaglio amministrativo e normativo. Inoltre,
          hai il tuo consulente sempre a disposizione e strumenti digitali dedicati per monitorare il
          tuo piano 24 ore su 24.</p>`}
        image={family}
        opacity='opacity-50'
        translate='lg:translate-x-[20%]'
        firstButtonText='Scopri di più'
      /> :
      <FullMinimalSection 
        headerImage={family}
        logoTransp=''
        title='Pensiamo a tutto noi'
        description={`Il tuo Oro è prezioso, ma non pesante. Careisgold solleva il cliente da ogni
          impellenza burocratica, occupandosi di ogni dettaglio amministrativo e normativo. Inoltre,
          hai il tuo consulente sempre a disposizione e strumenti digitali dedicati per monitorare il
          tuo piano 24 ore su 24.`}
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Scopri di più'
        darkButton={true}
        download={false}
        buttonClass='w-full xl:w-2/3'
        buttonClass2='hidden'
        href='/consulenza-supporto'
      />
       }


      <Footer banner={true}/>
    </div>
  )
}

export default Prodotti