import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const IncrementNum = ({ start, end, duration, startSign, endSign }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 1.0,   // Fully visible
  });

  return (
    <div ref={ref} className="text-4xl font-bold">
        {startSign}
        {inView && <CountUp start={start} end={end} duration={duration} separator=''/>}
        {endSign}
    </div>
  );
};

export default IncrementNum;
