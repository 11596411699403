import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/lingotti/header-servizi-page.webp";
import lingotto from "../../assets/lingotti/bg-servizi-goldbar.webp";
import Check from "../../assets/lottie/Check.json";
import Footer from '../../components/Footer';
import Lottie from 'lottie-react';
import 'aos/dist/aos.css';
import MinimalArrow from '../../components/MinimalArrow';
import BackgroundImageFade from '../../components/BackgroundImageFade';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'



export default function Servizi() {
  const listItemRefs = useRef([]);
  const lottieRefs = useRef([]);
  const [appearedItems, setAppearedItems] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const servizi = [
    {
        titolo: "CONSULENZA A DOMICILIO",
        testo: "La consulenza e la proposizione dell’offerta vengono effettuate presso il domicilio del cliente e conformemente alle sue necessità."
    },
    {
        titolo: "STOCCAGGIO E CUSTODIA IN CAVEAU",
        testo: "Grazie alla collaborazione con Battistolli Spa, leader in Italia per servizi di sicurezza, Careisgold mette al sicuro l’Oro dei propri clienti offrendo un servizio di custodia in caveaux di massima sicurezza. Ciascun lingotto depositato è protetto e identificato da un codice seriale e un codice sigillo abbinati unicamente al proprietario."
    },
    {
        titolo: "COPERTURA ASSICURATIVA TOTALE",
        testo: "I lingotti depositati nei caveaux sono assicurati al 100% del loro valore tramite polizze di protezione dei più grandi colossi assicurativi mondiali."
    },
    {
        titolo: "CONSEGNA A DOMICILIO",
        testo: "Nel caso non si voglia tenere i propri lingotti in caveau, Careisgold offre la possibilità di riceverli direttamente a casa tramite un servizio di trasporto in totale sicurezza e discrezione."
    },
    {
        titolo: "APP E SERVIZI DIGITALI",
        testo: "Tutti i clienti Careisgold hanno a disposizione un portale online e un'App da cui poter controllare lo stato del proprio piano, i versamenti effettuati, le fatture emesse, il prezzo dell’Oro aggiornato e i verbali di controllo dell’Oro depositato nei caveaux."
    },
    {
        titolo: "RIACQUISTO DELL’ORO",
        testo: "In ogni momento il cliente ha facoltà di rivendere a Careisgold i lingotti depositati nei caveaux, liquidando il proprio Oro in soli 15/20 lavorativi e ad una quotazione estremamente conveniente."
    },
    {
        titolo: "SERVIZIO CLIENTI",
        testo: "Careisgold mette a disposizione ai propri clienti un servizio di assistenza altamente preparato per gestire qualsiasi tipo di necessità e dare soluzione a qualsiasi eventuale richiesta."
    },
  ]

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = listItemRefs.current.indexOf(entry.target);
            if (!appearedItems.includes(index)) {
              setAppearedItems((prev) => [...prev, index]);
            }
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    listItemRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      listItemRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [appearedItems]);

  const content = <>
    <div className='relative z-10 lg:p-10 pt-44 pb-20 text-white bg-gradient-custom w-full px-5'>
      <div className='lg:pr-8 flex justify-center'>
        <ul className=' lg:w-1/2 w-full'>
          {servizi.map((item, index) => (
            <li 
              key={index} 
              className='mt-14' 
              data-aos="fade-up" 
              ref={(el) => (listItemRefs.current[index] = el)}
            >
              <div className={`flex ${width < 1280 ? 'flex-col items-center' : '' }`}>
                <span className='mr-4 xl:w-[5%] lg:w-[10%] md:w-[5%] sm:w-[10%] w-[20%] mb-8 ml-2'>
                  <Lottie 
                    animationData={Check} 
                    loop={!appearedItems.includes(index)} 
                    lottieRef={(el) => (lottieRefs.current[index] = el)}
                  />
                </span>
                <span className='w-[90%] text-center xl:text-start'>
                  <h4 className='text-xl text-animate-gold mb-4'><b>{item.titolo}</b></h4>
                  <p>{item.testo}</p>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </>

  return (
    <>
    <Helmet>
        <title>Servizi Professionali Oro | Careisgold SpA</title>
        <meta name="description" content="Scopri i servizi professionali di Careisgold per investimenti in oro. Consulenza esperta, custodia sicura e soluzioni su misura per i tuoi obiettivi finanziari." />
        <meta name="keywords" content="servizi oro, consulenza investimenti, custodia oro, oro fisico da investimento, lingotti oro, Careisgold" />
        <meta property="og:title" content="Servizi Professionali Oro | Careisgold SpA" />
        <meta property="og:description" content="Scopri i servizi professionali di Careisgold per investimenti in oro. Consulenza esperta, custodia sicura e soluzioni su misura per i tuoi obiettivi finanziari." />
        <meta property="og:image" content={headerImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/servizi/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/servizi/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Servizi Professionali Oro | Careisgold SpA",
              "url": "https://www.careisgold.it/servizi/",
              "description": "Scopri i servizi professionali di Careisgold per investimenti in oro. Consulenza esperta, custodia sicura e soluzioni su misura per i tuoi obiettivi finanziari.",
              "inLanguage": "it",
              "primaryImageOfPage": {
                "@type": "ImageObject",
                "url": ${headerImage}
              },
              "publisher": {
                "@type": "Organization",
                "name": "Careisgold SpA",
                "logo": {
                  "@type": "ImageObject",
                  "url": ${logo}
                },
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Via Monte Baldo 14/D",
                  "addressLocality": "Villafranca di Verona",
                  "addressRegion": "Veneto",
                  "postalCode": "37069",
                  "addressCountry": "IT"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+39 045 8213155",
                  "contactType": "Customer Service"
                },
                "sameAs": [
                  "https://www.facebook.com/careisgoldspa",
                  "https://www.instagram.com/careisgoldspa",
                  "https://www.linkedin.com/company/careisgold-spa"
                ]
              }
            }
          `}
        </script>
      </Helmet>
      <Navbar />
      <div>
      <MinimalArrow backgroundImage={headerImage} title='Abbiamo pensato a tutto, nei minimi dettagli' Subtitle={`<p class=''>Mettiamo al primo posto la cura delle persone. Careisgold si distingue per l’ampia offerta di servizi di
            altissimo livello, progettati per soddisfare ogni necessità del cliente e liberarlo da qualsiasi tipo di
            preoccupazione e di complessità burocratica, combinando sicurezza, assistenza e trasparenza.</p>`}/>
      <BackgroundImageFade src={lingotto} content={content} ptClass='pt-10'/>
       
      </div>
      <Footer />
    </>
  );
}
