import React from 'react';
import { IoMdArrowDropupCircle, IoMdArrowDropdownCircle, IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useFixing } from '../context/FixingGoldContext';

function FixingGold() {
    const {
        isVisible,
        setIsVisible,
        showFixing,
        prezzo,
        variazione,
        bullBear,
        loading,
        error,
        handleFixingShow
    } = useFixing();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

  
    return (
        <>
            {showFixing && 
                <button onClick={handleFixingShow} className={`fixed bottom-4 right-0 ${isVisible ? 'text-start' : 'text-end'} pe-4 py-2 z-important glassmorphism flex flex-row items-center transition-transform duration-300 ${isVisible ? 'translate-x-0' : 'translate-x-[11.5rem]'}`} aria-expanded={isVisible}>
                    {isVisible ? <IoIosArrowForward className='text-white mx-4'/> : <IoIosArrowBack className='text-white mx-4'/> }
                    <div>
                        <p className='text-white font-semibold uppercase'>fixing del giorno</p>
                        <div className='flex flex-row items-center'>
                            <span className={`text-white font-semibold`}>€ {prezzo}</span> &nbsp; 
                            <span className={`font-bold ${bullBear ? 'text-green-600' : 'text-rose-600'}`}>{bullBear ? variazione + '%' : variazione + '%'}</span> &nbsp;
                            {bullBear ? 
                                <IoMdArrowDropupCircle className='text-green-600'/> : 
                                <IoMdArrowDropdownCircle className='text-rose-600'/>
                            }
                        </div>
                    </div>
                </button>  
            }
        </>
    );
}

export default FixingGold;
