import React, {useState, useEffect} from 'react';
import Static from '../../components/Static1';
import acqua from '../../assets/lingotti/0005 - Still life Care is gold-Modifica.webp';
import oroP from '../../assets/lingotti/0060 - Still life Care is gold-Modifica.webp';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/staticSection/azienda_sede.webp";
import Footer from "../../components/Footer";
import image1 from '../../assets/codiceEtico/pexels-julien-riedel-907961520-20319546.webp'
import FullMinimalSection from '../../components/FullMinimalSection';
import codiceEtico from '../../assets/pdf/codice-etico.pdf'
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'
import MinimalSection from '../../components/MinimalSection';
import divisionePrime from '../../assets/divisionePrime/1.jpg';
import divisionePrivate from '../../assets/divisionePrime/4.jpg';


function Azienda() {
  const [width, setWidth] = useState(window.innerWidth);
  var ogImage = `https://www.careisgold.it${headerImage}`;
  
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>Careisgold: Leader nel mercato del risparmio in Oro</title>
        <meta name="description" content="Scopri Careisgold, azienda leader in Italia nel mercato del risparmio in oro. Offriamo soluzioni sicure e innovative per privati e aziende con trasparenza e professionalità." />
        <meta name="keywords" content="Careisgold, investimenti oro, azienda oro Italia, lingotti oro, sicurezza investimenti, trasparenza finanziaria" />
        <meta property="og:title" content="Careisgold: Leader nel mercato del risparmio in Oro" />
        <meta property="og:description" content="Scopri Careisgold, azienda leader in Italia nel mercato del risparmio in oro. Offriamo soluzioni sicure e innovative per privati e aziende con trasparenza e professionalità." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/azienda/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/azienda/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Careisgold SpA",
          "url": "https://www.careisgold.it/azienda/",
          "logo": ${logo},
          "description": "Scopri Careisgold, azienda leader in Italia nel mercato del risparmio in oro. Offriamo soluzioni sicure e innovative per privati e aziende con trasparenza e professionalità.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Monte Baldo 14/D",
            "addressLocality": "Villafranca di Verona",
            "addressRegion": "Veneto",
            "postalCode": "37069",
            "addressCountry": "IT"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+39 045 8213155",
            "contactType": "Customer Service"
          },
          "sameAs": [
            "https://www.facebook.com/careisgoldspa",
            "https://www.instagram.com/careisgoldspa",
            "https://www.linkedin.com/company/careisgold-spa"
          ]
        }
      `}
    </script>
      </Helmet>
      <Navbar />
      <Static 
        headerImage={headerImage}
        logoTransp=''
        title='Una realtà costruita sulla cura delle persone'
        description={` Il nostro motto è: l’Oro accessibile a tutti, prima di tutti. Grazie a Careisgold, infatti, chiunque può accedere
          al Bene Rifugio per eccellenza con semplicità e sicurezza.`}
        href='/perche-scegliere-careisgold'
        logoPosition='left-[130vh] top-[30vh] w-[110vh]'
        buttonText='Perchè sceglierci'
      />

      {/* PRIMO BLOCCO STAFF/STORIA */}
      
          <MinimalSection
            orientation='right' 
            background='bg-black' 
            title="La nostra storia, la nostra crescita"
            titleClass='text-4xl text-white'
            description={` Careisgold è una società per azioni del Gruppo Care Holding SpA. Care Holding SpA, attiva sul mercato da
              oltre 15 anni, si dedica costantemente alla realizzazione di progetti su misura per il benessere delle persone
              e del loro futuro.`}
            descriptionClass='text-white'
            imageUrl={acqua}
            imgOpacity="opacity-100"
            strength='0.14'
            lerp='0.30'
            buttonClass1='w-full '
            buttonClass2='mt-5 w-full '
            darkButton={true}
            secondButton={true}
            href='/gruppo'
            href2='/staff'
            firstButtonText='Storia del gruppo'
            secondButtonText='il nostro team'
          /> 
        

      {/* SECONDO BLOCCO DIVISIONE PRIME */}
      {width > 640 ? <MinimalSection
        orientation='left' 
        background='bg-neutral-50' 
        title="Divisione Prime"
        titleClass='text-4xl text-black'
        description={`Un nuovo modo di fare impresa, una nuova figura indipendente
        in un mercato ricco di opportunità. Careisgold mette a disposizione del
        pubblico una rete di collaboratori adibiti alla promozione dell’Oro da
        investimento su tutto il territorio nazionale.`}
        descriptionClass='text-black'
        imageUrl={divisionePrime}
        imgOpacity="opacity-100"
        strength='0.14'
        lerp='0.30'
        buttonClass1='w-full '
        secondButton={false}
        buttonClass2='hidden'
        darkButton={false}
        href='https://4changeyourbusiness.it/'
        firstButtonText='Scopri di più'
      />  : 
      <FullMinimalSection 
        headerImage={divisionePrime}
        logoTransp=''
        title='Divisione Prime'
        description={`Un nuovo modo di fare impresa, una nuova figura indipendente
        in un mercato ricco di opportunità. Careisgold mette a disposizione del
        pubblico una rete di collaboratori adibiti alla promozione dell’Oro da
        investimento su tutto il territorio nazionale.`}
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Scopri di più'
        secondButtonText='il nostro team'
        darkButton={true}
        download={false}
        buttonClass='w-full'
        buttonClass2='hidden'
        href='https://4changeyourbusiness.it/'
      />}

      {/* TERZO BLOCCO DIVISONE PRIVATE */}
      
      <MinimalSection
        orientation='right' 
        background='bg-black' 
        title="Divisione Private"
        titleClass='text-4xl text-white'
        description={`La Divisione Private di Careisgold è orientata ai
        professionisti del settore, promotori finanziari, agenti, banche, e broker che hanno
        scelto di aggiungere un nuovo, fondamentale asset per i loro clienti, puntando sulla
        sicurezza e la solidità dell’Oro.`}
        descriptionClass='text-white'
        imageUrl={divisionePrivate}
        imgOpacity="opacity-100"
        strength='0.14'
        lerp='0.30'
        buttonClass1='w-full '
        buttonClass2='hidden'
        darkButton={true}
        href='/divisione-private-consulenti'
        firstButtonText='Scopri di più'
      /> 

      {/* QUARTO BLOCCO BLOCCO ORO PRESTIGE */}
      
      {width > 640 ? <MinimalSection
        orientation='left' 
        background='bg-neutral-50' 
        title="Oro Prestige, lingotti 24k in gioielleria"
        titleClass='text-4xl text-black'
        description={` Da oggi, grazie a Careisgold, puoi diventare proprietario di oro da investimento nella tua gioielleria di fiducia. Chiedi di noi al tuo gioielliere.`}
        descriptionClass='text-black'
        imageUrl={oroP}
        imgOpacity="opacity-100"
        strength='0.14'
        lerp='0.30'
        buttonClass1='w-full '
        buttonClass2='hidden'
        darkButton={false}
        href='/oro-prestige'
        firstButtonText='Oro Prestige'
      /> : 
      <FullMinimalSection 
        headerImage={oroP}
        logoTransp=''
        title='Oro Prestige, lingotti 24k in gioielleria'
        description={`Da oggi, grazie a Careisgold, puoi diventare proprietario di oro da investimento nella tua gioielleria di fiducia. Chiedi di noi al tuo gioielliere.`}
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Oro Prestige'
        secondButton={false}
        secondButtonText='il nostro team'
        darkButton={true}
        download={false}
        buttonClass='w-full '
        buttonClass2='hidden'
        href='/oro-prestige'
      />}

      {/* QUINTO BLOCCO CODICE ETICO  */}
      <FullMinimalSection 
        headerImage={image1}
        logoTransp=''
        title='Il nostro Codice Etico'
        description={`Il codice etico di Care Holding SpA mira a ridurre le incertezze, orientare i comportamenti e rispondere a
          tutti coloro che hanno rapporti, a vario titolo, con la nostra azienda. È fondamentale confermare la nostra
          visione etica e chiarire principi, valori e responsabilità comuni. Questi principi guidano le nostre relazioni
          con mercato, consumatori, comunità, dipendenti e parti interessate.`}
        href={codiceEtico}
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='Leggi di più'
        secondButton={false}
        secondButtonText=''
        darkButton={true}
        download={true}
        buttonClass='hidden w-full lg:w-1/2'
        buttonClass2='hidden'
        href2=''
      />
      <Footer banner={true}/>
    </div>
  )
}

export default Azienda