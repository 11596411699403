export default function ImageStickyContent({src, content}) {
    return<>
    <div className="relative bg-black">
        <div className="flex">
          <div className="relative w-1/4">
            <div 
              className="sticky top-0 w-full h-[100vh] hidden lg:block"
              data-aos="fade-in" data-aos-duration="3000"
              >
                <img src={src} className='h-full object-cover' alt="" data-aos="fade-in" data-aos-duration="3000"/>
              <div className=" top-0 right-0 w-full h-full bg-gradient-custom-image"></div>
            </div>
          </div>
          <div className="relative z-10 lg:pt-44 text-white bg-black lg:w-[75%] lg:pl-60">
            {content}
          </div>
        </div>
      </div>
    </>
}