import React, { useEffect } from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SmoothScroll from './components/SmoothScroll';
import Scrollbar from './components/ScrollBar';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { hydrate } from "react-dom";
import { createRoot } from 'react-dom/client';
import { FixingProvider } from './context/FixingGoldContext';
import TagManager from 'react-gtm-module';


const tagManagerArgs = {
    gtmId: 'GTM-5VBL7N7'
}

const disableGTM = false;

const GtmInit = () =>{
  useEffect(() => {
    if(window.location.hostname !== 'localhost' && !disableGTM){
      TagManager.initialize(tagManagerArgs);
    }
  },[]);
}

AOS.init();


const rootElement = document.getElementById("root");
const root = createRoot(rootElement); 

const isDesktop = () => {
  return window.innerWidth >= 768; // Assuming md breakpoint is 768px
};

if (rootElement.hasChildNodes()) {
  hydrate(  
  <React.StrictMode>
    <FixingProvider>
      {isDesktop() &&
      <>
        <Scrollbar/>
        <SmoothScroll/>
      </>}
      <App />
      <GtmInit/>
    </FixingProvider>
  </React.StrictMode>, rootElement);
} else {
  root.render(  
  <React.StrictMode>
    <FixingProvider>
      {isDesktop() && 
      <>
        <Scrollbar/>
        <SmoothScroll/>
      </>}
      <App />
      <GtmInit/>
    </FixingProvider>
  </React.StrictMode>);
}

reportWebVitals();
