import React, { useRef } from 'react';
import { ScrollParallax } from 'react-just-parallax';
import DefaultButton from '../components/DefaultButton';
import headerImage from '../assets/lingotti/0082 - Still life Care is gold-Modifica.webp'
function NotFound404() {

  const createMarkup = (htmlContent) => {
    return {__html: htmlContent};
  };

  const nextSectionRef = useRef(null);

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <>
      <div className='relative z-20 h-lvh overflow-hidden'>
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                <img src={headerImage} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" />
              </ScrollParallax>
          <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full flex justify-center items-center">
              <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                <div className='lg:w-full'>
                  <h2 className="lg:text-5xl text-4xl text-white font-semibold text-center mb-6" data-aos="fade-up" data-aos-duration="1000">404 <br /> PAGINA NON TROVATA</h2>
                   <DefaultButton text='Torna alla home' link='/' dark={true} buttonClass='w-full' />
                </div>
              </div>
          </div>
      </div>
      <div ref={nextSectionRef}>
      </div>
    </>
  );
}

export default NotFound404;
