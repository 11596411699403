import React, { useRef } from 'react';
import FullBWSection from '../../components/FullBWsection';
import logoNero from '../../assets/staticSection/app.webp';
import contattoUmano from '../../assets/consulenzaSupporto/1crop.webp';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/staticSection/family6.webp";
import FullMinimalSection from '../../components/FullMinimalSection';
import Footer from '../../components/Footer';
import MinimalArrow from '../../components/MinimalArrow';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'
import appAndroid from '../../assets/icons/google-play.svg'
import appApple from '../../assets/icons/app-store.svg'



function ConsulenzaSupporto() {
  
  return (  
    <div className="">
      <Helmet>
        <title>Careisgold SpA: prodotti e servizi</title>
        <meta name="description" content="Scopri i prodotti innovativi e i servizi personalizzati di Careisgold per investire in oro in modo professionale." />
        <meta name="keywords" content="careisgold, prodotti, servizi, investimento in oro, consulenza, deposito, servizio clienti" />
        <meta property="og:title" content="Careisgold SpA: prodotti e servizi" />
        <meta property="og:description" content="Scopri i prodotti innovativi e i servizi personalizzati di Careisgold per investire in oro in modo professionale." />
        <meta property="og:image" content={headerImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/prodotti-e-servizi" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/prodotti-e-servizi" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Careisgold SpA: prodotti e servizi",
          "url": "https://www.careisgold.it/prodotti-e-servizi",
          "description": "Scopri i prodotti innovativi e i servizi personalizzati di Careisgold per investire in oro in modo professionale.",
          "inLanguage": "it",
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "url": ${headerImage}
          },
          "publisher": {
            "@type": "Organization",
            "name": "Careisgold SpA",
            "logo": {
              "@type": "ImageObject",
              "url": ${logo}
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Via Monte Baldo 14/D",
              "addressLocality": "Villafranca di Verona",
              "addressRegion": "Veneto",
              "postalCode": "37069",
              "addressCountry": "IT"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+39 045 8213155",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/careisgoldspa",
              "https://www.instagram.com/careisgoldspa",
              "https://www.linkedin.com/company/careisgold-spa"
            ]
          }
        }
      `}
    </script>
      </Helmet>
      <Navbar />
      <MinimalArrow backgroundImage={headerImage} title='Ti offriamo la libertà di non pensarci' Subtitle='Careisgold si occupa di ogni aspetto amministrativo e burocratico
            relativo al tuo piano in Oro. Vogliamo liberarti da ogni
            preoccupazione, così puoi dedicarti serenamente alle tue
            priorità. A tutto il resto ci pensiamo noi!'/>
      <FullBWSection 
        background='bg-black'
        text='text-neutral-50'
        buttonClass1='w-full xl:w-2/3'
        buttonClass2='hidden'
        darkButton={true}
        href2='/lingotti'
        href='/contatti'
        title='Per noi il contatto umano è fondamentale'
        description={`<p class=''>Non vendiamo online perché ci piace seguirti ancora come una volta. Con Careisgold avrai a disposizione un incaricato sempre
            pronto ad aiutarti in ogni fase del nostro percorso insieme. Inoltre, il nostro servizio clienti è sempre pronto ad offrirti supporto e assistenza.</p>`}
        image={contattoUmano}
        opacity='opacity-50'
        translate='lg:translate-x-[20%]'
        firstButtonText='Contattaci'
        secondButtonText='Lingotti'
      />
      <FullMinimalSection 
        headerImage={logoNero}
        logoTransp=''
        title='Porta sempre in tasca il tuo caveau'
        description={`Con Careisgold hai sempre tutto sotto controllo. Abbiamo creato una app e un portale online per monitorare il tuo piano in modo
                semplice e intuitivo, ovunque tu sia e in qualsiasi momento.`}
        href='/'
        logoPosition='left-[-40vh] top-[-30vh] w-[110vh]'
        buttonText='SCOPRI TUTTI I SERVIZI CAREISGOLD'
        darkButton={true}
        buttonClass='hidden'
        androidIcon={appAndroid}
        hrefAndroid='https://play.google.com/store/apps/details?id=it.areaclienti.careisgold&pli=1'
        appleIcon={appApple}
        hrefApple='https://apps.apple.com/it/app/careisgold-clienti/id6472647255'
        downloadApp={true}
        buttonClass2='hidden'
      />
      <Footer/>
    </div>
  )
}

export default ConsulenzaSupporto