import React, { useRef } from 'react'
import { ScrollParallax } from 'react-just-parallax';
import { SlArrowDown } from 'react-icons/sl';


function MinimalArrow({backgroundImage, title, Subtitle}) {

    const nextSectionRef = useRef(null);

    // Function to scroll to the next section
    const scrollToNextSection = () => {
      nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const createMarkup = (htmlContent) => {
        return {__html: htmlContent};
    };


  return (
    <div>
        <div className='relative z-20 h-lvh overflow-hidden bg-black'>
            <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                <img src={backgroundImage} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" />
            </ScrollParallax>
          <div className="absolute top-0 left-0 bg-black bg-opacity-30 md:bg-opacity-30 w-full h-full flex justify-center">
            <div className="flex justify-center items-center text-start ps-15 mx-5 md:mr-[20%] xl:ml-[50%]">
                <div className='lg:w-[100%]'>
                  <h2 className="lg:text-5xl text-4xl text-white drop-shadow-2xl font-semibold text-balance" data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(title)}></h2>
                  <p className="text-xl mt-4 mb-10 text-white drop-shadow-2xl text-balance" data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(Subtitle)}></p>
                </div>
            </div>          
              <SlArrowDown 
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="1000"
                  onClick={scrollToNextSection} 
                  className='arrow cursor-pointer block absolute transform -translate-x-1/2 text-slate-50 text-3xl md:text-5xl' />
          </div>
      </div>
      <div ref={nextSectionRef}>
      </div>
    </div>
  )
}

export default MinimalArrow