import React from 'react';
import { ScrollParallax } from 'react-just-parallax';
import { Link } from 'react-router-dom';
import DefaultButton from './DefaultButton';

function FullBWSection({background, text, buttonClass1, buttonClass2, href, href2, title, description, image, translate, opacity, darkButton, firstButtonText, secondButton, secondButtonText}) {

    const createMarkup = (htmlContent) => {
        return {__html: htmlContent};
    };

    return (
        <>
            <div className={`relative z-20 h-lvh overflow-hidden ${background} flex justify-center items-center text-start`}>
                <div className='hidden md:block'>
                    <div className={`w-full h-full grid grid-cols-1 md:grid-cols-2`}>
                        <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                            <img src={image}
                                className={`absolute left-0 top-[-40vh] h-[200vh] w-full md:w-[200vh] object-contain ${opacity}`}
                                alt='logo careisgold'
                                style={{ transform: 'scale(1.2)' }}
                            />
                        </ScrollParallax>
                        <div className={`flex justify-center items-center ps-4 md:ps-32 lg:ps-32 xl:ps-48`}>
                            <div className={`flex flex-col mx-auto ${translate}`}>
                                <h2 className={`text-5xl ${text} font-semibold text-balance`} data-aos="fade-up" data-aos-duration="1000">{title}</h2>
                                <div className={`text-xl mt-4 mb-10 ${text} text-balance`} data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(description)}></div>
                                <DefaultButton text={firstButtonText || 'Scopri di più'} link={href} dark={darkButton} buttonClass={buttonClass1} />
                                {secondButton && <DefaultButton text={secondButtonText || 'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`block md:hidden flex justify-center items-center px-4 md:ps-32 lg:ps-32 xl:ps-48`}>
                    <div className={`text-start mx-auto ${translate}`}>
                        <h2 className={`text-5xl ${text} font-semibold text-balance`} data-aos="fade-up" data-aos-duration="1000">{title}</h2>
                        <div className={`text-xl mt-4 mb-10 ${text} text-balance`} data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(description)}></div>
                        <DefaultButton text={firstButtonText || 'Scopri di più'} link={href} dark={darkButton} buttonClass={buttonClass1} />
                        {secondButton && <DefaultButton text={secondButtonText ||'Scopri di più'} link={href2} dark={darkButton} buttonClass={buttonClass2} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FullBWSection;
