import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate  } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Home/HomePage';
import Azienda from './pages/Azienda/Azienda';
import Notizie from './pages/Notizie/Notizie';
import Prodotti from './pages/Prodotti/Prodotti';
import ConsulenzaSupporto from './pages/Prodotti/ConsulenzaSupporto.jsx';
import Lingotti from './pages/Prodotti/Lingotti';
import CollaboraConNoi from './pages/CollaboraConNoi/CollaboraConNoi';
import Faq from './pages/Faq/Faq';
import Contatti from './pages/Contatti/Contatti';
import Approfondimenti from './pages/Notizie/ApprofondimentiSingle';
import RassegnaStampa from './pages/Notizie/RassegnaStampaSingle';
import OroPrestige from './pages/OroPrestige/OroPrestige';
import axios from "axios";
import Staff from './pages/Azienda/Staff';
import History from './pages/Azienda/History';
import AreaRiservata from './pages/AreaRiservata/AreaRiservata';
import ScopriProdotti from './pages/Prodotti/ScopriProdotti';
import AziendaServizi2 from './pages/Azienda/Servizi2.jsx'
import PercheOro from './pages/Home/PercheOro.jsx';
import NotFound404 from './pages/NotFound404.jsx';
import Servizi from './pages/Prodotti/Servizi.jsx';
import DivisionePrivate from './pages/DivisionePrivate/DivisionePrivate.jsx'
import SingleGioielleria from './pages/OroPrestige/singleGioielleria/SingleGioielleria.jsx';
import Lottie from "lottie-react";
import loading from "./assets/lottie/loading.json";
import Recensioni from './pages/Recensioni/Recensioni.jsx';


function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);  // This effect will run every time the location changes

  return null; // This component does not render anything
}


function App() {

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://newgest.careholding.it/blog_ws.axd/?type=GetPosts`,{
              port: 8800
            });
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  function formatToUrlFriendly(text) {
    const cleanedText = text
      .toLowerCase() // Convert to lowercase
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/[àáâãäå]/g, 'a') // Replace "à", "á", "â", "ã", "ä", "å" with "a"
      .replace(/[èéêë]/g, 'e') // Replace "è", "é", "ê", "ë" with "e"
      .replace(/[ìíîï]/g, 'i') // Replace "ì", "í", "î", "ï" with "i"
      .replace(/[òóôõö]/g, 'o') // Replace "ò", "ó", "ô", "õ", "ö" with "o"
      .replace(/[ùúûü]/g, 'u') // Replace "ù", "ú", "û", "ü" with "u"
      .replace(/[^a-z0-9-_+]/g, '-') // Remove special characters except hyphens and alphanumeric characters
      .replace(/-{2,}/g, '-'); // Remove consecutive hyphens

        if (cleanedText.endsWith('-')) {
            return cleanedText.slice(0, -1);  
    }return cleanedText;
  }


  return (
    <div className="App">
      <div id="loading-overlay">
        <div id="lottie-container">
          <Lottie animationData={loading} loop={true} />
        </div>
      </div>
      <Router>
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/azienda/" element={<Azienda />} />
          <Route exact path="/perche-scegliere-careisgold/" element={<AziendaServizi2 />} />
          <Route exact path="/banco-metalli/" element={<AziendaServizi2 />} />
          <Route exact path="/certificazione-good-delivery/" element={<AziendaServizi2 />} />
          <Route exact path="/accreditamenti-certificazioni/" element={<AziendaServizi2 />} />
          <Route path="/trattamento-fiscale/" element={<Navigate to="/notizie/approfondimenti/32/guida-alla-tassazione-dell-oro-da-investimento-in-italia-leggi-e-dichiarazioni/" replace />} />
          <Route exact path="/notizie/" element={<Notizie />} />
          <Route exact path="/notizie/rassegna-stampa/" element={<Notizie/>} />
          <Route exact path="/notizie/approfondimenti/" element={<Notizie/>} />
          <Route exact path="/prodotti-e-servizi/" element={<Prodotti />} />
          <Route exact path="/consulenza-supporto/" element={<ConsulenzaSupporto />} />
          <Route exact path="/lingotti/" element={<Lingotti />} />
          <Route exact path="/kinegram/" element={<Lingotti />} />
          <Route exact path="/collabora-con-noi/" element={<CollaboraConNoi />} />
          <Route exact path="/collabora-con-noi/divisione-prime/" element={<CollaboraConNoi />} />
          <Route exact path="/collabora-con-noi/divisione-private/" element={<CollaboraConNoi />} />
          <Route exact path="/collabora-con-noi/divisione-corporate/" element={<CollaboraConNoi />} />
          <Route exact path="/faq/" element={<Faq />} />
          <Route exact path="/faq/categoria/" element={<Faq />} />
          <Route exact path="/contatti/" element={<Contatti />} />
          <Route exact path="/oro-prestige/" element={<OroPrestige />} />
          <Route exact path="/oroprestige/" element={<OroPrestige />} />
          <Route exact path="/staff/" element={<Staff />} />
          <Route exact path="/gruppo/" element={<History />} />
          <Route exact path="/area-clienti-collaboratori/" element={<AreaRiservata />} />
          <Route exact path="/prodotti/" element={<ScopriProdotti />} />
          <Route exact path="/investire-in-oro/" element={<PercheOro />} />
          <Route exact path="/not-found/" element={<NotFound404 />} />
          <Route exact path="/servizi/" element={<Servizi />} />
          <Route exact path="/divisione-private-consulenti/" element={<DivisionePrivate />} />
          <Route exact path="/recensioni/" element={<Recensioni />} />

          {/* pagina gioiellerie */}
          <Route exact path="/oro-prestige/:city/:name/" element={<SingleGioielleria />} />


            {/* dynamic routes */}
            {posts.map(post => (
                <Route key={post.Id} exact path={`/notizie/approfondimenti/${post.Id}/${formatToUrlFriendly(post.Metatitle)}/`} element={<Approfondimenti/>} />
            ))}
            {posts.map(post => (
                <Route key={post.Id} exact path={`/notizie/rassegna-stampa/${post.Id}/${formatToUrlFriendly(post.Metatitle)}/`} element={<RassegnaStampa/>} />
            ))}

        </Routes>
      </Router>
    </div>
  );
}

export default App;
