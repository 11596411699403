import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/lingotti/0056 - Still life Care is gold-Modifica.webp";
import lingotto from "../../assets/lingotti/00391-Still-life-Care-is-gold-Modifica.webp";
import Check from "../../assets/lottie/Check.json";
import Footer from '../../components/Footer';
import Lottie from 'lottie-react';
import 'aos/dist/aos.css';
import GraficoOro from '../../components/GoldPriceNew';
import IncrementNum from '../../components/IncrementNum';
import MinimalArrow from '../../components/MinimalArrow';
import BackgroundImageFade from '../../components/BackgroundImageFade';
import { Helmet } from 'react-helmet';
import logo from '../../assets/oroPrestige/logo-trasp.webp'
import ReactDOM from 'react-dom';



export default function PercheOro() {
  const listItemRefs = useRef([]);
  const lottieRefs = useRef([]);
  const [appearedItems, setAppearedItems] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  var ogImage = 'https://www.careisgold.it/static/media/0056%20-%20…re%20is%20gold-Modifica.6d513d707ad5330c169e.webp';


  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = listItemRefs.current.indexOf(entry.target);
            if (!appearedItems.includes(index)) {
              setAppearedItems((prev) => [...prev, index]);
            }
          }
        });
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    listItemRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      listItemRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [appearedItems]);


  const vantaggi = 
  [
    "È il bene rifugio per eccellenza. Così lo definiscono molti esperti in quanto, offrendo <b>protezione contro l’inflazione</b>, mantiene il suo valore e il suo potere d’acquisto ed è in grado di sopravvivere a qualsiasi default di valuta. L’oro non fallisce.",
    "Protegge il patrimonio dalla volatilità e dagli scossoni dei mercati finanziari, non essendo legato all’andamento di azioni e obbligazioni.",
    "È un bene fisico di proprietà del Cliente: chi lo acquista è il solo e unico proprietario.",
    "È durevole e invariabile: viene usato sin dai tempi della Preistoria (oltre 6000 anni fa).",
    "Non è una risorsa infinita.",
    "È utilizzato come riserva monetaria: 1/4 dell’Oro mondiale è detenuto da Banche e Governi e il trend dei loro acquisti è in costante aumento.",
    "È liquidabile in tutto il mondo, in quanto il suo valore è riconosciuto universalmente.",
    "È esente IVA e conveniente da un punto di vista fiscale sia in fase di acquisto, che di possesso, che di vendita."
  ]

  const content = <>
  <div className='relative z-10 lg:p-10 pt-10 text-white bg-gradient-custom w-full px-5'>
            <div className='mb-10 md:mb-0 lg:pl-9 flex justify-center items-center flex-col '>
              <p className=' text-center text-2xl mb-9 lg:w-1/2 w-full mb-28'  data-aos="fade-up">Fin dall’antichità, 
                l'Oro è considerato il metodo di pagamento più importante e sicuro. Raro, prezioso e
                malleabile, può essere rifuso e riconiato senza perdere qualità o valore. Scopri tutte le sue qualità uniche e come
                monitorare il suo valore nel tempo.
              </p>
              <h2 className='text-center text-3xl mb-9 text-animate-gold lg:w-1/2 w-full' data-aos="fade-up"><b>I vantaggi di diversificare il portafoglio con l’oro fisico da investimento</b> </h2>
            </div>
            <div className='lg:pr-8 flex justify-center'>
              <ul className=' lg:w-1/2 w-full'>
                {vantaggi.map((text, index) => (
                  <li 
                    key={index} 
                    className='mt-7' 
                    data-aos="fade-up" 
                    ref={(el) => (listItemRefs.current[index] = el)}
                  >
                    <div className={`flex lg:flex-row items-center   flex-col  `}>
                      <span className='mr-4 xl:w-[5%] mb-3 lg:w-[10%] md:w-[5%] sm:w-[10%] w-[20%] ml-2 '>
                        <Lottie 
                          animationData={Check} 
                          loop={!appearedItems.includes(index)} 
                          lottieRef={(el) => (lottieRefs.current[index] = el)}
                        />
                      </span>
                      <span className='w-[90%] text-center xl:text-start'>
                        <p dangerouslySetInnerHTML={{ __html: `<b>${text.split(' ').slice(0, 4).join(' ')}</b> ${text.split(' ').slice(4).join(' ')}` }}></p>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='relative z-10 text-white bg-gradient-custom-top w-full'>
            <div className="flex justify-center flex-col xl:flex-row py-20">
              <div className="basis-1/4 mt-10 xl:mt-0">
                <IncrementNum start={0} end={65} duration={3} startSign='+&nbsp;' endSign='%'/>
                <p className='mt-4 font-semibold text-lg'>Negli ultimi 5 anni</p>
              </div>
              <div className="basis-1/4 mt-10 xl:mt-0">
                <IncrementNum start={0} end={560} duration={3} startSign='+&nbsp;' endSign='%'/>
                <p className='mt-4 font-semibold text-lg'>Incremento dal 2000 ad oggi</p>
              </div>
              <div className="basis-1/4 mt-10 xl:mt-0">
                <IncrementNum start={0} end={9688} duration={3} startSign='+&nbsp;' endSign='%'/>
                <p className='mt-4 font-semibold text-lg'>Negli ultimi 100 anni</p>
              </div>
            </div>
            <GraficoOro/>
          </div>
  </>

  return (
    <>
    <Helmet>
        <title>Tutto su Investimenti in Oro | Careisgold</title>
        <meta name="description" content="Diversifica il tuo portafoglio con Careisgold. Strategie, consigli e informazioni per investimenti sicuri e solidi." />
        <meta name="keywords" content="investire in oro, guida su investimenti in oro, strategie, diversificazione portafoglio, investimento, Careisgold" />
        <meta property="og:title" content="Tutto su Investimenti in Oro | Careisgold" />
        <meta property="og:description" content="Diversifica il tuo portafoglio con Careisgold. Strategie, consigli e informazioni per investimenti sicuri e solidi." />
        <meta property="og:image" content={ogImage} /> 
        <meta property="og:url" content="https://www.careisgold.it/perche-investire-in-oro/" />
        <meta property="og:type" content="page" /> 
        <link rel="canonical" href="https://www.careisgold.it/perche-investire-in-oro/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Tutto su Investimenti in Oro | Careisgold",
          "url": "https://www.careisgold.it/perche-investire-in-oro/",
          "description": "Diversifica il tuo portafoglio con Careisgold. Strategie, consigli e informazioni per investimenti sicuri e solidi.",
          "inLanguage": "it",
          "primaryImageOfPage": {
            "@type": "ImageObject",
            "url": ${headerImage}
          },
          "publisher": {
            "@type": "Organization",
            "name": "Careisgold SpA",
            "logo": {
              "@type": "ImageObject",
              "url": ${logo}
            },
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Via Monte Baldo 14/D",
              "addressLocality": "Villafranca di Verona",
              "addressRegion": "Veneto",
              "postalCode": "37069",
              "addressCountry": "IT"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+39 045 8213155",
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.facebook.com/careisgoldspa",
              "https://www.instagram.com/careisgoldspa",
              "https://www.linkedin.com/company/careisgold-spa"
            ]
          }
        }
      `}
    </script>
      </Helmet>
      <Navbar />
      <div>
        <MinimalArrow backgroundImage={headerImage} title='C’era una volta un metallo dorato…' Subtitle={`<p class=''>Valore, purezza, prestigio: queste sono solo alcune parole chiave associate all’Oro. </p>`}/>
        <BackgroundImageFade src={lingotto} content={content} />
      
      </div>
      <Footer />
    </>
  );
}
