import React, { useState, useEffect, useRef, useCallback } from 'react';
import { LoadScript , StandaloneSearchBox } from '@react-google-maps/api';

const libraries = ['places'];

const GoogleMapsLoader = ({ handleAddress }) => {
  const [loadScriptKey, setLoadScriptKey] = useState(0);
  const [searchBox, setSearchBox] = useState(null);
  const googleMaps = 'AIzaSyCZyoDZ5eFh61i2vM3zyDXUI3WVp3J4E9Y'

  useEffect(() => {
    // Increment the key to force remount whenever necessary
    setLoadScriptKey(prevKey => prevKey + 1);
  }, []);

  
  const inputRef = useRef(null);
  const onLoad = useCallback((ref) => {
    setSearchBox(ref);
  }, []);

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        handleAddress(place.formatted_address);
        console.log(place.formatted_address); // Log the value to the console
      }
    }
  };

  return (
    <LoadScript 
      key={loadScriptKey}
      googleMapsApiKey={googleMaps}
      libraries={libraries}
    >
      <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
        <input 
          ref={inputRef}
          type="text"
          id="location"
          name="location"
          // value={address}
          // onChange={(e) => {
          //   setAddress(e.target.value);
          // }}
          placeholder=''
          className="w-full ps-2 py-2 mt-1 bg-neutral-800 text-slate-50 focus:outline-none focus:border-indigo-500"  
        />
      </StandaloneSearchBox>
    </LoadScript>
  );
};

export default GoogleMapsLoader;
