import React, { useRef, useState } from 'react';
import Navbar from '../../components/Navbar';
import headerImage from "../../assets/staticSection/forme-oro5.webp";
import { FiChevronRight, FiSearch } from 'react-icons/fi';
import faqData from '../../assets/json/faq.json';
import Footer from '../../components/Footer';
import MinimalArrow from '../../components/MinimalArrow';
import { Helmet } from 'react-helmet';


function Faq() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(null); // State to track which dropdown is open
  var ogImage = 'https://www.careisgold.it/static/media/forme-oro5.37d2851987d74556984c.webp';

  // Filter FAQ items based on search query
  const filteredFaq = faqData.filter(item =>
    item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle toggling dropdown
  const handleToggle = (index) => {
    setIsOpen(isOpen === index ? null : index); // If clicked on same index, close it, otherwise open it
  };

  const nextSectionRef = useRef(null);

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="">
      <Helmet>
        <title>FAQ Investimenti in Oro | Careisgold</title>
        <meta name="description" content="Trova risposte alle domande frequenti sugli investimenti in oro con Careisgold. Scopri come investire in oro fisico in modo sicuro." />
        <meta property="og:title" content="FAQ Investimenti in Oro | Careisgold" />
        <meta property="og:description" content="Trova risposte alle domande frequenti sugli investimenti in oro con Careisgold. Scopri come investire in oro fisico in modo sicuro." />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content="https://www.careisgold.it/faq/" />
        <meta property="og:type" content="page" />
        <meta name="keywords" content="FAQ oro, investimenti oro, domande frequenti, Careisgold" />
        <link rel="canonical" href="https://www.careisgold.it/faq/" />
        <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Come posso investire in oro con Careisgold?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Puoi investire in oro con Careisgold visitando il nostro sito web e scegliendo tra i diversi piani di investimento disponibili."
              }
            },
            {
              "@type": "Question",
              "name": "Quali sono i vantaggi dell'investimento in oro?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "L'investimento in oro offre sicurezza e protezione del patrimonio, oltre a essere un bene rifugio che mantiene il suo valore nel tempo."
              }
            },
            {
              "@type": "Question",
              "name": "Come garantite l'autenticità dei lingotti d'oro?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "I nostri lingotti d'oro sono dotati di un sistema anticontraffazione che ne garantisce l'autenticità."
              }
            }
          ]
        }
      `}
    </script>
      </Helmet>
      <Navbar />
      <MinimalArrow backgroundImage={headerImage} title='Per ogni domanda, una risposta' Subtitle="Qui troverai le principali soluzioni ai dubbi più comuni sull’oro e sui nostri piani di acquisto ricorrente."/>
      <div className='flex flex-col items-center justify-start flex-grow pb-20'>
        <div className='relative mt-20 w-11/12 md:w-1/2'>
          <input 
            type='text' 
            placeholder='Domande frequenti . . .' 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className=' bg-neutral-800 text-slate-50 px-4 py-2 focus:outline-none focus:border-blue-500 w-full'
          />
          <FiSearch className='search-icon absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-400' />
        </div>
        
        <div className='mt-8 w-11/12 md:w-1/2'>
          {filteredFaq.length === 0 ? (
            <p className='text-slate-50'>Non ci sono domande relative alla sua ricerca...</p>
          ) : (
            filteredFaq.map((item, index) => (
              <div key={index} className='faq-item border border-2 border-neutral-800 p-4 mb-4'>
                <div 
                  className='faq-question text-lg font-semibold cursor-pointer text-start text-slate-100 flex justify-between align-center'
                  onClick={() => handleToggle(index)} 
                >
                  {item.question}
                  <span className='flex'>
                    <FiChevronRight className={`text-slate-50 transition-transform duration-300 my-auto transform ${isOpen === index ? 'rotate-90' : ''}`} />
                  </span>
                </div>
                {isOpen === index && ( 
                  <div className='faq-answer text-start text-slate-100 mt-6'>{item.answer}</div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Faq;
