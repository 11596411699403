import React, { useRef } from 'react';
import { ScrollParallax } from 'react-just-parallax';
import { Link } from 'react-router-dom';
import DefaultButton from './DefaultButton';

function Static1({headerImage, logoTransp, title, description, href, logoPosition, buttonText, scrollDown, aos}) {

  const createMarkup = (htmlContent) => {
    return {__html: htmlContent};
  };

  const nextSectionRef = useRef(null);

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <>
      <div className='relative z-20 h-lvh overflow-hidden'>
              <ScrollParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} lerpEase="0.05">
                <img src={headerImage} data-aos={aos ? "" : "fade-in"} data-aos-duration={aos ? "" : "2000"} alt='header image' className="absolute top-0 left-0 w-full h-full object-cover" />
              </ScrollParallax>
          <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full flex justify-center items-center">
                {logoTransp !== '' && <img  src={logoTransp}    
                  data-aos="fade-in" data-aos-duration="3000"  
                  className={`absolute ${logoPosition} object-contain`}
                  alt='logo careisgold'
                />}
              <div className="flex justify-end text-start ps-15  mx-[5%] md:ml-[5%]">
                <div className='lg:w-[50%]'>
                  <h2 className="lg:text-5xl text-4xl text-white font-semibold text-balance" data-aos="fade-up" data-aos-duration="1000" dangerouslySetInnerHTML={createMarkup(title)}></h2>
                  <p className="text-xl mt-4 mb-10 text-white text-balance" data-aos="fade-up" data-aos-duration="1000">{description}</p>
                  {buttonText && <DefaultButton text={buttonText || 'Scopri di più'} link={href} dark={true} buttonClass='' />}
                </div>
              </div>
          </div>
      </div>
      <div ref={nextSectionRef}>
      </div>
    </>
  );
}

export default Static1;
