import React, { useEffect, useRef, useState } from 'react';
import { MouseParallax } from 'react-just-parallax';
import imgDonna from '../assets/oroPrestige/donna.webp';
import imgDust from '../assets/oroPrestige/dust.webp';
import imgDustSm from '../assets/oroPrestige/dustSM.webp';
import imgMano from '../assets/oroPrestige/mano.webp';
import imgManoSm from '../assets/oroPrestige/manoSM.webp';
import lingotto from '../assets/oroPrestige/lingotto.webp';
import logoTransp from '../assets/oroPrestige/logo-trasp.webp';
import { SlArrowDown } from "react-icons/sl";


function MouseFollow() {
    const [animationLogo, setAnimationLogo] = useState(true);
    const [animationLingotto, setAnimationLingotto] = useState(false);

    const nextSectionRef = useRef(null);

    // Function to scroll to the next section
    const scrollToNextSection = () => {
      nextSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    useEffect(()=>{
        setTimeout(()=>{
            setAnimationLogo(false);
        },1500);
        setTimeout(()=>{
            setAnimationLingotto(true);
        },2000)
    })

  return (
    <>
    <div className='relative z-20 h-lvh overflow-hidden bg-black hidden md:block'>
        <MouseParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} strength="0.005">
            <img src={imgDonna} data-aos="fade-in" data-aos-duration="3000" className="absolute top-0 left-0 w-full h-full object-cover z-0" style={{ transform: 'scale(1)' }} alt='logo careisgold' />
        </MouseParallax>
        <MouseParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} strength="0.01">
                <img src={lingotto} 
                    className='opacity-95 z-10' 
                    style={{
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)', 
                        height: '50vh', 
                        objectFit: 'contain'
                    }} 
                    alt='logo careisgold' 
                />
            </MouseParallax>
        <MouseParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} strength="0.012">
            <img src={imgDust} data-aos="fade-in" data-aos-duration="3000" className="absolute top-0 left-0 w-full h-full object-cover z-40" style={{ transform: 'scale(1)' }} alt='logo careisgold' />
        </MouseParallax>
        <MouseParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} strength="0.02">
            <img src={imgMano} data-aos="fade-in" data-aos-duration="3000" className="absolute top-0 left-0 w-full h-full object-cover z-40" style={{ transform: 'scale(1)' }} alt='logo careisgold' />
        </MouseParallax>
        <div className="absolute top-0 left-0 w-full h-full z-0">
            <MouseParallax enableOnTouchDevice={true} isAbsolutelyPositioned={true} strength="0.001">
                <img src={logoTransp} 
                    className='opacity-50' 
                    style={{
                        position: 'absolute', 
                        top: '75%', 
                        left: '80%', 
                        transform: 'translate(-50%, -50%)', 
                        height: '20vw', 
                        objectFit: 'contain'
                    }} 
                    alt='logo careisgold' 
                />
            </MouseParallax>
            <SlArrowDown 
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                onClick={scrollToNextSection} 
                className='cursor-pointer absolute bottom-10 left-1/2 transform -translate-x-1/2 text-slate-50 text-5xl bouncing' />
        </div>
    </div>
    {/* mobile version */}
    <div className='relative w-full h-screen flex items-center justify-center overflow-hidden block md:hidden'>
        <img src={imgManoSm}         
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1000" 
        className='absolute bg-cover z-20' style={{ width: '80%', top:'60%', left:'-15%'}} alt='logo careisgold' />
        <img src={logoTransp} 
        data-aos="fade-in"
        data-aos-easing="linear"
        data-aos-duration="2500"
        className={`absolute bg-cover z-10 transition-opacity duration-2500 ${animationLogo ? 'fadeIn' : 'fadeOut'}`} style={{ width: '60%', top:'30%'}} alt='logo careisgold' />
        {animationLingotto && 
            <>
                <img src={lingotto} 
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="2500"
                className='absolute bg-cover z-10 sm:w-1/2 w-3/5 sm:top-[12%] top-[18%]' alt='logo careisgold' />
                <img src={imgDustSm} 
                data-aos="fade-in"
                data-aos-easing="linear"
                data-aos-duration="3000"
                className='absolute bg-cover z-0' style={{ width: '100%', top:'35%', left:'60%', transform: 'translate(-50%, -50%)'}} alt='logo careisgold' />
                <SlArrowDown 
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="3000"
                onClick={scrollToNextSection} 
                className='cursor-pointer absolute bottom-28 left-1/2 transform -translate-x-1/2 text-slate-50 text-3xl bouncing z-20' style={{transform: 'translate(-50%, -50%)'}} />
            </>
        }

    </div>

    <div ref={nextSectionRef}>
    </div>
    </>
  );
}

export default MouseFollow;
